import moment from 'moment';
import jstz from 'jstz';
import L from 'i18n-react';
import { store } from '../redux/store';
import types from '../redux/types';
import notification from './notification';

const { dispatch } = store;

export const addZeroAfterPoint = n => {
  if (n > 1) {
    return `0${addZeroAfterPoint(n - 1)}`;
  }
  return '0';
};

export const toCrop = amount => {
  const max = amount || 0;
  return function (string) {
    const symb = +string < 0 ? '-' : '';
    const value = `${
      `${string}`.replace(',', '.').split('.')[1] ? `${string}` : `${string}.`
    }${addZeroAfterPoint(max)}`.replace(',', '.');
    if (max) {
      const reg = new RegExp(`\\d+(?:\\.\\d{0,${max}})?`);
      return symb + value.match(reg)[0];
    }
    const reg = new RegExp(`\\d+(?:\\.\\d{0)?`);
    return symb + value.match(reg)[0];
  };
};

export const firstLatterToUppercase = word => {
  if (typeof word === 'string') {
    if (word && !word?.trim().length) return;
    if (word) return word?.charAt(0).toUpperCase() + word.slice(1);
  }
};

export const cropNumber = (number, numbersAfterDot) => {
  if (number || number === 0) {
    let exp = '';
    const numStr = `${number}`;
    if (numStr?.includes('e')) {
      exp = `e${numStr.split('e')[1]}`;
    }
    // if (number >= 1) {
    //   return toCrop(4)(number) + exp;
    // }
    // if (number > 0 && number < 1) {
    //   return toCrop(6)(number) + exp;
    // }
    // return toCrop(4)(number) + exp;
    if (numbersAfterDot) {
      return toCrop(numbersAfterDot)(number) + exp;
    }
    return toCrop(6)(number) + exp;
  }
};

export const cropNumberToInt = number => {
  if (number || number === 0) {
    return toCrop(-1)(number);
  }
};

export const pushToMiddle = (word, symb) => {
  // check if uses in futures Chart, may be not nedded
  if (word) {
    const lpart = word.slice(0, word.length / 2);
    const rpart = word.slice(word.length / 2);
    return lpart + symb + rpart;
  }
};

export const hideEmail = string => {
  if (string) {
    const splittedString = string.split('@');
    const { length } = splittedString[0];
    const stars = '***';
    const firstThreeLetters = splittedString[0].slice(-length, 2);
    const result = firstThreeLetters + stars + splittedString[1];
    return result;
  }
};

export const intNumberValidation = text => {
  if (text === '') return true;
  const regExp = /^\d{1,8}$/;
  if (!regExp.test(text)) return false;
  return true;
};

export const numberValidation = text => {
  if (text === '') return true;
  const regExp = /^(?:[0-9]{1,8}[\.][0-9]{1,8}|[0-9]{1,8}[\.]|[0-9]{1,8})$/;
  if (!regExp.test(text)) return false;
  return true;
};

export const amountValidation = (before = 8, after = 8) => {
  return function (text) {
    if (text === '') return true;
    const regExp = new RegExp(
      `^(?:[0-9]{1,${before}}[\.][0-9]{1,${after}}|[0-9]{1,${before}}[\.]|[0-9]{1,${before}})$`,
    );
    if (!regExp.test(text)) return false;
    return true;
  };
};

export const empty = text => {
  if (!text || text.length === 0) return true;
  return false;
};

export const passwordValid = password => {
  if (password.length < 8) return false;
  const regExp = /(?=^.{8,25}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/;
  if (!regExp.test(password)) return false;
  return true;
};

export const passwordConfirm = (password, confirmPassword) => {
  if (confirmPassword.length <= 8) return false;
  if (password !== confirmPassword) return false;
  // const regExp = /(?=^.{8,32}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  // const regExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,24}$/;
  const regExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[,.#?!@$%^&*-]).{8,}$/;
  if (!regExp.test(password)) return false;
  return true;
};

export const emailValid = email => {
  if (email.length === 0) return false;
  // const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;
  const regExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!regExp.test(email)) return false;
  return true;
};

export const phoneValid = phone => {
  if (phone.length === 0) return false;
  const regExp = /^[\+][1-9]{1}[\d]{9,13}$/;
  if (!regExp.test(phone)) return false;
  return true;
};

const hostname = window?.location?.hostname;
const protocol = window?.location?.protocol;

export const wsUrl = () => {
  if (hostname === '127.0.0.1') {
    return `ws://${hostname}:8980`;
  }
  if (protocol === 'http:' && hostname !== 'localhost') {
    return `ws://${hostname}:8850`;
  }
  if (protocol === 'https:') {
    return `wss://${hostname}:8443`;
  }
  // return 'ws://35.171.103.203:8850';
  // return `ws://orderbook.fun:8443`;

  return `ws://localhost:8080`;
};

export const range = (start, end) => {
  const arr = [];
  for (let i = start; i <= end; i += 1) {
    arr.push(i);
  }
  return arr;
};

export const addZero = value => String(value).padStart(2, '0');

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const sortingColumnTable = ({ sort, data, setState }) => {
  if (Object.keys(data).length === 0) return;
  if (!sort?.sort && !sort?.order) return;
  const newData = data?.sort((itemA, itemB) => {
    const a =
      itemA[sort.order] ||
      itemA[sort.order] === 0 ||
      itemA[sort.order]?.length === 0 || // needs to be checked
      itemA.asset[sort.order] ||
      itemA.mem_info[sort.order];
    const b =
      itemB[sort.order] ||
      itemB[sort.order] === 0 ||
      itemB[sort.order]?.length === 0 || // needs to be checked
      itemB.asset[sort.order] ||
      itemB.mem_info[sort.order];
    if (isNaN(+a)) {
      if (sort?.sort === 'desc') {
        return a < b ? -1 : 1;
      }
      if (sort?.sort === 'asc') {
        return a < b ? 1 : -1;
      }
    } else {
      if (sort?.sort === 'desc') {
        return +a < +b ? -1 : 1;
      }
      if (sort?.sort === 'asc') {
        return +a < +b ? 1 : -1;
      }
    }
    return 0;
  });
  setState(newData);
};

export const checkType = type => {
  switch (type) {
    case 'input':
      return L.translate('Wallets.deposit');
    case 'output':
      return L.translate('Wallets.withdrawal');
    case 'deposit':
      return L.translate('Wallets.deposit');
    case 'withdraw':
      return L.translate('Wallets.withdrawal');
    default:
      return '-';
  }
};

export const statusText = status => {
  switch (status) {
    case 'confirmed':
      return L.translate('Wallets.status_type.confirmed');
    case 'unconfirmed':
      return L.translate('Wallets.status_type.unconfirmed');
    case 'completed':
      return L.translate('Wallets.status_type.completed');
    case 'pending':
      return L.translate('Wallets.status_type.pending');
    case 'waiting':
      return L.translate('Wallets.status_type.waiting');
    case 'processing':
      return L.translate('Wallets.status_type.processing');
    case 'processed':
      return L.translate('Wallets.status_type.processed');
    case 'canceled':
      return L.translate('Wallets.status_type.canceled');
    case 'rejected':
      return L.translate('Wallets.status_type.rejected');
    case 'in_progress':
      return L.translate('Wallets.status_type.in_progress');
    case 'in progress':
      return 'In progress';
    default:
      return L.translate('Wallets.status_type.not_status');
  }
};

export const changeContract = contract => {
  dispatch({ type: types.SET_CURRENT_CONTRACT_START, payload: contract });
  dispatch({ type: types.FUTURES_SELECT_CONTRACT, payload: contract });
};

export const cropUsdt = (pair, price) => {
  if (
    pair?.toLowerCase()?.includes('usdt') ||
    pair?.toLowerCase()?.includes('usd') ||
    pair?.toLowerCase()?.includes('eur')
  ) {
    return toCrop(4)(price);
  }

  return toCrop(6)(price);
};

export const subtractMonth = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());
};

export const transformData = (time, format) => {
  if (typeof time === 'number') {
    return moment(time * 1000).format(format || 'DD-MM-YYYY HH:mm:ss');
  }
  return time;
};

export const getTimezone = () => {
  const timezone = jstz.determine();
  const timezoneName = timezone.name();
  const string = [
    'America/New_York',
    'America/Los_Angeles',
    'America/Chicago',
    'America/Phoenix',
    'America/Toronto',
    'America/Vancouver',
    'America/Argentina/Buenos_Aires',
    'America/El_Salvador',
    'America/Sao_Paulo',
    'America/Bogota',
    'America/Caracas',
    'Europe/Moscow',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Paris',
    'Europe/Rome',
    'Europe/Warsaw',
    'Europe/Istanbul',
    'Europe/Zurich',
    'Australia/Sydney',
    'Australia/Brisbane',
    'Australia/Adelaide',
    'Australia/ACT',
    'Asia/Almaty',
    'Asia/Ashkhabad',
    'Asia/Tokyo',
    'Asia/Taipei',
    'Asia/Singapore',
    'Asia/Shanghai',
    'Asia/Seoul',
    'Asia/Tehran',
    'Asia/Dubai',
    'Asia/Kolkata',
    'Asia/Hong_Kong',
    'Asia/Bangkok',
    'Asia/Chongqing',
    'Asia/Jerusalem',
    'Asia/Kuwait',
    'Asia/Muscat',
    'Asia/Qatar',
    'Asia/Riyadh',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'Pacific/Fakaofo',
    'Pacific/Honolulu',
    'America/Mexico_City',
    'Africa/Cairo',
    'Africa/Johannesburg',
    'Asia/Kathmandu',
    'US/Mountain',
  ];
  const kiev = ['Europe/Kiev', 'Europe/Zaporozhye', 'Europe/Uzhgorod'].includes(
    timezoneName,
  )
    ? 'Europe/Athens'
    : timezoneName;
  return string.includes(kiev) ? kiev : 'Etc/UTC';
};

export const toJoinArray = async ({ all, favorites }) => {
  const result = await all.map(item => {
    const includes = favorites.find(el => {
      return String(el.id) === String(item.id);
    });
    if (includes?.id) {
      return { ...item, favorite: true };
    }
    return item;
  });
  return result;
};

export const toUpdateArray = (id, all) => {
  const result = all.map(item => {
    if (String(item?.id) === String(id)) {
      return { ...item, favorite: !item.favorite };
    }
    return item;
  });
  return result;
};

export const toUpdate = (first, second) => {
  try {
    let newSecond = second;
    const arr = Object.entries(first);
    arr.forEach(element => {
      const key = element[0];
      const value = element[1];
      if (newSecond[key] === undefined) {
        newSecond = { ...newSecond, [key]: value };
        return;
      }
      if (typeof value === 'object' && !value?.length && value !== null) {
        return toUpdate(value, newSecond[key]);
      }
      newSecond[key] = value;
    });
    return newSecond;
  } catch (error) {
    return { ...second, ...first };
  }
};

export const numberWithCommas = number => {
  return String(number).replace(/(\B)(?=(\d{3})+\.)/g, ',');
};

export const numberWithCommasNotDot = number => {
  return String(number).replace(/(\B)(?=(\d{3})+(?!\d))/g, ',');
};

export const firstLatterToSmall = word => {
  if (typeof word === 'string') {
    if (word && !word?.trim().length) return;
    if (word) return word?.charAt(0) + word.slice(1).toUpperCase();
  }
};

export const copyText = e => {
  const copyId = e?.currentTarget?.dataset?.copyid;
  const successNotif = e?.currentTarget?.dataset?.successnotif;
  const errorNotif = e?.currentTarget?.dataset?.errornotif;
  if (copyId) {
    const elementText = document.getElementById(copyId);
    if (elementText?.innerHTML) {
      navigator.clipboard
        .writeText(elementText.innerHTML)
        .then(() =>
          notification({
            type: 'success',
            message: successNotif || 'Text copied successfully.',
          }),
        )
        .catch(() =>
          notification({
            type: 'error',
            message: errorNotif || 'Something went wrong.',
          }),
        );
    }
  }
};

export const thisDemo = () => {
  return window?.location?.hostname.includes('demo');
};

export const toSubDomen = subDomen => {
  const host = window?.location?.host;
  const protocol = window?.location?.protocol;
  const pathname = window?.location?.pathname;
  return `${protocol}//${
    subDomen?.length ? `${subDomen}.` : ''
  }${host}${pathname}`;
};

export const toHome = subDomen => {
  const host = window?.location?.host;
  const protocol = window?.location?.protocol;
  return `${protocol}//${subDomen?.length ? `${subDomen}.` : ''}${host}`;
};

export const createIframe = src => {
  const iframe = document.createElement('iframe');
  iframe.style.position = 'fixed';
  iframe.id = 'shuftipro-iframe';
  iframe.name = 'shuftipro-iframe';
  iframe.allow = 'camera';
  iframe.src = src;
  iframe.style.top = 0;
  iframe.style.left = 0;
  iframe.style.bottom = 0;
  iframe.style.right = 0;
  iframe.style.margin = 0;
  iframe.style.padding = 0;
  iframe.style.overflow = 'hidden';
  iframe.style.border = 'none';
  iframe.style.zIndex = '2147483647';
  iframe.width = '100%';
  iframe.height = '100%';
  iframe.dataset.removable = true;

  document.body.appendChild(iframe);
};

export const convertImgToBase64URL = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      let canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/jpeg');
      resolve(dataURL);
      canvas = null;
    };
    img.src = url;
  });
};

export const getUserWithType = (arr = [], type) =>
  arr.find(item => item?.type?.split('_')[0] === type);

export const iOS = navigator?.userAgent?.match(/iPhone|iPad|iPod/i);

export const windowReference = url => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.onclick = "this.target='_blank';";
  a.click();
};

export const killExponential = (a, b) => {
  a = +a;
  if (isNaN(a) || a === 0) return '0';
  if (Math.abs(a) <= 1e-9) return '> 0.00000000';
  if (Math.abs(a) <= 9e-7) {
    const sign = a < 0 ? '-' : '';
    if (typeof a === 'number') a += '';
    a = a.split('e-');
    let res = '0.';
    for (let i = 0; i < a[1] - 1; i += 1) {
      res += '0';
    }
    a = sign + res + Math.abs(a[0].split('.')[0]);
    return Number(a).toFixed(b);
  }

  return Number(a).toFixed(b);
};

// For Cashback and FeesAndLimits components
export const transformForTakerMakerUserCashback = (
  data,
  auth,
  userCashback,
  vip = '0',
  currentCashback = {},
  volumeStatistics = {},
) => {
  return auth
    ? data?.map((el, idx) => ({
        ...el,
        maker_fee:
          userCashback?.reach >= el.max_amount
            ? userCashback?.cashback_maker
            : el.maker_fee,
        taker_fee:
          userCashback?.reach >= el.max_amount
            ? userCashback?.cashback_taker
            : el.taker_fee,
        total_taker_fee:
          +vip >= +el.level
            ? data[0].total_taker_fee -
              (data[0].total_taker_fee * currentCashback?.rate?.taker_fee) / 100
            : data[idx].total_taker_fee,
        total_maker_fee:
          +vip >= +el.level
            ? data[0].total_maker_fee -
              (data[0].total_maker_fee * currentCashback?.rate?.maker_fee) / 100
            : data[idx].total_maker_fee,
        style_cashback:
          +vip > +el.level ||
          +vip === +data[data.length - 1].level ||
          userCashback?.reach > el.max_amount
            ? '#FDFF86'
            : null,
        style_limits: +vip >= +el.level ? '#FDFF86' : null,
        style:
          +vip > +el.level ||
          +vip === +data[data.length - 1].level ||
          userCashback?.reach >= el.max_amount
            ? '#FDFF86'
            : null,
      }))
    : data?.map(el => ({
        ...el,
        maker_fee: el.maker_fee,
        taker_fee: el.taker_fee,
      }));
};

// For Cashback and FeesAndLimits components
export const transformForTakerMakerStyle = (data, item, vip = '0') => {
  return data?.map(el =>
    // (el.taker_fee < item.cashback_taker &&
    //   el.maker_fee < item.cashback_maker) ||
    +vip >= +el.level || el.max_amount <= item.reach
      ? {
          ...el,
          taker_fee: item.cashback_taker,
          maker_fee: item.cashback_maker,
          total_taker_fee:
            data[0].total_taker_fee -
            (data[0].total_taker_fee * item.cashback_taker) / 100,
          total_maker_fee:
            data[0].total_maker_fee -
            (data[0].total_maker_fee * item.cashback_maker) / 100,
          // style_cashback: el.max_amount < item.reach ? '#FDFF86' : null,
          style_cashback:
            (el.taker_fee < item.cashback_taker &&
              el.maker_fee < item.cashback_maker) ||
            +vip > +el.level ||
            // +vip === +data[data.length - 1].level ||
            el.max_amount < item.reach
              ? '#FDFF86'
              : null,
          style_limits:
            +vip > +el.level || el.max_amount <= item.reach ? '#FDFF86' : null,
          style: el.max_amount <= item.reach ? '#FDFF86' : null,
        }
      : {
          ...el,
          maker_fee: el.maker_fee,
          taker_fee: el.taker_fee,
        },
  );
};
// for test
export const sendPostFormForPerfectMoney = (url, param) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;
  form.target = '_blank';

  Object.entries(param).forEach(el => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = `${el[0]}`;
    input.value = `${el[1]}`;

    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
};

export const device_id = () => {
  let b = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
  do {
    const c = Math.floor(Math.random() * 42 + 48);
    if (c < 58 || c > 64) b += String.fromCharCode(c);
  } while (b.length < 48);
  return b;
};

export const throttle = (func, delay = 500) => {
  let timeout = null;
  return function (...args) {
    if (!timeout) {
      timeout = setTimeout(() => {
        func.call(this, ...args);
        timeout = null;
      }, delay);
    }
  };
};
