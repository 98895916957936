import types from '../types';

const initialState = {
  invoice: null,
  recentDepositHistory: null,
  recentWithdrawHistory: null,
  transactionFiatHistory: null,
  transactionHistory: null,
  transferHistory: null,
  futures: null,
  referrals: null,
  airdrop: null,
  loading: false,
  profit: null,
};

export const wallets = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_WALLETS_TRANSACTIONS_START:
    case types.GET_WALLETS_START:
    case types.GET_REFERRAL_BALANCE_START:
    case types.GET_AIRDROP_TRANSACTIONS_START:
    case types.GET_WALLETS_TRANSFER_START:
      return { ...state, loading: true };
    // case types.POST_CREATE_BANK_DEPOSITE_REQUEST_SUCCESS:
    //   return { ...state, invoice: payload };
    case types.GET_WALLETS_DEPOSITS_SUCCESS:
      return { ...state, recentDepositHistory: payload };
    case types.GET_WALLETS_WITHDRAWALS_SUCCESS:
      return { ...state, recentWithdrawHistory: payload };
    case types.GET_WALLETS_TRANSACTIONS_SUCCESS:
      return { ...state, transactionHistory: payload, loading: false };
    case types.GET_FIAT_TRANSACTIONS_SUCCESS:
      return { ...state, transactionFiatHistory: payload, loading: false };
    case types.GET_WALLETS_TRANSFER_SUCCESS:
      return { ...state, transferHistory: payload, loading: false };
    case types.GET_WALLETS_SUCCESS:
    case types.UPDATE_WALLETS:
      return { ...state, loading: false, ...payload };
    case types.FUTURES_GET_USER_BALANCE_SUCCESS:
    case types.UPDATE_FUTURES_USER_BALANCE:
      return { ...state, futures: payload, loading: false };
    case types.GET_REFERRAL_BALANCE_SUCCESS:
      return {
        ...state,
        referrals: { ...state.referrals, ...payload },
        loading: false,
      };
    case types.GET_REFERRALS_TRANSFERS_SUCCESS:
      return {
        ...state,
        referrals: { ...state.referrals, transfers: payload },
        loading: false,
      };
    case types.GET_AIRDROP_TRANSACTIONS_SUCCESS:
      return { ...state, airdrop: payload, loading: false };
    case types.GET_PROFIT_SUCCESS:
      return { ...state, profit: payload };
    case types.GET_WALLETS_TRANSACTIONS_FAILURE:
    case types.GET_WALLETS_FAILURE:
    case types.GET_REFERRAL_BALANCE_FAILURE:
    case types.GET_AIRDROP_TRANSACTIONS_FAILURE:
    case types.GET_WALLETS_TRANSFER_FAILURE:
      return { ...state, loading: false };
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

const initialStateAssets = {
  assets: [],
  loading: false,
};

export const assets = (state = initialStateAssets, action) => {
  switch (action.type) {
    case types.GET_ASSETS_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload,
        loading: false,
      };
    case types.GET_ASSETS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // case types.LOGOUT_SUCCESS:
    //   return initialStateAssets;
    default:
      return state;
  }
};

const initialStatePayments = {
  payeer: [],
  prmoney: [],
  paypal: [],
};

export const payments = (state = initialStatePayments, { type, payload }) => {
  switch (type) {
    case types.GET_PAYEER_SYSTEM_SUCCESS:
      return {
        ...state,
        payeer: payload,
      };
    case types.GET_PRMONEY_SYSTEM_SUCCESS:
      return {
        ...state,
        prmoney: payload,
      };
    case types.GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_SUCCESS:
      return {
        ...state,
        paypal: payload,
      };
    case types.LOGOUT_SUCCESS:
      return initialStatePayments;
    default:
      return state;
  }
};
export const transak = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_TRANSAK_LINK_SUCCESS:
      return payload;

    default:
      return state;
  }
};
