import { put, takeLatest, call, fork, all, spawn } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import { rootSagaWalletsCrypto } from './crypto/actions';
import { rootSagaWalletsFiat } from './fiat/actions';
import notification from '../../services/notification';
import { closeModal } from '../../components/Base/Modal';

function* getWalletsList() {
  try {
    const { data, status } = yield call(api.wallets.getWalletsList);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_WALLETS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLETS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getTransakLink() {
  try {
    const { data, status } = yield call(api.wallets.getTransakLink);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_TRANSAK_LINK_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_TRANSAK_LINK_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getWallet({ payload }) {
  try {
    console.log(payload);
    const { data, status } = yield call(api.wallets.getWallet, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    console.log(data);
    yield put({ type: types.GET_WALLET_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLET_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getWalletsTransactions({ query }) {
  try {
    const { data, status } = yield call(
      api.wallets.getWalletsTransactions,
      query,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_WALLETS_TRANSACTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLETS_TRANSACTIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getFiatTransactions({ query }) {
  try {
    const { data, status } = yield call(api.wallets.getFiatTransactions, query);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_FIAT_TRANSACTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_FIAT_TRANSACTIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getWalletsTransfer({ body }) {
  try {
    const { data, status } = yield call(api.wallets.getWalletsTransfer, body);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_WALLETS_TRANSFER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLETS_TRANSFER_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getWalletsDeposits({ query }) {
  try {
    const { data, status } = yield call(api.wallets.getWalletsDeposits, query);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_WALLETS_DEPOSITS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLETS_DEPOSITS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getWalletsWithdrawals({ query }) {
  try {
    const { data, status } = yield call(
      api.wallets.getWalletsWithdrawals,
      query,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_WALLETS_WITHDRAWALS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_WALLETS_WITHDRAWALS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getAssets() {
  try {
    const { data, status } = yield call(api.wallets.getAssets);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_ASSETS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_ASSETS_FAILURE,
    });
  }
}

function* getFuturesBalance() {
  try {
    const { data, status } = yield call(api.trading.futures.getUserBalance);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    // console.log(data);
    yield put({ type: types.FUTURES_GET_USER_BALANCE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.FUTURES_GET_USER_BALANCE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* postFuturesTransfers({ payload }) {
  try {
    const { status } = yield call(
      payload.type
        ? api.wallets.futures.postTransferToFutures
        : api.wallets.futures.postTransferToSpot,
      payload.data,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_FUTURES_TRANSFERS_SUCCESS });
    yield put({ type: types.FUTURES_GET_USER_BALANCE_START });
    yield put({ type: types.GET_WALLETS_START });
    notification({ type: 'success', message: 'Success' });
    closeModal();
  } catch (error) {
    yield put({ type: types.POST_FUTURES_TRANSFERS_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    if (error?.response?.status === 422) {
      notification({ type: 'error', message: 'Not enough money' });
    }
  }
}
function* getRefferalsBalance() {
  try {
    const { data, status } = yield call(
      api.wallets.referrals.getReferralbalance,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    // console.log(data);
    yield put({ type: types.GET_REFERRAL_BALANCE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_REFERRAL_BALANCE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getReferralsTransfers({ body }) {
  try {
    const { data, status } = yield call(
      api.wallets.referrals.getReferralsTransfers,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    // console.log(data);
    yield put({ type: types.GET_REFERRALS_TRANSFERS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_REFERRALS_TRANSFERS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* postRefferalsTransfers({ payload }) {
  try {
    const { status } = yield call(
      api.wallets.referrals.postTransferToSpot,
      payload.data,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_REFERRALS_TRANSFERS_SUCCESS });
    yield put({ type: types.GET_REFERRAL_BALANCE_START });
    // yield put({ type: types.GET_WALLETS_START });
    notification({ type: 'success', message: 'Success' });
    closeModal();
  } catch (error) {
    yield put({ type: types.POST_REFERRALS_TRANSFERS_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('Something went wrong');
    if (error?.response?.status === 422) {
      notification({ type: 'error', message: 'Not enough money' });
    }
  }
}
function* getAirdropTransactions({ body }) {
  try {
    const { data, status } = yield call(
      api.wallets.airdrop.getAirdropTransactions,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_AIRDROP_TRANSACTIONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_AIRDROP_TRANSACTIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}
function* postAirdropsTransfers({ payload }) {
  try {
    const { status } = yield call(
      api.wallets.airdrop.postTransferToSpot,
      payload.data,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_AIRDROP_TRANSFERS_SUCCESS });
    yield put({ type: types.GET_AIRDROP_TRANSACTIONS_START });
    notification({ type: 'success', message: 'Success' });
    closeModal();
  } catch (error) {
    yield put({ type: types.POST_AIRDROP_TRANSFERS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('Something went wrong');

    if (error?.response?.status === 422) {
      notification({ type: 'error', message: 'Not enough money' });
    }
  }
}
function* getProfit() {
  try {
    const { data, status } = yield call(api.wallets.getProfit);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    console.log(data);
    yield put({ type: types.GET_PROFIT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_PROFIT_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}
export function* rootSagaWallets() {
  yield all([
    takeLatest(types.GET_ASSETS_START, getAssets),
    takeLatest(types.GET_WALLETS_WITHDRAWALS_START, getWalletsWithdrawals),
    takeLatest(types.GET_WALLETS_DEPOSITS_START, getWalletsDeposits),
    takeLatest(types.GET_WALLETS_TRANSACTIONS_START, getWalletsTransactions),
    takeLatest(types.GET_FIAT_TRANSACTIONS_START, getFiatTransactions),
    takeLatest(types.GET_WALLETS_TRANSFER_START, getWalletsTransfer),
    takeLatest(types.GET_AIRDROP_TRANSACTIONS_START, getAirdropTransactions),
    takeLatest(types.GET_WALLET_START, getWallet),
    takeLatest(types.GET_PROFIT_START, getProfit),
    takeLatest(types.GET_WALLETS_START, getWalletsList),
    takeLatest(types.FUTURES_GET_USER_BALANCE_START, getFuturesBalance),
    takeLatest(types.POST_FUTURES_TRANSFERS_START, postFuturesTransfers),
    takeLatest(types.GET_REFERRAL_BALANCE_START, getRefferalsBalance),
    takeLatest(types.GET_REFERRALS_TRANSFERS_START, getReferralsTransfers),
    takeLatest(types.POST_REFERRALS_TRANSFERS_START, postRefferalsTransfers),
    takeLatest(types.POST_AIRDROP_TRANSFERS_START, postAirdropsTransfers),
    takeLatest(types.GET_TRANSAK_LINK_START, getTransakLink),
    spawn(rootSagaWalletsCrypto),
    spawn(rootSagaWalletsFiat),
  ]);
}
