import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';
import { closeModal } from '../../components/Base/Modal';

function* getFees({ body }) {
  console.log(body);
  try {
    const { data, status } = yield call(api.fees.getFees, body);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_FEES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_FEES_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

export function* rootSagaFees() {
  yield all([takeLatest(types.GET_FEES_START, getFees)]);
}
