import React, {
  StrictMode,
  Suspense,
  useEffect,
  useRef,
  useState,
  useContext
} from 'react';
import { Redirect, Switch,Route, useHistory, useLocation ,} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import L from 'i18n-react';
import { isTablet, isMobile, isAndroid, isIOS } from 'react-device-detect';
import Headers from '../Base/Headers';
import routes from '../../routes';
import Footers from '../Base/Footers';
import AuthWrapper from '../Base/AuthWrapper';
import types from '../../redux/types';
import { languages } from '../../languages';
import SocketConnect from '../HOC/SocketConnect';
import { Modal, openModal } from '../Base/Modal';
import Loader from '../Base/Loader';
import SocketPrivateNotifications from '../HOC/SocketPrivateNotifications';
import { intervalReguest, toMs } from '../../services/axiosInterceptors';
import DemoHead from '../Base/Demo/DemoHead';
import { thisDemo } from '../../services/helpers';
import Chat from '../Base/Chat';
import Cookie from '../Base/Cookie';
import { ThemeContext } from '../../ThemeContext';
import { userSecurityDataSettingsSelector } from '../../redux/settings/selectors';
import ModalLoginSecurity from '../Base/Modals/ModalLoginSecurity';
import DownloadAppBanner from '../Base/DownloadAppBanner';

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(state => state?.user?.token);
  const language = useSelector(state => state?.language?.language);
  const currentPair = useSelector(
    state => state?.currentPair?.currentPair?.pair,
  );
  const userSecurityData = useSelector(userSecurityDataSettingsSelector);
  const isLogin = localStorage.getItem("firoz_token"); //  && axios.defaults.headers.common.Authorization ????
  const location = useLocation();
  const history = useHistory();
  const [showBanner, isShowBanner] = useState(true);
  const [theme, setTheme] = useState('');
 

  const { theme: contextTheme } = useContext(ThemeContext);

  useEffect(() => {
    setTheme(contextTheme);
  }, [contextTheme]);

  const handleShowBanner = () => {
    isShowBanner(false);
  };

  const isMobileApp = new URLSearchParams(useHistory().location.search).get(
    'app',
  );
  const isTheme = new URLSearchParams(useHistory().location.search).get(
    'theme',
  );
  const isMobileToken = new URLSearchParams(useHistory().location.search).get(
    'access_token',
  );
  const isLang = new URLSearchParams(useHistory().location.search).get('lang');

  L.setTexts(languages[language]);
  useEffect(() => {
    if (isMobileToken) {
      dispatch({
        type: types.UPDATE_USER_TOKEN_START,
        payload: isMobileToken,
      });
      axios.defaults.headers.common.Authorization = `Bearer ${isMobileToken}`;
      intervalReguest();
      return;
    }
    console.log('app-token', token)
    if (token) {

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      intervalReguest();
    }
  }, [token, isMobileToken]);

  useEffect(() => {
    dispatch({ type: types.GET_ASSETS_START });
  }, []);

  useEffect(() => {
    if (isLogin) {
      dispatch({ type: types.GET_USER_DATA_START });
      dispatch({ type: types.GET_WALLETS_START });
      dispatch({
        type: types.GET_USER_SECURITY_DATA_START,
      });
    }
  }, [isLogin]);

  useEffect(() => {
    let interval = null;

    if (
      !isMobileApp &&
      !!Object.keys(userSecurityData)?.length &&
      (!userSecurityData?.email_confirmed ||
        !userSecurityData?.phone_confirmed ||
        !userSecurityData?.google2fa_enabled)
    ) {
      interval = setInterval(() => {
        openModal(() => <ModalLoginSecurity data={userSecurityData} />);
      }, toMs(5));
    }

    return () => {
      clearInterval(interval);
    };
  }, [userSecurityData, isMobileApp]);

  useEffect(() => {
    if (
      location.pathname === routes.Auth.ResetPassword.path &&
      location.search
    ) {
      history.replace(`${routes.Auth.ResetPassword.path}/${location.search}`);
    }
    if (location.pathname === routes.Auth.Login.path && location?.search) {
      history.replace(`${routes.Auth.Login.path}/${location.search}`);
    }
    if (location.pathname === routes.Auth.Signup.path && location.search) {
      history.replace(`${routes.Auth.Signup.path}/${location.search}`);
    }
  }, [history, location.pathname, location.search]);

  const code =
    location.pathname.includes(routes.Trade.SpotTrade.path) &&
    location.pathname
      .split('/')
      [location.pathname.split('/').length - 1].includes('_')
      ? location.pathname
          .split('/')
          [location.pathname.split('/').length - 1].toLowerCase()
      : 'btc_usdt';

  useEffect(() => {
    if (code && code !== currentPair) {
      dispatch({
        type: types.SET_CURRENT_PAIR_START,
        payload: code,
      });
    }
  }, [code]);

  useEffect(() => {
    if (isLang) {
      dispatch({ type: types.SET_LANGUAGE_START, payload: isLang });
    }
  }, [isLang]);

  const refAutoScroll = useRef(null);
  useEffect(() => {
    if (refAutoScroll?.current && window.pageYOffset > 0) {
      refAutoScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname, refAutoScroll]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isTheme && isTheme === 'dark') {
      wrapperRef.current.parentNode.parentNode.style.backgroundColor =
        'transparent';
    }
  }, [isTheme]);
  // This function is temp, because unnecessary loader at referral
  // const loaderStart = () => {
  //   if (!window.location.pathname.includes('referrals')) return <Loader />;
  //   return null;
  // };

  const checkRoutesForDarkTheme =
    location.pathname === routes.User.IdentityVerificationKYC.path ||
    location.pathname === routes.BuyCrypto.Exchange.path ||
    location.pathname === routes.Wallets.SpotWallets.path ||
    location.pathname === routes.ContactUs.path ||
    location.pathname === routes.RiskNotifications.path ||
    location.pathname === routes.FAQs.path;

  return (
    <>
      {!isMobileApp && <Cookie />}
      <StrictMode>
        <SocketConnect />
        <SocketPrivateNotifications />
        {isLogin &&
          location.pathname !==
            routes.User.ChatAdminManagmement.Messages.path &&
          location.pathname !==
            routes.User.ChatAdminManagmement.ChatUsers.path &&
          !isMobileApp && <Chat />}
        <div ref={refAutoScroll} />
        <div className="wrapper" ref={wrapperRef}>
          <div
            className={`${
              checkRoutesForDarkTheme && isTheme === 'dark'
                ? 'content dark-app'
                : 'content'
            } ${
              showBanner && (isAndroid || isIOS) && !isMobileApp
                ? 'content--with-banner'
                : ''
            } ${theme}`}
          >
            <Modal />
            {thisDemo() && <DemoHead />}
            {!location.search.includes('app=true') && (
              <>
                <DownloadAppBanner
                  showBanner={showBanner}
                  onShowBanner={handleShowBanner}
                />
                <Headers isLogin={isLogin} />
              </>
            )}
            <Suspense fallback={<Loader />}>
              {/* <Suspense fallback={loaderStart()}> */}
              <Switch>
              <Route path={routes.Misc.Terms.path} component={routes.Misc.Terms.component} />
              <Route path={routes.Misc.PrivacyAndTerms.path} component={routes.Misc.PrivacyAndTerms.component} />
              <Route path={routes.Misc.AmlPolicy.path} component={routes.Misc.AmlPolicy.component} />

                <AuthWrapper
                  exact
                  path={routes.Root.path}
                  component={routes.Home.component}
                />

                <AuthWrapper
                  path={routes.Auth.Login.path}
                  component={routes.Auth.Login.component}
                />
                <AuthWrapper
                  path={routes.Auth.Signup.path}
                  component={routes.Auth.Signup.component}
                />
                <AuthWrapper
                  path={routes.Auth.Recovery.path}
                  component={routes.Auth.Recovery.component}
                />

                <AuthWrapper
                  path={routes.Auth.SmsLoginChecked.path}
                  component={routes.Auth.SmsLoginChecked.component}
                />

                <AuthWrapper
                  path={routes.Auth.ConfirmMobilePhone.path}
                  component={routes.Auth.ConfirmMobilePhone.component}
                />

                <AuthWrapper
                  path={routes.Auth.ApproveDevice.path}
                  component={routes.Auth.ApproveDevice.component}
                />

                <AuthWrapper
                  path={routes.BuyCrypto.Exchange.path}
                  component={routes.BuyCrypto.Exchange.component}
                />

                <AuthWrapper
                  path={routes.BuyCrypto.BuyCrypto.path}
                  component={routes.BuyCrypto.BuyCrypto.component}
                />
                {/* <AuthWrapper
                  path={routes.BuyCrypto.BankTransfer.path}
                  component={routes.BuyCrypto.BankTransfer.component}
                /> */}
                {/* <AuthWrapper
                  path={routes.BuyCrypto.P2P.path}
                  component={routes.BuyCrypto.P2P.component}
                /> */}
                <AuthWrapper
                  path={routes.Pairs.path}
                  component={routes.Pairs.component}
                />
                <AuthWrapper
                  path={routes.Trade.SpotTrade.path}
                  component={routes.Trade.SpotTrade.component}
                />
                <AuthWrapper
                  path={routes.Auth.VerifyYourEmail.path}
                  component={routes.Auth.VerifyYourEmail.component}
                />
                {/* <AuthWrapper
                  path={routes.Trade.MarginTrade.path}
                  component={routes.Trade.MarginTrade.component}
                /> */}
                {/* <AuthWrapper
                  path={routes.Trade.FuturesTrade.path}
                  component={routes.Trade.FuturesTrade.component}
                /> */}
                {/* <AuthWrapper
                  path={routes.Trade.MarginTrade.path}
                  component={routes.Trade.MarginTrade.component}
                /> */}
                {/* <AuthWrapper
                  path={routes.Trade.ForexTrade.path}
                  component={routes.Trade.ForexTrade.component}
                /> */}

                {/* <AuthWrapper
                  path={routes.BinaryOptions.path}
                  component={routes.BinaryOptions.component}
                /> */}

                {/* <AuthWrapper
                  path={routes.Analytics.ValueAnalytics.path}
                  component={routes.Analytics.ValueAnalytics.component}
                /> */}
                {/* <AuthWrapper
                  path={routes.Analytics.CopyTrading.path}
                  component={routes.Analytics.CopyTrading.component}
                /> */}

                {!thisDemo() && (
                  <AuthWrapper
                    path={routes.Referral.path}
                    component={routes.Referral.component}
                  />
                )}

                <AuthWrapper
                  path={routes.FAQs.path}
                  component={routes.FAQs.component}
                />

                <AuthWrapper
                  path={routes.AboutUs.path}
                  component={routes.AboutUs.component}
                />

                <AuthWrapper
                  path={routes.ContactUs.path}
                  component={routes.ContactUs.component}
                />

                <AuthWrapper
                  path={routes.Auth.ResetPassword.path}
                  component={routes.Auth.ResetPassword.component}
                />

                <AuthWrapper
                  path={routes.Auth.Reset2FA.path}
                  component={routes.Auth.Reset2FA.component}
                />

                <AuthWrapper
                  path={routes.TermsOfUse.path}
                  component={routes.TermsOfUse.component}
                />

                <AuthWrapper
                  path={routes.User.path}
                  component={routes.User.component}
                />

                <AuthWrapper
                  path={routes.Wallets.path}
                  component={routes.Wallets.component}
                />

                <AuthWrapper
                  path={routes.PrivacyPolicy.path}
                  component={routes.PrivacyPolicy.component}
                />

                <AuthWrapper
                  path={routes.AMLPolicy.path}
                  component={routes.AMLPolicy.component}
                />

                <AuthWrapper
                  path={routes.RiskNotifications.path}
                  component={routes.RiskNotifications.component}
                />

                <AuthWrapper
                  path={routes.Safu.path}
                  component={routes.Safu.component}
                />

                <AuthWrapper
                  path={routes.Vacancy.path}
                  component={routes.Vacancy.component}
                />

                <AuthWrapper
                  path={routes.Cashback.path}
                  component={routes.Cashback.component}
                />

                <AuthWrapper
                  path={routes.FeesAndLimits.path}
                  component={routes.FeesAndLimits.component}
                />

                <AuthWrapper
                  path={routes.Apis.path}
                  component={routes.Apis.component}
                />

                <AuthWrapper
                  path={routes.ApisDocs.path}
                  component={routes.ApisDocs.component}
                />

                <AuthWrapper
                  exact
                  path={routes.Vote.path}
                  component={routes.Vote.component}
                />

                <AuthWrapper
                  path={routes.Vote.InfoForUsers.path}
                  component={routes.Vote.InfoForUsers.component}
                />

                <AuthWrapper
                  path={routes.Vote.InfoForProjects.path}
                  component={routes.Vote.InfoForProjects.component}
                />

                <AuthWrapper
                  path={routes.Vote.VoteForProject.path}
                  component={routes.Vote.VoteForProject.component}
                />

                <AuthWrapper
                  path={routes.Vote.VoteIEOListing.path}
                  component={routes.Vote.VoteIEOListing.component}
                />

                <AuthWrapper
                  path={routes.Vote.VoteFutureProjects.path}
                  component={routes.Vote.VoteFutureProjects.component}
                />

                <AuthWrapper
                  path={routes.Vote.VoteEndedProjects.path}
                  component={routes.Vote.VoteEndedProjects.component}
                />

                <AuthWrapper
                  path={routes.ListingRequest.path}
                  component={routes.ListingRequest.component}
                />

                <AuthWrapper
                  path={routes.ListingForm.path}
                  component={routes.ListingForm.component}
                />


                <AuthWrapper
                  path={routes.CryptocurrencyAssetsAndStatus.path}
                  component={routes.CryptocurrencyAssetsAndStatus.component}
                />

                <AuthWrapper
                  path={routes.AccountVerify.path}
                  component={routes.AccountVerify.component}
                />

                {/* <AuthWrapper
                  path={routes.News.path}
                  component={routes.News.component}
                /> */}

                <AuthWrapper
                  path={routes.Auth.NewIP.path}
                  component={routes.Auth.NewIP.component}
                />

                <AuthWrapper
                  path={routes.LoginsHistory.path}
                  component={routes.LoginsHistory.component}
                />
                <Redirect to={routes.Root.path} />
              </Switch>
            </Suspense>
          </div>
          {!isMobileApp && <Footers refAutoScroll={refAutoScroll} />}
        </div>
      </StrictMode>
    </>
  );
};

export default App;
