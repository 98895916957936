import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../../types';
import api from '../../../services/api';
import notification from '../../../services/notification';
import { closeModal } from '../../../components/Base/Modal';

export function* putUsersResetPassword({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.resetPassword,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.USERS_RESET_PASSWORD_SUCCESS, payload: data });
    yield put({ type: types.GET_USER_DATA_START });
    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. Your password has been changed',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.USERS_RESET_PASSWORD_FAILURE });
    if (error?.response?.status === 401) return;
    if (error?.response?.data?.errors?.includes('invalid_totp_code')) {
      notification({
        type: 'error',
        title: 'Security',
        message: 'Error. The data is incorrect',
      });
    }
  }
}

export function* getUsersSecurityData() {
  try {
    const { data, status } = yield call(api.settings.security.getUserData);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_USER_SECURITY_DATA_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_USER_SECURITY_DATA_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* postEnableGoogle2FA({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.enableGoogle2FA,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.ENABLE_GOOGLE_2FA_SUCCESS, payload: data });
    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. 2FA is enable',
    });
    yield put({ type: types.GET_USER_SECURITY_DATA_START });
    yield put({
      type: types.GET_USER_DATA_START,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.ENABLE_GOOGLE_2FA_FAILURE });
    if (error?.response?.status === 401) return;
    if (error?.response?.data?.errors?.includes('invalid_totp_code')) {
      notification({
        type: 'error',
        title: 'Security',
        message: 'Error. The data is incorrect',
      });
    }
  }
}

export function* postDisableGoogle2FA({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.disableGoogle2FA,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.DISABLE_GOOGLE_2FA_SUCCESS, payload: data });
    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. 2FA is disabled',
    });
    yield put({ type: types.GET_USER_SECURITY_DATA_START });
    yield put({
      type: types.GET_USER_DATA_START,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.DISABLE_GOOGLE_2FA_FAILURE });
    if (error?.response?.status === 401) return;
    if (error?.response?.data?.errors?.includes('invalid_totp_code')) {
      notification({
        type: 'error',
        title: 'Security',
        message: 'Error. The data is incorrect',
      });
    }
  }
}

export function* getSecretKey() {
  try {
    const { data, status } = yield call(api.settings.security.getSecretKey);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_SECRET_KEY_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_SECRET_KEY_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* getSecurityOptions() {
  try {
    const { data, status } = yield call(
      api.settings.security.getSecurityOptions,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_SECURITY_OPTIONS_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_SECURITY_OPTIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* putSecurityOptions({ payload, closeModal }) {
  try {
    const { data, status } = yield call(
      api.settings.security.putSecurityOptions,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.PUT_SECURITY_OPTIONS_SUCCESS });
    yield put({ type: types.GET_SECURITY_OPTIONS_START });

    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. Your session settings has been changed',
    });
    if (closeModal) {
      closeModal();
    }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.PUT_SECURITY_OPTIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* setWithdrawalSecretPhrase({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.setWithdrawalSecretPhrase,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.SET_WITHDRAWAL_SECRET_PHRASE_SUCCESS });
    yield put({ type: types.GET_SECURITY_OPTIONS_START });

    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. Your secret phrase has been saved.',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.SET_WITHDRAWAL_SECRET_PHRASE_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    if (
      error?.response?.data?.errors?.secret_phrase?.includes(
        'the_secret_phrase_may_not_be_greater_than40_characters',
      )
    ) {
      notification({
        type: 'error',
        title: 'Error',
        message: 'The secret phrase may not be greater than 40 characters!',
      });
    }
  }
}

export function* getUserUniqueDevices() {
  try {
    const { data, status } = yield call(
      api.settings.security.getUserUniqueDevices,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_USER_UNIQUE_DEVICES_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_USER_UNIQUE_DEVICES_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* deleteUserUniqueDevice({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.deleteUserUniqueDevice,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.DELETE_USER_UNIQUE_DEVICE_SUCCESS,
      payload: payload.params.device_id,
    });

    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. Your unique device has been deleted.',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.DELETE_USER_UNIQUE_DEVICE_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* getUserWhitelistAddresses() {
  try {
    const { data, status } = yield call(
      api.settings.security.getUserWhitelistAddresses,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_USER_WHITELIST_ADDRESSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_USER_WHITELIST_ADDRESSES_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* updateUserWhitelistAddresses({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.updateUserWhitelistAddresses,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.UPDATE_USER_WHITELIST_ADDRESSES_SUCCESS,
      payload: data,
    });
    // yield put({
    //   type: types.GET_USER_WHITELIST_ADDRESSES_START,
    // });

    notification({
      type: 'success',
      title: 'Security',
      message: 'Success. Your addresses has been updated.',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.UPDATE_USER_WHITELIST_ADDRESSES_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* checkValidAddress({
  payload,
  setIsValid = () => {},
  setData = () => {},
  result = [],
  idx = 0,
}) {
  try {
    const { data, status } = yield call(
      api.settings.security.checkValidAddress,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.CHECK_USER_VALID_ADDRESS_SUCCESS,
    });

    if (data?.is_valid) {
      notification({
        type: 'success',
        title: 'Security',
        message: 'Success. Your address is valid.',
      });
      setIsValid(true);
      setData(
        result.map(el =>
          el.asset_code === payload.asset_code
            ? {
                ...el,
                error: false,
                addresses: el.addresses.map((item, i) =>
                  i === idx
                    ? { ...item, address: payload.address, isValid: true }
                    : item,
                ),
              }
            : el,
        ),
      );
    } else {
      notification({
        type: 'error',
        title: 'Security',
        message: 'Error. Your address is invalid!',
      });
      setIsValid(false);

      setData(
        result.map(el =>
          el.asset_code === payload.asset_code
            ? {
                ...el,
                error: true,
                addresses: el.addresses.map((item, i) =>
                  i === idx
                    ? { ...item, address: payload.address, isValid: false }
                    : item,
                ),
              }
            : el,
        ),
      );
    }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.CHECK_USER_VALID_ADDRESS_FAILURE });
    notification({
      type: 'error',
      title: 'Security',
      message: 'Error. Your address is invalid!',
    });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* getUserLoginHistory({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.getUserLoginHistory,
      payload,
    );

    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_USER_LOGIN_HISTORY_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_USER_LOGIN_HISTORY_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* postAproveWithdrawal({ body, clearFields }) {
  try {
    const { data, status } = yield call(
      api.settings.security.postAproveWithdrawal,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_APPROVE_WITHDRAWAL_SUCCESS,
    });
    yield call(clearFields);
    notification({
      type: 'success',
      title: 'Withdrawal',
      message: 'Your withdrawal is successfully approved',
    });
    closeModal();
  } catch (error) {
    yield put({
      type: types.POST_APPROVE_WITHDRAWAL_FAILURE,
    });
    if (error?.response?.status === 401) return;
    if (error?.response?.data?.errors?.includes('model_not_found')) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'You entered wrong email code',
      });
    }
  }
}

export function* postResendAproveWithdrawalCode({ payload }) {
  try {
    const { data, status } = yield call(
      api.settings.security.postResendAproveWithdrawalCode,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_RESEND_APPROVE_WITHDRAWAL_CODE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: types.POST_RESEND_APPROVE_WITHDRAWAL_CODE_FAILURE,
    });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* watcherUsersResetPassword() {
  yield takeLatest(types.USERS_RESET_PASSWORD_START, putUsersResetPassword);
}

export function* watcherGetUserSecurityData() {
  yield takeLatest(types.GET_USER_SECURITY_DATA_START, getUsersSecurityData);
}

export function* watcherEnableGoogle2FA() {
  yield takeLatest(types.ENABLE_GOOGLE_2FA_START, postEnableGoogle2FA);
}

export function* watcherDisableGoogle2FA() {
  yield takeLatest(types.DISABLE_GOOGLE_2FA_START, postDisableGoogle2FA);
}

export function* watcherGetSecretKey() {
  yield takeLatest(types.GET_SECRET_KEY_START, getSecretKey);
}

export function* watcherGetSecurityOptions() {
  yield takeLatest(types.GET_SECURITY_OPTIONS_START, getSecurityOptions);
}

export function* watcherPutSecurityOptions() {
  yield takeLatest(types.PUT_SECURITY_OPTIONS_START, putSecurityOptions);
}

export function* watcherSetWithdrawalSecretPhrase() {
  yield takeLatest(
    types.SET_WITHDRAWAL_SECRET_PHRASE_START,
    setWithdrawalSecretPhrase,
  );
}

export function* watcherGetUserUniqueDevices() {
  yield takeLatest(types.GET_USER_UNIQUE_DEVICES_START, getUserUniqueDevices);
}

export function* watcherDeleteUserUniqueDevice() {
  yield takeLatest(
    types.DELETE_USER_UNIQUE_DEVICE_START,
    deleteUserUniqueDevice,
  );
}

export function* watcherGetUserWhitelistAddresses() {
  yield takeLatest(
    types.GET_USER_WHITELIST_ADDRESSES_START,
    getUserWhitelistAddresses,
  );
}

export function* watcherUpdateUserWhitelistAddresses() {
  yield takeLatest(
    types.UPDATE_USER_WHITELIST_ADDRESSES_START,
    updateUserWhitelistAddresses,
  );
}

export function* watcherCheckValidAddress() {
  yield takeLatest(types.CHECK_USER_VALID_ADDRESS_START, checkValidAddress);
}

export function* watcherGetUserLoginHistory() {
  yield takeLatest(types.GET_USER_LOGIN_HISTORY_START, getUserLoginHistory);
}

export function* watcherPostApproveWithdrawal() {
  yield takeLatest(types.POST_APPROVE_WITHDRAWAL_START, postAproveWithdrawal);
}

export function* watcherPostResendAproveWithdrawalCode() {
  yield takeLatest(
    types.POST_RESEND_APPROVE_WITHDRAWAL_CODE_START,
    postResendAproveWithdrawalCode,
  );
}
