import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector } from '../../redux/sockets/selectors';
import { socketTokenSelector } from '../../redux/auth/selectors';

const SocketChat = ({ children, lang }) => {
  const dispatch = useDispatch();
  const isSocketOpen = useSelector(socketSelector);
  const socketToken = useSelector(socketTokenSelector);


  useEffect(() => {
    if (socketToken) {
      dispatch({ type: 'SOCKET_OPEN', socketToken });
    }
  }, [dispatch, socketToken]);
  useEffect(() => {
    if (isSocketOpen) {
      dispatch({
        type: 'SOCKET_SEND',
        payload: [5, `chat:${lang}`],
      });
    }
    return () => {
      if (isSocketOpen) {
        dispatch({
          type: 'SOCKET_SEND',
          payload: [6, `chat:${lang}`],
        });
      }
    };
  }, [isSocketOpen, lang]);

  return <>{children}</>;
};

export default SocketChat;
