import React from 'react';
import L from 'i18n-react';
import { useHistory } from 'react-router-dom';
import { closeModal } from '../../Modal';
import routes from '../../../../routes';

const ModalLoginSecurity = ({ data }) => {
  const history = useHistory();

  const handleMoveToSecurity = () => {
    history.push(routes.User.Security.path);
    closeModal();
  };

  return (
    <div className="theme-modal theme-modal--regular">
      <button className="close-modal" type="button" onClick={closeModal}>
        <svg
          className="svg-inline--fa fa-times fa-w-11"
          aria-hidden="true"
          data-prefix="fa"
          data-icon="times"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 352 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          />
        </svg>
      </button>
      <div className="modal-header">
        <p className="modal-title">
          {L.translate('Base.Modals.ModalSecurityChecked.security_check_title')}
        </p>
      </div>
      <div className="modal-body">
        <div className="modal-text modal-text--type3">
          <p>
            {L.translate(
              'Base.Modals.ModalSecurityChecked.security_check_subtitle',
            )}
          </p>
        </div>
        <div className="choose-security">
          {!data?.email_confirmed ? (
            <div className="security-type-col">
              <button
                className="security-type"
                type="button"
                onClick={handleMoveToSecurity}
              >
                <span className="security-type__icon">
                  <img src="/img/email-verification.png" alt="" />
                </span>
                <span className="security-type__text">Email Verification</span>
              </button>
            </div>
          ) : null}
          {!data?.phone_confirmed ? (
            <div className="security-type-col">
              <button
                className="security-type"
                type="button"
                onClick={handleMoveToSecurity}
              >
                <span className="security-type__icon">
                  <img src="/img/mobile-verification.png" alt="" />
                </span>
                <span className="security-type__text">
                  Mobile phone Verification
                </span>
              </button>
            </div>
          ) : null}
          {!data?.google2fa_enabled ? (
            <div className="security-type-col">
              <button
                className="security-type"
                type="button"
                onClick={handleMoveToSecurity}
              >
                <span className="security-type__icon">
                  <img src="/img/google-verification.png" alt="" />
                </span>
                <span className="security-type__text">Google Verification</span>
              </button>
            </div>
          ) : null}
        </div>
        <div className="modal-notification-footer">
          <div className="modal-notification-footer__logo">
            <img
              src={'/img/stablecoin.marketupdated-1.svg'}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLoginSecurity;
