import React from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { languageSelector } from '../../../../redux/lng/selectors';

const DemoHead = ({ isLogin }) => {
  const language = useSelector(languageSelector); // need to update language

  return (
    <div style={{ height: 40 }}>
      <div
        style={{
          background: '#ca0233',
          width: '100%',
          height: 40,
          color: '#000',
          position: 'fixed',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>{L.translate('Demo.head')}</span>
      </div>
    </div>
  );
};

export default React.memo(DemoHead);
