import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

function* getVacancy() {
  try {
    const { data, status } = yield call(api.vacancy.getVacancy);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_VACANCY_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_VACANCY_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetVacancy() {
  yield takeLatest(types.GET_VACANCY_START, getVacancy);
}
