import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App';
import './styles/css/index.css';
import { store, persistor } from './redux/store';
import { axiosInterceptors } from './services/axiosInterceptors';
import Loader from './components/Base/Loader';
import { getLS, setLS } from './services/localStorage';
import { device_id } from './services/helpers';
import { ThemeContext, ThemeProvider } from './ThemeContext';

console.log(
  `%cBuild version: ${process.env.REACT_APP_VERSION || 'localhost'}`,
  'color: #0B6623; background: #000; padding: 15px 50px; font-size: 16px;',
);

export const Index = () => {
  const history = useHistory();
  const { theme } = useContext(ThemeContext); // Ensure ThemeProvider wraps this component
  const dispatch = useDispatch();
  axiosInterceptors({ history, dispatch });

  if (!getLS('device_hash')) {
    setLS('device_hash', device_id());
  }

  console.log('index rerender');
  return (
    <>
      <App />
      <NotificationContainer />
    </>
  );
};
// fix
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
    <ThemeProvider> {/* Wrap Index with ThemeProvider */}
      <BrowserRouter>
        <Index />
      </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
