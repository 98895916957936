import types from '../types';

const fees = (state = {}, { type, payload }) => {
  switch (type) {
    case types.GET_FEES_SUCCESS:
      return payload;
    case types.LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default fees;
