import types from '../types';

const videos = (
  state = {
    data: [],
    loader: true,
  },
  { type, payload },
) => {
  switch (type) {
    case types.GET_VIDEOS_START:
      return { ...state, loader: true };

    case types.GET_VIDEOS_SUCCESS:
      return { loader: false, data: payload };

    case types.GET_VIDEOS_FAILURE:
      return { ...state, loader: false };

    default:
      return state;
  }
};

export default videos;
