import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import routes from '../../../routes';

const Cookie = () => {
  return (
    <CookieConsent
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.85)',
        padding: '30px 0',
        zIndex: 202,
      }}
      disableButtonStyles
      contentStyle={{
        paddingRight: '15px',
        paddingLeft: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
        maxWidth: '1140px',
        flex: 'none',
      }}
      buttonWrapperClasses="cookies__btn"
      buttonClasses="page-btn page-btn--full page-btn--type7"
      buttonText="I accept"
    >
      <div
        className="cookies__text"
        style={{ margin: '0 auto', textAlign: 'center' }}
      >
        <p>
          We use cookies to enhance your experience, analyze our traffic, and
          for security and marketing. By visiting our website you agree to our
          use of cookies.
        </p>
        <Link to={routes.PrivacyPolicy.path}>Read more about cookies</Link>
      </div>
    </CookieConsent>
  );
};

export default Cookie;
