import { act } from 'react-dom/test-utils';
/* eslint-disable no-else-return */
import { combineReducers } from 'redux';
import types from '../../types';

const initialStateTables = {
  openedOrders: [],
  openedPositions: [],
  ordersHistory: [],
  fills: [],
  loading: false,
};

const tables = (state = initialStateTables, action) => {
  switch (action.type) {
    case types.FUTURES_GET_OPENED_ORDERS_START:
    case types.FUTURES_GET_OPENED_POSITIONS_START:
    case types.FUTURES_GET_ORDERS_HISTORY_START:
    case types.FUTURES_GET_FILLS_START:
      return {
        ...state,
        loading: true,
      };
    case types.FUTURES_GET_OPENED_ORDERS_SUCCESS:
      return {
        ...state,
        openedOrders: action.payload,
        loading: false,
      };
    case types.FUTURES_GET_OPENED_POSITIONS_SUCCESS:
      return {
        ...state,
        openedPositions: action.payload,
        loading: false,
      };
    case types.UPDATE_FUTURES_OPENED_POSITIONS: {
      // NEED TO CHANGE
      console.log(('positions from sockets: ', action.payload));
      const exist = state.openedPositions.some(
        order => order.symbol === action.payload.symbol, // prev it was id, need testing
      );

      if (exist) {
        if (action.payload.size === 0) {
          return {
            ...state,
            openedPositions: state.openedPositions.filter(
              pos => pos.symbol !== action.payload.symbol,
            ),
          };
        }
        return {
          ...state,
          openedPositions: state.openedPositions.map(pos => {
            if (pos.symbol === action.payload.symbol) {
              return action.payload;
            }

            return pos;
          }),
        };
      } else {
        if (action.payload.size !== 0) {
          return {
            ...state,
            openedPositions: [action.payload, ...state.openedPositions],
          };
        }
        return state;
      }
    }

    case types.FUTURES_GET_ORDERS_HISTORY_SUCCESS:
      return {
        ...state,
        ordersHistory: action.payload,
        loading: false,
      };
    case types.FUTURES_GET_FILLS_SUCCESS:
      return {
        ...state,
        fills: action.payload,
        loading: false,
      };
    case types.UPDATE_FUTURES_FILLS: {
      console.log(action.payload);
      return {
        ...state,
        fills: [action.payload, ...state.fills],
      };
    }
    case types.FUTURES_GET_OPENED_ORDERS_FAILURE:
    case types.FUTURES_GET_OPENED_POSITIONS_FAILURE:
    case types.FUTURES_GET_ORDERS_HISTORY_FAILURE:
    case types.FUTURES_GET_FILLS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // This is for Sagas.
    // Sent payload is one object with id, which was changed recently.
    // We need to find an object with the same id in openedOrders or ordersHistory,
    // because we have one sockets for two of these tables.
    case types.UPDATE_FUTURES_ORDERS:
      if (action.payload.status === 'opened') {
        // check, may be not only 'opened' status
        const exist = state.openedOrders.some(
          order => order.id === action.payload.id,
        );
        if (exist) {
          return {
            ...state,
            openedOrders: state.openedOrders.map(order => {
              if (order.id === action.payload.id) {
                return action.payload;
              }
              return order;
            }),
          };
        } else {
          return {
            ...state,
            openedOrders: [action.payload, ...state.openedOrders],
          };
        }
      } else {
        // removing if status filled or canceled
        console.log(action.payload);
        if (action.payload.status === 'filled' || 'canceled') {
          return {
            ...state,
            openedOrders: state.openedOrders.filter(
              pos => pos.id !== action.payload.id,
            ),
          };
        }
        return {
          ...state,
          ordersHistory: [action.payload, ...state.ordersHistory],
        };
      }

    case types.FUTURES_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        openedOrders: state.openedOrders.filter(
          order => order.id !== action.payload,
        ),
      };

    case types.FUTURES_CANCEL_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        openedOrders: [],
      };
    case types.LOGOUT_SUCCESS:
      return initialStateTables;

    default:
      return state;
  }
};

const initialStateRecentTrades = {
  trades: [],
  loading: false,
};

const recentTrades = (state = initialStateRecentTrades, action) => {
  switch (action.type) {
    case types.FUTURES_GET_RECENT_TRADES_START:
      return {
        ...state,
        loading: true,
      };
    case types.FUTURES_GET_RECENT_TRADES_SUCCESS:
      return {
        ...state,
        trades: action.payload,
        loading: false,
      };
    case types.FUTURES_GET_RECENT_TRADES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_FUTURES_RECENT_TRADES: {
      // NEED TO CHANGE
      console.log('futures recent trades sockets ', action.payload);
      return {
        trades: [action.payload, ...state.trades],
      };
    }
    case types.LOGOUT_SUCCESS:
      return initialStateRecentTrades;
    default:
      return state;
  }
};

const initialStateOrderBook = {
  orders: {},
  loading: false,
};

const orderBook = (state = initialStateOrderBook, action) => {
  switch (action.type) {
    case types.FUTURES_GET_ORDER_BOOK_START:
      return {
        ...state,
        loading: true,
      };
    case types.FUTURES_GET_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case types.FUTURES_GET_ORDER_BOOK_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_FUTURES_ORDER_BOOK: {
      console.log('socket order book', action.payload);
      return {
        orders: action.payload,
      };
    }
    default:
      return state;
  }
};
const initialStateContractsList = {
  contracts: [],
  loading: false,
};

const contractsList = (state = initialStateContractsList, action) => {
  switch (action.type) {
    case types.FUTURES_GET_CONTRACTS_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case types.FUTURES_GET_CONTRACTS_LIST_SUCCESS:
    case types.UPDATE_FUTURES_CONTRACTS:
      console.log('UPDATE_FUTURES_CONTRACTS', action.payload);
      return {
        ...state,
        contracts: action.payload,
        loading: false,
      };
    case types.FUTURES_GET_CONTRACTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

const futures = combineReducers({
  tables,
  orderBook,
  recentTrades,
  contractsList,
});

export default futures;
