import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../services/api';
import types from '../../types';

function* getUserTradeVolumeStatistics() {
  try {
    const { data, status } = yield call(
      api.trading.getUserTradeVolumeStatistics,
    );

    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_USER_TRADE_VOLUME_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_USER_TRADE_VOLUME_STATISTICS_FAILURE,
    });
  }
}

export function* watcherGetUserTradeVolumeStatistics() {
  yield takeLatest(
    types.GET_USER_TRADE_VOLUME_STATISTICS_START,
    getUserTradeVolumeStatistics,
  );
}

function* getTradingVolumeLevels() {
  try {
    const { data, status } = yield call(api.trading.getTradingVolumeLevels);

    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_TRADING_VOLUME_LEVELS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TRADING_VOLUME_LEVELS_FAILURE,
    });
  }
}

export function* watcherGetTradingVolumeLevels() {
  yield takeLatest(
    types.GET_TRADING_VOLUME_LEVELS_START,
    getTradingVolumeLevels,
  );
}

function* getCurrentCahsback() {
  try {
    const { data, status } = yield call(api.trading.getCurrentCahsback);

    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_CURRENT_CASHBACK_RATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CURRENT_CASHBACK_RATE_FAILURE,
    });
  }
}

export function* watcherGetCurrentCahsback() {
  yield takeLatest(types.GET_CURRENT_CASHBACK_RATE_START, getCurrentCahsback);
}

function* getCashbacksByTrades({ payload }) {
  try {
    const { data, status } = yield call(
      api.trading.getCashbacksByTrades,
      payload,
    );

    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_CASHBACKS_BY_TRADES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_CASHBACKS_BY_TRADES_FAILURE,
    });
  }
}

export function* watcherGetCashbacksByTrades() {
  yield takeLatest(types.GET_CASHBACKS_BY_TRADES_START, getCashbacksByTrades);
}
