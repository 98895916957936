import EN from './EN.json';
import RU from './RU.json';
import DE from './DE.json';
import FR from './FR.json';
import IT from './IT.json';
import IN from './IN.json';
import CZ from './CZ.json';
import KR from './KR.json';

export const languages = {
  EN,
  RU,
  IT,
  DE,
  CZ,
  IN,
  FR,
  KR,
};
