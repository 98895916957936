import React from 'react';

const routes = {
  Root: {
    path: `/`,
  },

  Home: {
    path: `/home`,
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/HomePage' /* webpackChunkName: "HomePage" */
      ),
    ),
  },

  Auth: {
    Login: {
      path: '/login',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/LoginPage' /* webpackChunkName: "LoginPage" */
        ),
      ),
    },
    Signup: {
      path: '/signup',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/SignupPage' /* webpackChunkName: "SignupPage" */
        ),
      ),
    },
    Recovery: {
      path: '/recovery',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/ForgotPasswordPage' /* webpackChunkName: "ForgotPasswordPage" */
        ),
      ),
    },
    ResetPassword: {
      path: '/password_recovery',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/RecoveryPasswordPage' /* webpackChunkName: "RecoveryPasswordPage" */
        ),
      ),
    },
    Reset2FA: {
      path: '/reset_2FA',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/Reset2FA' /* webpackChunkName: "Reset2FA" */
        ),
      ),
    },
    VerifyYourEmail: {
      path: '/verify_you_email',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/VerifyYourEmail' /* webpackChunkName: "VerifyYourEmail" */
        ),
      ),
    },
    NewIP: {
      path: '/confirm_IP',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/NewIPLogged' /* webpackChunkName: "NewIPLogged" */
        ),
      ),
    },
    SmsLoginChecked: {
      path: '/login_sms',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/SmsCodeNew' /* webpackChunkName: "SmsCodeLogin" */
        ),
      ),
    },
    ConfirmMobilePhone: {
      path: '/confirm_phone',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/ConfirmPhone' /* webpackChunkName: "ConfirmMobilePhone" */
        ),
      ),
    },
    ApproveDevice: {
      path: '/approve_device',
      component: React.lazy(() =>
        import(
          '../components/Pages/Auth/ApproveDevice' /* webpackChunkName: "ApproveDevice" */
        ),
      ),
    },
  },

  Pairs: {
    path: '/pairs',
    component: React.lazy(() =>
      import(
        '../components/Pages/Trade/PairsPage' /* webpackChunkName: "PairsPage" */
      ),
    ),
    Favorites: {
      path: '/pairs/favorites',
      SpotFavorites: {
        path: '/pairs/favorites/spot',
      },
      FuturesFavorites: {
        path: '/pairs/favorites/futures',
      },
      MarginFavorites: {
        path: '/pairs/favorites/margin',
      },
    },
    Spot: {
      path: '/pairs/spot',
    },
    // Margin: {
    //   path: '/pairs/margin',
    // },
    // Futures: {
    //   path: '/pairs/futures',
    // },
  },

  AboutUs: {
    path: '/about-us',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/AboutUsPage' /* webpackChunkName: "AboutUsPage" */
      ),
    ),
  },

  BuyCrypto: {
    // BankTransfer: {
    //   path: '/bank-transfer',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/BuyCrypto/BankTransfer' /* webpackChunkName: "BankTransfer" */
    //     ),
    //   ),
    // },
    Exchange: {
      path: '/exchange',
      component: React.lazy(() =>
        import(
          '../components/Pages/Exchange' /* webpackChunkName: "Exchange" */
        ),
      ),
    },
    BuyCrypto: {
      path: '/buy_crypto',
      component: React.lazy(() =>
        import(
          '../components/Pages/BuyCrypto' /* webpackChunkName: "BuyCrypto" */
        ),
      ),
    },
    // P2P: {
    //   path: '/p-two-p',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/BuyCrypto/P2P' /* webpackChunkName: "BuyCrypto_P2P" */
    //     ),
    //   ),
    // },
  },

  Trade: {
    path: '/trade',
    SpotTrade: {
      path: '/trade/spot',
      component: React.lazy(() =>
        import(
          '../components/Pages/Trade/SpotTrade' /* webpackChunkName: "SpotTrade" */
        ),
      ),
    },
    // MarginTrade: {
    //   path: '/trade/margin',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Trade/MarginTrade' /* webpackChunkName: "MarginTrade" */
    //     ),
    //   ),
    // },
    // ForexTrade: {
    //   path: '/trade/forex',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Trade/ForexTrade' /* webpackChunkName: "ForexTrade" */
    //     ),
    //   ),
    // },
    // FuturesTrade: {
    //   path: '/trade/futures',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Trade/FuturesTrade' /* webpackChunkName: "FuturesTrade" */
    //     ),
    //   ),
    // },
  },

  // BinaryOptions: {
  //   path: '/binary-options',
  //   component: React.lazy(() =>
  //     import(
  //       '../components/Pages/BinaryOptions' /* webpackChunkName: "BinaryOptions" */
  //     ),
  //   ),
  // },

  // Analytics: {
  //   ValueAnalytics: {
  //     path: '/value-analytics',
  //     component: React.lazy(() =>
  //       import(
  //         '../components/Pages/Analytics/ValueAnalytics' /* webpackChunkName: "ValueAnalyticsPages" */
  //       ),
  //     ),
  //   },
  //   CopyTrading: {
  //     path: '/copy-trading',
  //     component: React.lazy(() =>
  //       import(
  //         '../components/Pages/Analytics/CopyTrading' /* webpackChunkName: "CopyTrading" */
  //       ),
  //     ),
  //   },
  // },

  FAQs: {
    path: '/FAQs',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/FAQsPage' /* webpackChunkName: "FAQsPage" */
      ),
    ),
  },

  ContactUs: {
    path: '/contact_us',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/ContactUsPages' /* webpackChunkName: "ContactUsPage" */
      ),
    ),
  },

  TermsOfUse: {
    path: '/terms_of_use',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/TermsOfUsePages' /* webpackChunkName: "TermsOfUsePages" */
      ),
    ),
  },

  User: {
    path: '/user',
    component: React.lazy(() =>
      import('../components/Pages/Users' /* webpackChunkName: "Users" */),
    ),
    Dashboard: {
      path: '/user/dashboard',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/Dashboard' /* webpackChunkName: "Dashboard" */
        ),
      ),
    },
    ApiManagement: {
      path: '/user/api_management',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/ApiManagement' /* webpackChunkName: "ApiManagement" */
        ),
      ),
    },
    Identification: {
      path: '/user/identification',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/Identification' /* webpackChunkName: "Identification" */
        ),
      ),
    },
    IdentityVerificationKYC: {
      path: '/user/verification_kyc',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/IdentityVerificationKYC' /* webpackChunkName: "IdentityVerificationKYC" */
        ),
      ),
    },
    PersonalInfo: {
      path: '/user/personal_info',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/PersonalInfo' /* webpackChunkName: "PersonalInfo" */
        ),
      ),
    },
    ChatAdminManagmement: {
      Messages: {
        path: '/user/messages',
        component: React.lazy(() =>
          import(
            '../components/Pages/Users/ChatAdminManagmement/Messages' /* webpackChunkName: "Messages" */
          ),
        ),
      },
      ChatUsers: {
        path: '/user/chat_users',
        component: React.lazy(() =>
          import(
            '../components/Pages/Users/ChatAdminManagmement/ChatUsers' /* webpackChunkName: "ChatUsers" */
          ),
        ),
      },
    },
    ChatSettings: {
      path: '/user/chat_settings',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/ChatSettings' /* webpackChunkName: "ChatSettings" */
        ),
      ),
    },
    // RewardCenter: {
    //   path: '/user/reward_center',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Users/RewardCenter' /* webpackChunkName: "RewardCenter" */
    //     ),
    //   ),
    // },
    Security: {
      path: '/user/security',
      component: React.lazy(() =>
        import(
          '../components/Pages/Users/SecurityNew' /* webpackChunkName: "Security" */
        ),
      ),
    },
  },
  Referral: {
    path: '/referral',
    component: React.lazy(() =>
      import(
        '../components/Pages/Users/Referral' /* webpackChunkName: "Referral" */
      ),
    ),
  },
  Wallets: {
    path: '/wallets',
    component: React.lazy(() =>
      import('../components/Pages/Wallets' /* webpackChunkName: "Wallets" */),
    ),

    SpotWallets: {
      path: '/wallets/spot',
      component: React.lazy(() =>
        import(
          '../components/Pages/Wallets/SpotWallets' /* webpackChunkName: "SpotWallets" */
        ),
      ),
      CryptoDeposit: {
        path: '/wallets/spot/deposit',
        component: React.lazy(() =>
          import(
            '../components/Pages/Wallets/SpotWallets/CryptoDeposit' /* webpackChunkName: "MarginWallets" */
          ),
        ),
      },
      CryptoWithdraw: {
        path: '/wallets/spot/withdraw',
        component: React.lazy(() =>
          import(
            '../components/Pages/Wallets/SpotWallets/CryptoWithdraw' /* webpackChunkName: "CryptoWithdraw" */
          ),
        ),
      },
      FiatDeposit: {
        path: '/wallets/spot/fiat/deposit',
        component: React.lazy(() =>
          import(
            '../components/Pages/Wallets/SpotWallets/FiatDeposit' /* webpackChunkName: "WalletsFiatDeposit" */
          ),
        ),
      },
      FiatWithdraw: {
        path: '/wallets/spot/fiat/withdraw',
        component: React.lazy(() =>
          import(
            '../components/Pages/Wallets/SpotWallets/FiatWithdraw' /* webpackChunkName: "WalletsFiatWithdraw" */
          ),
        ),
      },
    },

    // MarginWallets: {
    //   path: '/wallets/margin',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/MarginWallets' /* webpackChunkName: "MarginWallets" */
    //     ),
    //   ),
    // },

    // ForexWallets: {
    //   path: '/wallets/forex',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/ForexWallets' /* webpackChunkName: "ForexWallets" */
    //     ),
    //   ),
    // },

    // FuturesWallets: {
    //   path: '/wallets/futures',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/FuturesWallets' /* webpackChunkName: "FuturesWallets" */
    //     ),
    //   ),
    // },

    // P2PWallets: {
    //   path: '/wallets/p-two-p',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/P2PWallets' /* webpackChunkName: "P2PWallets" */
    //     ),
    //   ),
    // },

    AirdropWallet: {
      path: '/wallets/airdrop',
      component: React.lazy(() =>
        import(
          '../components/Pages/Wallets/AirdropWallet' /* webpackChunkName: "AirdropWallet" */
        ),
      ),
    },

    // BinaryOptionsWallets: {
    //   path: '/wallets/binary-options',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/BinaryOptionsWallets' /* webpackChunkName: "BinaryOptionsWallets" */
    //     ),
    //   ),
    // },
    // ReferralsWallets: {
    //   path: '/wallets/referrals',
    //   component: React.lazy(() =>
    //     import(
    //       '../components/Pages/Wallets/ReferralsWallets' /* webpackChunkName: "ReferralsWallets" */
    //     ),
    //   ),
    //   Funds: {
    //     path: '/wallets/referrals/funds',
    //   },
    //   Transfers: {
    //     path: '/wallets/referrals/transfers',
    //   },
    // },
    TransactionHistory: {
      path: '/wallets/transaction',
      DepositWithdrawal: {
        path: '/wallets/transaction/deposit-withdrawal',
      },
      Transfer: {
        path: '/wallets/transaction/transfer',
      },
      TransactionFiat: {
        path: '/wallets/transaction/fiat',
      },
      component: React.lazy(() =>
        import(
          '../components/Pages/Wallets/TransactionHistory' /* webpackChunkName: "TransactionHistory" */
        ),
      ),
    },
    Orders: {
      path: '/wallets/orders',
      OrdersHistory: {
        path: '/wallets/orders/orders-history',
      },
      Transfer: {
        path: '/wallets/orders/transfer',
      },
      component: React.lazy(() =>
        import(
          '../components/Pages/Wallets/OrdersInfo' /* webpackChunkName: "OrdersInfo" */
        ),
      ),
    },
  },
  PrivacyPolicy: {
    path: '/privacy_policy',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/PrivacyPolicyPages' /* webpackChunkName: "PrivacyPolicyPages" */
      ),
    ),
  },
  AMLPolicy: {
    path: '/AML_policy',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/AMLPolicy' /* webpackChunkName: "AMLPolicy" */
      ),
    ),
  },
  RiskNotifications: {
    path: '/risk_notifications',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/RiskNotifications' /* webpackChunkName: "RiskNotifications" */
      ),
    ),
  },
  Safu: {
    path: '/safu',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Safu' /* webpackChunkName: "RiskNotifications" */
      ),
    ),
  },
  AccountVerify: {
    path: '/account_verify',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/AccountVerification' /* webpackChunkName: "RiskNotifications" */
      ),
    ),
  },
  Vacancy: {
    path: '/vacancy',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Vacancy' /* webpackChunkName: "Vacancy" */
      ),
    ),
  },
  News: {
    path: '/news',
    component: React.lazy(() =>
      import('../components/Pages/Statics/News' /* webpackChunkName: "News" */),
    ),
  },
  Cashback: {
    path: '/cashback',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Cashback' /* webpackChunkName: "Cashback" */
      ),
    ),
  },
  Apis: {
    path: '/apis',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Apis' /* webpackChunkName: "FeesAndLimits" */
      ),
    ),
  },
  ApisDocs: {
    path: '/api_docs',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/ApisDocs' /* webpackChunkName: "FeesAndLimits" */
      ),
    ),
  },
  FeesAndLimits: {
    path: '/fees_and_limits',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/FeesAndLimits' /* webpackChunkName: "FeesAndLimits" */
      ),
    ),
  },
  Vote: {
    path: '/vote',
    component: React.lazy(() =>
      import('../components/Pages/Statics/Vote' /* webpackChunkName: "Vote" */),
    ),
    InfoForUsers: {
      path: '/vote/info_for_users',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/InfoForUsers' /* webpackChunkName: "VoteInfoForUsers" */
        ),
      ),
    },
    InfoForProjects: {
      path: '/vote/info_for_projects',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/InfoForProjects' /* webpackChunkName: "VoteInfoForProjects" */
        ),
      ),
    },
    VoteForProject: {
      path: '/vote/projects',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/Projects' /* webpackChunkName: "VoteProjects" */
        ),
      ),
    },
    VoteIEOListing: {
      path: '/vote/ieo',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/IEOListing' /* webpackChunkName: "VoteIEOListing" */
        ),
      ),
    },
    VoteFutureProjects: {
      path: '/vote/future_projects',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/ProjectsFuture' /* webpackChunkName: "VoteProjectsFuture" */
        ),
      ),
    },
    VoteEndedProjects: {
      path: '/vote/ended_projects',
      component: React.lazy(() =>
        import(
          '../components/Pages/Statics/Vote/ProjectsEnded' /* webpackChunkName: "VoteProjectsEnded" */
        ),
      ),
    },
  },
  ListingRequest: {
    path: '/listing_request',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Vote/ListingRequest' /* webpackChunkName: "VoteListingRequest" */
      ),
    ),
  },
  ListingForm: {
    path: '/listing_form',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/Vote/ListingForm' /* webpackChunkName: "VoteListingRequest" */
      ),
    ),
  },
  CryptocurrencyAssetsAndStatus: {
    path: '/assets_and_status',
    component: React.lazy(() =>
      import(
        '../components/Pages/Statics/StatusPage' /* webpackChunkName: "CryptocurrencyAssetsAndStatus" */
      ),
    ),
  },
  LoginsHistory: {
    path: '/login_history',
    component: React.lazy(() =>
      import(
        '../components/Pages/Users/LoginsHistory' /* webpackChunkName: "UserLoginsHistory" */
      ),
    ),
  },
  Misc:{
    Terms: {
      path: '/m/terms',
      component: React.lazy(() =>
        import(
          '../components/Pages/Misc/Terms' /* webpackChunkName: "MiscTerms" */
        ),
      ),
    },
    PrivacyAndTerms: {
      path: '/m/privacy-and-terms',
      component: React.lazy(() =>
        import(
          '../components/Pages/Misc/PrivacyAndTerms' /* webpackChunkName: "MiscPrivacyAndTerms" */
        ),
      ),
    },
    AmlPolicy: {
      path: '/m/aml-policy',
      component: React.lazy(() =>
        import(
          '../components/Pages/Misc/AmlPolicy' /* webpackChunkName: "MiscAmlPolicy" */
        ),
      ),
    },
  }
};
export default routes;
