import React from 'react';
import L from 'i18n-react';
import { closeModal } from '../../Modal';

const ModalError = ({ textError }) => {
  return (
    <div className="theme-modal theme-modal--regular ">
      <button type="button" className="close-modal" onClick={closeModal}>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.72431 0.281387C1.53527 0.0987908 1.28208 -0.00224601 1.01927 3.78936e-05C0.756455 0.00232179 0.505056 0.107744 0.319214 0.293598C0.133373 0.479452 0.0279575 0.730868 0.0256738 0.993696C0.02339 1.25652 0.12442 1.50973 0.307004 1.69879L6.09551 7.48768L0.306002 13.2766C0.210268 13.369 0.133908 13.4797 0.0813763 13.602C0.0288447 13.7243 0.00119436 13.8558 3.78454e-05 13.9889C-0.00111867 14.122 0.024242 14.254 0.0746405 14.3772C0.125039 14.5004 0.199466 14.6123 0.293578 14.7064C0.38769 14.8005 0.499604 14.875 0.622787 14.9254C0.745971 14.9758 0.877958 15.0011 1.01105 15C1.14414 14.9988 1.27567 14.9712 1.39795 14.9186C1.52024 14.8661 1.63085 14.7897 1.72331 14.694L7.51282 8.90508L13.3013 14.694C13.4904 14.8766 13.7436 14.9776 14.0064 14.9753C14.2692 14.973 14.5206 14.8676 14.7064 14.6818C14.8923 14.4959 14.9977 14.2445 15 13.9817C15.0022 13.7188 14.9012 13.4656 14.7186 13.2766L8.93013 7.48768L14.7186 1.69879C14.9012 1.50973 15.0022 1.25652 15 0.993696C14.9977 0.730868 14.8923 0.479452 14.7064 0.293598C14.5206 0.107744 14.2692 0.00232179 14.0064 3.78936e-05C13.7436 -0.00224601 13.4904 0.0987908 13.3013 0.281387L7.51282 6.07028L1.72431 0.280385V0.281387Z"
            fill="black"
          />
        </svg>
      </button>
      <div className="modal-notification-wrap">
        <div className="modal-notification modal-notification--error">
          <span className="modal-notification__icon">
            <svg
              className="fill"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9847 10.5L14.9551 7.5306C15.0527 7.43304 15.1301 7.31723 15.1829 7.18977C15.2357 7.0623 15.2629 6.92569 15.2629 6.78772C15.2629 6.64976 15.2357 6.51315 15.1829 6.38568C15.1301 6.25822 15.0527 6.14241 14.9551 6.04485C14.8576 5.94729 14.7418 5.86991 14.6143 5.81711C14.4869 5.76432 14.3502 5.73714 14.2123 5.73714C14.0743 5.73714 13.9377 5.76432 13.8102 5.81711C13.6828 5.86991 13.567 5.94729 13.4694 6.04485L10.5 9.0153L7.5306 6.04485C7.33358 5.84783 7.06636 5.73714 6.78772 5.73714C6.50909 5.73714 6.24187 5.84783 6.04485 6.04485C5.84783 6.24187 5.73714 6.50909 5.73714 6.78772C5.73714 6.92569 5.76432 7.0623 5.81711 7.18977C5.86991 7.31723 5.94729 7.43304 6.04485 7.5306L9.0153 10.5L6.04485 13.4694C5.84783 13.6664 5.73714 13.9336 5.73714 14.2123C5.73714 14.4909 5.84783 14.7581 6.04485 14.9551C6.24187 15.1522 6.50909 15.2629 6.78772 15.2629C7.06636 15.2629 7.33358 15.1522 7.5306 14.9551L10.5 11.9847L13.4694 14.9551C13.6664 15.1522 13.9336 15.2629 14.2123 15.2629C14.4909 15.2629 14.7581 15.1522 14.9551 14.9551C15.1522 14.7581 15.2629 14.4909 15.2629 14.2123C15.2629 13.9336 15.1522 13.6664 14.9551 13.4694L11.9847 10.5ZM10.5 21C4.70085 21 0 16.2991 0 10.5C0 4.70085 4.70085 0 10.5 0C16.2991 0 21 4.70085 21 10.5C21 16.2991 16.2991 21 10.5 21Z"
                fill="#FB3230"
              />
            </svg>
          </span>
          {L.translate('Base.Modals.Errors.error')}
        </div>
        <div className="modal-notification-text">
          <p>{textError}</p>
        </div>
        <div className="modal-notification-footer">
          <div className="modal-notification-footer__logo">
          <img
              src={'/img/stablecoin.marketupdated-1.svg'}
              alt=""
            />
          </div>
          <div className="modal-notification-actions">
            <button
              className="page-btn page-btn--full"
              type="button"
              onClick={closeModal}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalError;
