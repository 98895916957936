import types from '../types';

const inivitationsState = {
  loading: false,
  total: 0,
  per_page: 15,
  last_page: 1,
  current_page: 1,
  invitations_all: [],
  invitations_by_status: {
    pending: 0,
    sent: 0,
    completed: 0,
  },
  profit_by_users: {
    bonuses: {},
    total: 0,
  },
  user_bonuses: null,
};

const inivitations = (state = inivitationsState, { type, payload }) => {
  switch (type) {
    case types.REFERRAL_GET_INVITATIONS_START:
    case types.GET_USER_REFERRAL_BONUSES_START:
      return {
        ...state,
        loading: true,
      };
    case types.REFERRAL_GET_INVITATIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case types.GET_USER_REFERRAL_BONUSES_SUCCESS:
      return {
        ...state,
        user_bonuses: payload,
        loading: false,
      };
    case types.REFERRAL_GET_INVITATIONS_FAILURE:
    case types.GET_USER_REFERRAL_BONUSES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.LOGOUT_SUCCESS:
      return inivitationsState;
    default:
      return state;
  }
};

export default inivitations;
