import React from 'react';
import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import { openModal } from '../../components/Base/Modal';
import ModalForTrader from '../../components/Base/Modals/ModalForTrader';

function* getTraderInfo() {
  try {
    const { data, status } = yield call(api.traderInfo.getTraderInfo);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_TRADER_INFO_SUCCESS });

    if (data?.is_active) {
      openModal(() => <ModalForTrader info={data} />);
    }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_TRADER_INFO_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetTraderInfo() {
  yield takeLatest(types.GET_TRADER_INFO_START, getTraderInfo);
}
