import React, {
  useEffect,
  useState,
  useContext
} from 'react';import { useDispatch, useSelector } from 'react-redux';
import types from '../../../../redux/types';
import { languages } from '../../../../languages';
import { ThemeContext } from '../../../../ThemeContext';
import { useMediaQuery } from 'react-responsive';

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const { language } = useSelector(store => store.language);
  const [theme, setTheme] = useState('');

  const { theme: contextTheme } = useContext(ThemeContext);
  useEffect(() => {
    console.log('Apppp')
    setTheme(contextTheme);
  }, [contextTheme]);

  const setCurrLanguage = e => {
    const { id } = e.currentTarget;
    e.currentTarget.parentNode.parentNode.parentNode.parentNode.classList.remove(
      'active',
    );
    if (id) {
      dispatch({ type: types.SET_LANGUAGE_START, payload: id });
    }
  };
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  const diactiveteList = classList => {
    setTimeout(() => {
      classList.remove('active');
    }, 300);
  };

  return (
    
    <ul
      className="lang-drop"
      onMouseOver={e => e.currentTarget.classList.add('active')}
      onFocus={e => e.currentTarget.classList.add('active')}
      onMouseOut={e => e.currentTarget.classList.remove('active')}
      onBlur={e => diactiveteList(e.currentTarget.classList)}
    >
    
      <li className='change'>
      <div className='language' style={isMobile ? {marginBottom: '-5px'} : {marginBottom: '5px'}}>
     <svg class="bn-svg language-icon hover-color" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.23 20.403a9.011 9.011 0 005.684-7.153h-3.942c-.147 2.86-.793 5.388-1.741 7.153zm-.757-7.153c-.178 4.102-1.217 7.25-2.473 7.25-1.256 0-2.295-3.148-2.473-7.25h4.946zm0-2.5H9.527C9.705 6.648 10.744 3.5 12 3.5c1.256 0 2.295 3.148 2.473 7.25zm2.499 0h3.942a9.01 9.01 0 00-5.683-7.153c.948 1.765 1.594 4.293 1.741 7.153zm-9.936 0c.147-2.862.793-5.392 1.743-7.156a9.01 9.01 0 00-5.693 7.156h3.95zm0 2.5h-3.95a9.01 9.01 0 005.693 7.157c-.95-1.765-1.596-4.295-1.743-7.157z" fill="currentColor"></path></svg>
     </div>
        <div
          className="lang-list"
          onMouseOut={e =>
            e.currentTarget.parentNode.parentNode.classList.remove('active')
          }
          onBlur={e =>
            e.currentTarget.parentNode.parentNode.classList.remove('active')
          }
        >
          <ul
            className="lang-list__item"
            onMouseOut={e =>
              e.currentTarget.parentNode.parentNode.classList.remove('active')
            }
            onBlur={e =>
              e.currentTarget.parentNode.parentNode.classList.remove('active')
            }
          >
            {languages
              ? Object.keys(languages).map(key => (
                  <li
                    id={key}
                    key={key}
                    onClick={setCurrLanguage}
                    role="presentation"
                  >
                    <button
                      type="button"
                      className={`${theme === 'dark' ? 'lang-btn-dark' : 'lang-btn'}`}
                      // style={{ pointerEvents: 'none' }}
                    >
                      <span className="lang-flag">
                        <img src={`/img/flags/${key}.png`} alt="" />
                      </span>
                      <span className="lang-btn__text">{key}</span>
                    </button>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </li>
    </ul>
  );
};

export default SelectLanguage;
