import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../../types';
import api from '../../../services/api';
import notification from '../../../services/notification';
import {
  checkPostStatusVerifyUserKYC,
  verifyPostUserKYC,
} from '../../../services/kycApi';
import { iOS, windowReference } from '../../../services/helpers';

function* getKycVerify() {
  try {
    const { data, status } = yield call(
      api.settings.identification.getKycVerifications,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    yield put({
      type: types.GET_KYC_VERIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: types.GET_KYC_VERIFICATIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.dir(error);
  }
}

export function* watcherGetKycVerify() {
  yield takeLatest(types.GET_KYC_VERIFICATIONS_START, getKycVerify);
}

function* verifyUserStartKYC({ payload, setIosLink }) {
  try {
    const { data, status } = yield call(api.auth.verifyUserStartKYC, payload);

    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    yield put({
      type: types.VERIFY_USER_KYC_SUCCESS,
    });
    if (data) {
      notification({
        type: 'info',
        title: 'KYC',
        message: 'Open Ondato KYC',
      });
    }
  } catch (error) {
    yield put({ type: types.VERIFY_USER_KYC_FAILURE });
    if (error?.response?.status === 401) return;
    console.dir(error);
  }
}

export function* watcherVerifyUserStartKYC() {
  yield takeLatest(types.VERIFY_USER_KYC_START, verifyUserStartKYC);
}
