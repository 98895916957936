import React from 'react';

export const PreloaderSmall = () => (
  <div style={{ height: '100%' }}>
    <div className="table-loader">
      <div className="loader" />
    </div>
  </div>
);

const Loader = () => {
  return (
    <div className="page-loader">
      <div className="loader" />
    </div>
  );
};

export default Loader;
