import types from '../types';

const initialState = {
  data: null,
  loader: false,
};

const vote = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_VOTING_LIST_START:
      return { ...state, loader: true };
    case types.GET_VOTING_LIST_SUCCESS:
      return { ...state, data: payload, loader: false };
    case types.GET_VOTING_LIST_FAILURE:
      return { ...state, loader: false };

    default:
      return state;
  }
};

export default vote;
