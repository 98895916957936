import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import { closeModal } from '../../components/Base/Modal';
import notification from '../../services/notification';

function* getAvailableCashback() {
  try {
    const { data, status } = yield call(api.cashback.getAvailableCashback);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_AVAILABLE_CASHBACK_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_AVAILABLE_CASHBACK_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetAvailableCashback() {
  yield takeLatest(types.GET_AVAILABLE_CASHBACK_START, getAvailableCashback);
}

function* updateUserCashbackRenewal() {
  try {
    const { data, status } = yield call(api.cashback.updateUserCashbackRenewal);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.UPDATTE_USER_CASHBACK_RENEWAL_SUCCESS });
    yield put({ type: types.GET_USER_CASHBACK_START });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.UPDATTE_USER_CASHBACK_RENEWAL_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherUpdateUserCashbackRenewal() {
  yield takeLatest(
    types.UPDATTE_USER_CASHBACK_RENEWAL_START,
    updateUserCashbackRenewal,
  );
}

function* postBuyCashback({ payload }) {
  try {
    const { data, status } = yield call(api.cashback.postBuyCashback, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_BUY_CASHBACK_SUCCESS });
    yield put({
      type: types.GET_USER_CASHBACK_START,
    });
    yield put({
      type: types.GET_USER_TRADE_VOLUME_STATISTICS_START,
    });
    yield put({
      type: types.GET_CURRENT_CASHBACK_RATE_START,
    });
    yield put({
      type: types.GET_WALLETS_START,
    });
    yield put({
      type: types.GET_TRADING_VOLUME_LEVELS_START,
    });
    yield put({
      type: types.GET_USER_DATA_START,
    });
    closeModal();
    console.log('buyCashback', data);
    notification({
      type: 'success',
      title: 'Cashback',
      message: 'Cashback was succesfully purchased',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.POST_BUY_CASHBACK_FAILURE });
    if (error?.response?.status === 401) {
      return console.log('Something went wrong!!!');
    }

    if (
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors?.includes('you_have_a_better_rate_now')
    ) {
      notification({
        type: 'error',
        title: 'Cashback',
        message: 'You have a better rate now',
      });
    }

    if (
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors?.includes('not_enough_balance')
    ) {
      notification({
        type: 'error',
        title: 'Cashback',
        message: "You don't have enough balance",
      });
    }
  }
}

export function* watcherPostBuyCashback() {
  yield takeLatest(types.POST_BUY_CASHBACK_START, postBuyCashback);
}

function* getUserCashback() {
  try {
    const { data, status } = yield call(api.cashback.getUserCashback);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_USER_CASHBACK_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_USER_CASHBACK_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetUserCashback() {
  yield takeLatest(types.GET_USER_CASHBACK_START, getUserCashback);
}
