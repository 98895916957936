import { put, takeLatest, call, all } from 'redux-saga/effects';
import types from '../../types';
import api from '../../../services/api';
import notification from '../../../services/notification';

function* getOpenedOrders() {
  try {
    const { data, status } = yield call(api.trading.futures.getOpenedOrders);
    yield put({ type: types.FUTURES_GET_OPENED_ORDERS_SUCCESS, payload: data });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_OPENED_ORDERS_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('getOpenedOrders  - Something went wrong');
  }
}

// export function* watcherGetOpenedOrders() {
//   yield takeLatest(types.FUTURES_GET_OPENED_ORDERS_START, getOpenedOrders);
// }

function* getOpenedPositions() {
  try {
    const { data, status } = yield call(api.trading.futures.getOpenedPositions);
    yield put({
      type: types.FUTURES_GET_OPENED_POSITIONS_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_OPENED_POSITIONS_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('getOpenedPositions futures  - Something went wrong');
  }
}

// export function* watcherGetOpenedPositions() {
//   yield takeLatest(
//     types.FUTURES_GET_OPENED_POSITIONS_START,
//     getOpenedPositions,
//   );
// }

function* getOrdersHistory() {
  try {
    const { data, status } = yield call(api.trading.futures.getOrdersHistory);
    yield put({
      type: types.FUTURES_GET_ORDERS_HISTORY_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_ORDERS_HISTORY_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('getOrdersHistory  - Something went wrong');
  }
}

// export function* watcherGetOrdersHistory() {
//   yield takeLatest(types.FUTURES_GET_ORDERS_HISTORY_START, getOrdersHistory);
// }

function* getFills() {
  try {
    const { data, status } = yield call(api.trading.futures.getFills);
    yield put({ type: types.FUTURES_GET_FILLS_SUCCESS, payload: data });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_FILLS_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('getFills  - Something went wrong');
  }
}

// export function* watcherGetFills() {
//   yield takeLatest(types.FUTURES_GET_FILLS_START, getFills);
// }

function* getRecentTrades({ payload }) {
  try {
    const { data, status } = yield call(
      api.trading.futures.getRecentTrades,
      payload,
    );
    yield put({ type: types.FUTURES_GET_RECENT_TRADES_SUCCESS, payload: data });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_RECENT_TRADES_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('getRecentTrades  - Something went wrong');
  }
}

// export function* watcherGetRecentTrades() {
//   yield takeLatest(types.FUTURES_GET_RECENT_TRADES_START, getRecentTrades);
// }

function* getOrderBook({ contract }) {
  try {
    const { data, status } = yield call(
      api.trading.futures.getOrderBook,
      contract,
    );
    yield put({ type: types.FUTURES_GET_ORDER_BOOK_SUCCESS, payload: data });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_ORDER_BOOK_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('getOrderBook  - Something went wrong');
  }
}

// export function* watcherGetOrderBook() {
//   yield takeLatest(types.FUTURES_GET_ORDER_BOOK_START, getOrderBook);
// }

function* getContractsList() {
  try {
    const { data, status } = yield call(api.trading.futures.getContractsList);
    yield put({
      type: types.FUTURES_GET_CONTRACTS_LIST_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_CONTRACTS_LIST_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('getContractsList  - Something went wrong');
  }
}

// export function* watcherContractsList() {
//   yield takeLatest(types.FUTURES_GET_CONTRACTS_LIST_START, getContractsList);
// }

function* cancelOrder({ id }) {
  try {
    const { data, status } = yield call(api.trading.futures.cancelOrder, id);
    yield put({
      type: types.FUTURES_CANCEL_ORDER_SUCCESS,
      payload: id,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_CANCEL_ORDER_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('cancelOrder  - Something went wrong');
  }
}

// export function* watcherCancelOrder() {
//   yield takeLatest(types.FUTURES_CANCEL_ORDER_START, cancelOrder);
// }

function* cancelAllOrders({ body }) {
  // body - query string
  try {
    const { data, status } = yield call(
      api.trading.futures.cancelAllOrders,
      body,
    );
    yield put({
      type: types.FUTURES_CANCEL_ALL_ORDERS_SUCCESS,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_CANCEL_ALL_ORDERS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('cancelAllOrders  - Something went wrong');
  }
}

// export function* watcherCancelAllOrders() {
//   yield takeLatest(types.FUTURES_CANCEL_ALL_ORDERS_START, cancelAllOrders);
// }

function* createOrder({ payload }) {
  // payload == body for request
  try {
    const { data, status } = yield call(
      api.trading.futures.createOrder,
      payload,
    );
    yield put({
      type: types.FUTURES_CREATE_ORDER_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_CREATE_ORDER_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('createOrder  - Something went wrong');
  }
}
// export function* watcherCreateOrder() {
//   yield takeLatest(types.FUTURES_CREATE_ORDER_START, createOrder);
// }

function* adjustLeverage({ payload }) {
  try {
    const { data, status } = yield call(
      api.trading.futures.adjustLeverage,
      payload,
    );
    yield put({
      type: types.FUTURES_ADJUST_LEVERAGE_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_ADJUST_LEVERAGE_FUTURES });
    if (error?.response?.status === 401) return;
    console.error('adjustLevel  - Something went wrong');
  }
}

function* adjustMarginMode({ payload }) {
  try {
    const { data, status } = yield call(
      api.trading.futures.adjustMarginMode,
      payload,
    );
    yield put({
      type: types.FUTURES_ADJUST_MARGIN_MODE_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_ADJUST_MARGIN_MODE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('adjustMarginMode  - Something went wrong');
  }
}

function* getFavoriteContracts() {
  try {
    const { data, status } = yield call(
      api.trading.futures.getFavoriteContracts,
    );
    yield put({
      type: types.FUTURES_GET_FAVORITE_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_GET_FAVORITE_FAILURE });
    console.dir(error);
    if (error?.response?.status === 401) return;
    console.error('getFavoriteContracts  - Something went wrong');
  }
}

function* toggleFavorite({ payload }) {
  try {
    const { data, status } = yield call(
      api.trading.futures.toggleFavorite,
      payload,
    );
    yield put({
      type: types.FUTURES_TOGGLE_FAVORITE_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.FUTURES_TOGGLE_FAVORITE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error('toggleFavorite  - Something went wrong');
  }
}

export function* rootSagaFutures() {
  yield all([
    // FUTURES TABLE
    takeLatest(types.FUTURES_GET_OPENED_ORDERS_START, getOpenedOrders),
    takeLatest(types.FUTURES_GET_OPENED_POSITIONS_START, getOpenedPositions),
    takeLatest(types.FUTURES_GET_ORDERS_HISTORY_START, getOrdersHistory),
    takeLatest(types.FUTURES_GET_FILLS_START, getFills),

    // FUTURES ORDER BOOK, RECENT TRADES, CONTRACTS LIST
    takeLatest(types.FUTURES_GET_RECENT_TRADES_START, getRecentTrades),
    takeLatest(types.FUTURES_GET_ORDER_BOOK_START, getOrderBook),
    takeLatest(types.FUTURES_GET_CONTRACTS_LIST_START, getContractsList),

    // FUTURES ACTIONS
    takeLatest(types.FUTURES_CANCEL_ORDER_START, cancelOrder),
    takeLatest(types.FUTURES_CANCEL_ALL_ORDERS_START, cancelAllOrders),
    takeLatest(types.FUTURES_CREATE_ORDER_START, createOrder),
    takeLatest(types.FUTURES_ADJUST_LEVERAGE_START, adjustLeverage), // may be need reducers later
    takeLatest(types.FUTURES_ADJUST_MARGIN_MODE_START, adjustMarginMode), // may be need reducers later
    takeLatest(types.FUTURES_GET_FAVORITE_START, getFavoriteContracts),
    takeLatest(types.FUTURES_TOGGLE_FAVORITE_START, toggleFavorite),
  ]);
}
