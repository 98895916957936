// redux/project/reducer.js
import types from '../types';

  
  const initialState = {
    loading: false,
    data: null,
    error: null,
  };
  
  const reducer = (state = initialState, type) => {
    switch (type) {
      case types.SUBMIT_FORM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  