import { put, takeLatest, call, all } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

function* getInvitation({ body }) {
  try {
    const { data, status } = yield call(api.referralSystem.getInvitation, body);
    yield put({
      type: types.REFERRAL_GET_INVITATIONS_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.REFERRAL_GET_INVITATIONS_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

function* getReferralBonuses() {
  try {
    const { data, status } = yield call(api.referralSystem.getReferralBonuses);
    yield put({
      type: types.REFERRAL_GET_BONUSES_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.REFERRAL_GET_BONUSES_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

function* getUserReferralBonuses() {
  try {
    const { data, status } = yield call(
      api.referralSystem.getUserReferralBonuses,
    );
    yield put({
      type: types.GET_USER_REFERRAL_BONUSES_SUCCESS,
      payload: data,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.GET_USER_REFERRAL_BONUSES_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

function* postSendInvitation({ body, clearEmailField }) {
  try {
    const { data, status } = yield call(
      api.referralSystem.postSendInvitation,
      body,
    );
    yield put({
      type: types.REFERRAL_SEND_INVITATION_SUCCESS,
      payload: data,
    });
    notification({
      type: 'success',
      title: 'Referrals',
      message: 'Success. Invitation successfully sent',
    });
    yield call(clearEmailField);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
  } catch (error) {
    yield put({ type: types.REFERRAL_SEND_INVITATION_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    if (
      error?.response?.data?.errors?.email &&
      error?.response?.data?.errors?.email?.includes(
        'the_email_is_already_registered',
      )
    ) {
      notification({
        type: 'error',
        title: 'Referrals',
        message: 'The email is already registered.',
      });
    }
    if (
      !error?.response?.data?.errors?.email &&
      error?.response?.data?.errors?.includes('already_sent_invite')
    ) {
      notification({
        type: 'error',
        title: 'Referrals',
        message: 'For this email already sent invite.',
      });
    }
  }
}

export function* rootSagaReferralSystem() {
  yield all([
    takeLatest(types.REFERRAL_GET_INVITATIONS_START, getInvitation),
    takeLatest(types.REFERRAL_GET_BONUSES_START, getReferralBonuses),
    takeLatest(types.REFERRAL_SEND_INVITATION_START, postSendInvitation),
    takeLatest(types.GET_USER_REFERRAL_BONUSES_START, getUserReferralBonuses),
  ]);
}
