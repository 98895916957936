import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { closeModal } from '../../../components/Base/Modal';
import api from '../../../services/api';
import {
  iOS,
  sendPostFormForPerfectMoney,
  windowReference,
} from '../../../services/helpers';
import notification from '../../../services/notification';
import { store } from '../../store';
import types from '../../types';

function* getPayeerSystem() {
  try {
    const { data, status } = yield call(api.wallets.fiat.getPayeerSystem);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_PAYEER_SYSTEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: types.GET_PAYEER_SYSTEM_FAILURE,
    });
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}

function* postPerfectMoneyDeposit({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPerfectMoneyDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PERFECTMONEY_DEPOSIT_SUCCESS });

    if (data?.url?.base) {
      if (iOS) {
        setIosLink({ url: data?.url?.base, param: data?.param });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
      } else {
        sendPostFormForPerfectMoney(data?.url?.base, data?.param);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open Perfect Money site',
        });
        closeModal();
      }
    } else {
      closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open Payeer site wrong',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PERFECTMONEY_DEPOSIT_SUCCESS,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postPerfectMoneyWidthdraw({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPerfectMoneyWithdraw,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PERFECTMONEY_WITHDRAW_SUCCESS });

    if (data) {
      closeModal();
      notification({
        type: 'success',
        title: 'Widthdraw',
        message: 'Withdrawal request was created',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PERFECTMONEY_WITHDRAW_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes(
        'you_have_reached_your_daily_withdrawal_limit',
      )
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You have reached your daily withdrawal limit',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes('low_balance')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Low balance',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_withdraw')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Too small amount for a withdraw',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}

function* postSettlePayWidthdraw({ body, clearFields }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postSettlePayWithdraw,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_SETTLEPAY_WITHDRAW_SUCCESS });

    if (data) {
      closeModal();
      notification({
        type: 'success',
        title: 'Widthdraw',
        message: 'Withdrawal request was created',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_SETTLEPAY_WITHDRAW_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes(
        'you_have_reached_your_daily_withdrawal_limit',
      )
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You have reached your daily withdrawal limit',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes('low_balance')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Low balance',
      });
      return;
    }

    if (
         error?.response?.data?.errors?.includes('first_you_must_verify_your_identity')
    ) {
      closeModal();
      clearFields();
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'First you must verify your identity.',
      });
      return;
    }


    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_withdraw')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Too small amount for a withdraw',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}

function* postPayeerDeposit({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPayeerDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PAYEER_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open Payeer site',
        });
        closeModal();
      }
    } else {
      closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open Payeer site wrong',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PAYEER_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postPayeerWidthdraw({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPayeerWidthdraw,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PERFECTMONEY_WITHDRAW_SUCCESS });
    if (data) {
      closeModal();
      notification({
        type: 'success',
        title: 'Widthdraw',
        message: 'Withdrawal request was created',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PERFECTMONEY_WITHDRAW_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes(
        'you_have_reached_your_daily_withdrawal_limit',
      )
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You have reached your daily withdrawal limit',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes('low_balance')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Low balance',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_withdraw')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Too small amount for a withdraw',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}
function* getPrMoneySystem() {
  try {
    const { data, status } = yield call(api.wallets.fiat.getPrMoneySystem);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_PRMONEY_SYSTEM_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: types.GET_PRMONEY_SYSTEM_FAILURE,
    });
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}
function* postPrMoneyDeposit({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPrMoneyDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PRMONEY_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open PrMoney site',
        });
        closeModal();
      }
    } else {
      closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open PrMoney site wrong',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PRMONEY_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

// function* postPrMoneyWidthdraw({ body }) {
//   try {
//     const { data, status } = yield call(
//       api.wallets.fiat.postPrMoneyWidthdraw,
//       body,
//     );
//     if (status < 200 || status >= 300) throw new Error('Something went wrong');
//     yield put({ type: types.POST_PRMONEY_WIDTHDRAW_SUCCESS });
// if (data?.url?.ready) {
//   if (iOS) {
//     setIosLink(data.url.ready);
//     setIosLink({ url: data.url.ready, param: {} });
//     notification({
//       type: 'info',
//       title: 'Widthdraw',
//       message: 'Generate link success',
//     });
//   } else {
//     windowReference(data.url.ready);
//     notification({
//       type: 'info',
//       title: 'Widthdraw',
//       message: 'Open PrMoney site',
//     });
//     closeModal();
//   }
// } else {
//   closeModal();
//   notification({
//     type: 'error',
//     title: 'Widthdraw',
//     message: 'Open PrMoney site wrong',
//   });
// }
//   } catch (error) {
//     yield put({
//       type: types.POST_PRMONEY_WIDTHDRAW_FAILURE,
//     });
//     if (error.response.status === 403) {
//       notification({
//         type: 'error',
//         title: 'Widthdraw',
//         message: 'You need to verify your account',
//       });
//       return;
//     }
//     notification({
//       type: 'error',
//       title: 'Widthdraw',
//       message: 'Something went wrong',
//     });
//   }
// }

function* postPalmiraDeposit({ body, setIosLink }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPalmiraDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PALMIRA_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open palmira site',
        });
        closeModal();
      }
    } else {
      closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open palmira site wrong',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PALMIRA_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postPaypalDeposit({ body, setIosLink, setAmount }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPaypalDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PAYPAL_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
        setAmount('');
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open Paypal site',
        });
        setAmount('');
        // closeModal();
      }
    } else {
      // closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open Paypal site wrong',
      });
    }
  } catch (error) {
    yield put({
      type: types.POST_PAYPAL_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postPaypalDepositApprove({ body }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPaypalDepositApprove,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PAYPAL_DEPOSIT_APPROVE_SUCCESS });

    notification({
      type: 'success',
      title: 'Deposit',
      message: 'Deposit succesfully',
    });
  } catch (error) {
    yield put({
      type: types.POST_PAYPAL_DEPOSIT_APPROVE_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postPaypalWidthdraw({ body, clearFields }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postPaypalWidthdraw,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_PAYPAL_WITHDRAW_SUCCESS });

    if (data) {
      notification({
        type: 'success',
        title: 'Widthdraw',
        message: 'Withdrawal request was created',
      });
      clearFields();
    }
  } catch (error) {
    yield put({
      type: types.POST_PAYPAL_WITHDRAW_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes(
        'you_have_reached_your_daily_withdrawal_limit',
      )
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'You have reached your daily withdrawal limit',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error.response.data.errors?.includes('low_balance')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Low balance',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_withdraw')
    ) {
      notification({
        type: 'error',
        title: 'Widthdraw',
        message: 'Too small amount for a withdraw',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}

function* getPaypalRecipientTypes() {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.getPaypalRecipientTypes,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_FAILURE,
    });
    notification({
      type: 'error',
      title: 'Widthdraw',
      message: 'Something went wrong',
    });
  }
}

function* postTrustDeposit({ body, setIosLink, setAmount }) {
  try {
    const { data, status } = yield call(
      api.wallets.fiat.postTrustDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_TRUST_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
        setAmount('');
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open Trust site',
        });
        setAmount('');
        // closeModal();
      }
    } else {
      // closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open Trust site wrong',
      });
    }
  } catch (error) {
    console.dir(error);
    yield put({
      type: types.POST_TRUST_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

function* postSettlePayDeposit({ body, setIosLink, setAmount }) {
  try {
    const { data, status } = yield call(
      //TODO: add api
      api.wallets.fiat.postSettlePayDeposit,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_SETTLEPAY_DEPOSIT_SUCCESS });
    if (data?.url?.ready) {
      if (iOS) {
        // setIosLink(data.url.ready);
        setIosLink({ url: data.url.ready, param: {} });
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Generate link success',
        });
        setAmount('');
      } else {
        windowReference(data.url.ready);
        notification({
          type: 'info',
          title: 'Deposit',
          message: 'Open Settlepay site',
        });
        setAmount('');
        // closeModal();
      }
    } else {
      // closeModal();
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Open Settlepay site wrong',
      });
    }
  } catch (error) {
    console.dir(error);
    yield put({
      type: types.POST_SETTLEPAY_DEPOSIT_FAILURE,
    });
    if (error?.response?.status === 403) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'You need to verify your account',
      });
      return;
    }
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors?.includes('too_small_amount_for_a_deposit')
    ) {
      notification({
        type: 'error',
        title: 'Deposit',
        message: 'Too small amount for a deposit',
      });
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Something went wrong',
    });
  }
}

export function* rootSagaWalletsFiat() {
  yield all([
    takeLatest(types.GET_PAYEER_SYSTEM_START, getPayeerSystem),
    takeLatest(types.POST_PAYEER_DEPOSIT_START, postPayeerDeposit),
    takeLatest(types.POST_PAYEER_WIDTHDRAW_START, postPayeerWidthdraw),
    takeLatest(types.GET_PRMONEY_SYSTEM_START, getPrMoneySystem),
    takeLatest(types.POST_PRMONEY_DEPOSIT_START, postPrMoneyDeposit),
    // takeLatest(types.POST_PRMONEY_WIDTHDRAW_START, postPrMoneyWidthdraw),
    takeLatest(types.POST_PALMIRA_DEPOSIT_START, postPalmiraDeposit),
    takeLatest(types.POST_PERFECTMONEY_DEPOSIT_START, postPerfectMoneyDeposit),
    takeLatest(
      types.POST_PERFECTMONEY_WITHDRAW_START,
      postPerfectMoneyWidthdraw,
    ),
    takeLatest(types.POST_SETTLEPAY_WITHDRAW_START, postSettlePayWidthdraw),
    takeLatest(types.POST_PAYPAL_DEPOSIT_START, postPaypalDeposit),
    takeLatest(
      types.POST_PAYPAL_DEPOSIT_APPROVE_START,
      postPaypalDepositApprove,
    ),
    takeLatest(types.POST_PAYPAL_WITHDRAW_START, postPaypalWidthdraw),
    takeLatest(
      types.GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_START,
      getPaypalRecipientTypes,
    ),
    takeLatest(types.POST_TRUST_DEPOSIT_START, postTrustDeposit),
    takeLatest(types.POST_SETTLEPAY_DEPOSIT_START, postSettlePayDeposit),
  ]);
}
