import React, {
  useEffect,
  useState,
  useContext
} from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import types from '../../../../redux/types';
import { ThemeContext } from '../../../../ThemeContext';
import { useLocation, NavLink, Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { useMediaQuery } from 'react-responsive';

const Download = () => {
  const [theme, setTheme] = useState('');

  const { theme: contextTheme } = useContext(ThemeContext);
  useEffect(() => {
    console.log('Apppp')
    setTheme(contextTheme);
  }, [contextTheme]);
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  const handleMouseOut = (e) => {
    const list = e.currentTarget;  
    list.classList.remove('active');
    const qrList = list.querySelector('.qr-list');
    if (qrList && !list.classList.contains('active')) {
      qrList.classList.remove('show');
      qrList.classList.add('hidden');
    }
  };
  
  const handleBlur = (e) => {
    const list = e.currentTarget;
    list.classList.remove('active');
    const qrList = list.querySelector('.qr-list');
    if (qrList && !list.classList.contains('active')) {
      qrList.classList.add('hidden');
    }
  };
  
  const diactiveteList = classList => {
    setTimeout(() => {
      classList.remove('active');
    }, 300);
  };

  return (

    <ul
      className="lang-drop"
      onMouseOver={(e) => {
        e.currentTarget.classList.add('active');
        e.currentTarget.querySelector('.qr-list').classList.remove('hidden');
        e.currentTarget.querySelector('.qr-list').classList.add('show');
      }}
      onFocus={(e) => {
        e.currentTarget.classList.add('active');
        e.currentTarget.querySelector('.qr-list').classList.remove('hidden');
        e.currentTarget.querySelector('.qr-list').classList.add('show');

      }}
      onMouseOut={handleMouseOut}
      onBlur={handleBlur}
    >

      <li className='change'>
        <div className='language'  style={isMobile ? {marginBottom: '7px'} : {marginBottom: '7px'}}>
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
          </svg>
        </div>
        <div
          className="qr-list hidden"
          onMouseOut={handleMouseOut}
          onBlur={handleBlur}
        >
          <div
            className="lang-list__item"
            onMouseOut={handleMouseOut}
            onBlur={handleBlur}
          >
            <div className={`${theme === 'dark' ? 'app-qr__item' : 'app-qr__item_light'}`} style={{ width: '100%' }}>
              <QRCode value="coinstable.io" />
              <p className={`${theme === 'dark' ? 'ieo-card__text' : 'light-ieo-card__text'}`}>Scan to Download App IOS & Android</p>
              <Link className="b_more_download" to="/#">More Download Options</Link>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default Download;
