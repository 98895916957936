import React, {
  useEffect,
  useState,
  useContext
} from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import L from 'i18n-react';
import routes from '../../../routes';
import { thisDemo, toHome, toSubDomen } from '../../../services/helpers';
import PaymentsBlock from './PaymentsBlock';
import DownloadButtonsBlock from './DownloadButtonsBlock';
import { ThemeContext } from '../../../ThemeContext';
const Footers = props => {
  const location = useLocation();
  const history = useHistory();
  const notRenderFooter = location.pathname.includes(routes.Trade.path);
  const main = location.pathname === routes.Root.path;
  const { language } = useSelector(store => store.language);
  const [theme, setTheme] = useState('');
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const isApiDocsRoute = window.location.pathname ;

  const { theme: contextTheme } = useContext(ThemeContext);

  useEffect(() => {
    console.log('Apppp', notRenderFooter)
    setTheme(contextTheme);
  }, [contextTheme]);

  const clickOnLogo = () => {
    if (!main) {
      history.push(routes.Root.path);
    }
    if (props.refAutoScroll?.current && window.pageYOffset > 0) {
      props.refAutoScroll.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // console.log(toSubDomen('news'));
  return (
    <>
      {notRenderFooter ? null : (
        <footer  className={isApiDocsRoute === '/api_docs' ? 'footer-hidden' : `footer ${theme}`}>
          <div className="container">
            <div className="footer-content">
              {/* <a
            // href={thisDemo() ? toHome('demo') : toHome('')}
            href={toHome('')}
            className="page-logo"
          >
            <img
              src='/img/logo.png'
              alt=""
            />
          </a> */}
              <div className="footer-column">
                <a
                  // href={thisDemo() ? toHome('demo') : toHome('')}
                  href={toHome('')}
                  className="page-logo-footer"
                >
                  <img
                    className='footer-logo'
                    src='/img/coinstable-logo.svg'
                    alt=""
                  />
                </a>
                <p className={`f-nav-block__title footer-paiblock ${theme}`}>
                  by Paiblock
                  </p>
                <p className='font-13'>© Coinstable {currentYear}</p>
              </div>
              <div className="footer-column">
                <div className="f-nav-block">
                  <p className={`f-nav-block__title ${theme}`}>
                    {L.translate('Footer.Info.title')}
                  </p>
                  <ul className="f-nav">
                    <li>
                      <Link to={routes.ListingRequest.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Info.listing_application')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.Cashback.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Info.vip')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.Vote.path}  className={`${theme === 'dark' ? 'dark' : ''}`}>
                        Launchpad
                      </Link>
                    </li>
                    {/* <li>
                    <Link to={routes.News.path}>
                      {L.translate('Footer.Info.news')}
                    </Link> */}
                    {/* <a
                      href="https://news.betconix.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {L.translate('Footer.news')}
                    </a> */}
                    {/* </li> */}
                  </ul>
                </div>
              </div>
              <div className="footer-column">
                <div className="f-nav-block">
                  <p className={`f-nav-block__title ${theme}`}>
                    {L.translate('Footer.Legal.title')}
                  </p>
                  <ul className="f-nav">
                    {/* <li>
                      <a
                        href={
                          language === 'RU'
                            ? 'https://stablecoin.markets/storage/docs/bnix_white_ru.pdf'
                            : 'https://stablecoin.markets/storage/docs/bnix_white_en.pdf'
                        }
                        target="blank"
                      >
                        {L.translate('Footer.Documents.whitepaper')}
                      </a>
                    </li> */}
                    <li>
                      <Link to={routes.PrivacyPolicy.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Legal.privacy_policy')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.AMLPolicy.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Legal.AML_policy')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.TermsOfUse.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Legal.terms_of_use')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.RiskNotifications.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Legal.risk_notifications')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.Safu.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Legal.safu')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-column">
                <div className="f-nav-block">
                  <p className={`f-nav-block__title ${theme}`}>
                    {L.translate('Footer.Company.title')}
                  </p>
                  <ul className="f-nav">
                    <li>
                      <Link to={routes.ContactUs.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Info.contact_support')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.FeesAndLimits.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Info.fees_and_limits')}
                      </Link>
                    </li>
                    <li>
                    <Link to={routes.ApisDocs.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        APIs
                    </Link>
                    </li>
                    <li>
                      <Link  to={routes.CryptocurrencyAssetsAndStatus.path} className={`${theme === 'dark' ? 'dark' : ''}`} >
                        {L.translate('Footer.Company.network')}
                      </Link>
                    </li>
                    <li>
                      <Link  to={routes.AccountVerify.path} className={`${theme === 'dark' ? 'dark' : ''}`} >
                        {L.translate('Footer.AccountVerification.title')}
                      </Link>
                    </li>
                    {/* <li>
                      <a href="https://paiblock.app/blog/posts/categories">
                        {L.translate('Footer.Company.blog')}
                      </a>
                    </li>
                    <li>
                      <Link to={routes.Vacancy.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        {L.translate('Footer.Company.vacancies')}
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.CryptocurrencyAssetsAndStatus.path} className={`${theme === 'dark' ? 'dark' : ''}`}>
                        Status
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://paiblock.app/blog/posts?category_id=9&lang=en&current_page=1&per_page=15"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`${theme === 'dark' ? 'dark' : ''}`}
                      >
                        Information about Assets
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="footer-column">
                <div className="f-nav-block">
                  <p className={`f-nav-block__title ${theme}`}>
                    {L.translate('Footer.Information.title')}
                  </p>
                  <ul className="f-nav icon_wrap">
                    <li>
                      <div className="f-social">
                        <Link
                          to={{
                            pathname: 'https://www.facebook.com/paiblock',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`f-social__link ${theme === 'dark' ? 'light' : ''}`}
                        >
                          <svg
                            width="9"
                            height="14"
                            viewBox="0 0 9 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.86496 7.82224L8.27894 5.34544H5.69052V3.73817C5.69052 3.06057 6.0521 2.40008 7.21138 2.40008H8.38812V0.291353C8.38812 0.291353 7.32026 0.124023 6.29927 0.124023C4.16762 0.124023 2.77428 1.3103 2.77428 3.45778V5.34544H0.404785V7.82224H2.77428V13.8097H5.69052V7.82224H7.86496Z"
                              fill="#1D1B1B"
                            />
                          </svg>
                        </Link>
                        <Link
                          to={{
                            pathname: 'https://www.instagram.com/paiblockapp/',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`f-social__link ${theme === 'dark' ? 'light' : ''}`}
                        >
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.3064 4.87308C6.04097 4.87308 4.21366 6.70079 4.21366 8.96673C4.21366 11.2327 6.04097 13.0604 8.3064 13.0604C10.5718 13.0604 12.3991 11.2327 12.3991 8.96673C12.3991 6.70079 10.5718 4.87308 8.3064 4.87308ZM8.3064 11.6281C6.84242 11.6281 5.64559 10.4346 5.64559 8.96673C5.64559 7.49886 6.83886 6.30532 8.3064 6.30532C9.77395 6.30532 10.9672 7.49886 10.9672 8.96673C10.9672 10.4346 9.77039 11.6281 8.3064 11.6281ZM13.5212 4.70563C13.5212 5.23649 13.0937 5.66046 12.5666 5.66046C12.0358 5.66046 11.6119 5.23292 11.6119 4.70563C11.6119 4.17833 12.0394 3.7508 12.5666 3.7508C13.0937 3.7508 13.5212 4.17833 13.5212 4.70563ZM16.2319 5.67471C16.1713 4.39567 15.8792 3.2627 14.9424 2.32924C14.0092 1.39579 12.8765 1.10364 11.5977 1.03951C10.2798 0.964694 6.32949 0.964694 5.01155 1.03951C3.73636 1.10008 2.60364 1.39223 1.66683 2.32568C0.730029 3.25913 0.441507 4.3921 0.377391 5.67115C0.302589 6.98938 0.302589 10.9405 0.377391 12.2588C0.437945 13.5378 0.730029 14.6708 1.66683 15.6042C2.60364 16.5377 3.7328 16.8298 5.01155 16.8939C6.32949 16.9688 10.2798 16.9688 11.5977 16.8939C12.8765 16.8334 14.0092 16.5412 14.9424 15.6042C15.8757 14.6708 16.1677 13.5378 16.2319 12.2588C16.3067 10.9405 16.3067 6.99294 16.2319 5.67471ZM14.5292 13.6732C14.2514 14.3715 13.7135 14.9095 13.0118 15.1909C11.961 15.6078 9.46762 15.5116 8.3064 15.5116C7.14519 15.5116 4.64823 15.6042 3.601 15.1909C2.90285 14.913 2.36499 14.3751 2.08359 13.6732C1.66683 12.6222 1.76301 10.1282 1.76301 8.96673C1.76301 7.80526 1.6704 5.30774 2.08359 4.26028C2.36142 3.56197 2.89929 3.02399 3.601 2.74253C4.65179 2.32568 7.14519 2.42188 8.3064 2.42188C9.46762 2.42188 11.9646 2.32924 13.0118 2.74253C13.71 3.02043 14.2478 3.55841 14.5292 4.26028C14.946 5.3113 14.8498 7.80526 14.8498 8.96673C14.8498 10.1282 14.946 12.6257 14.5292 13.6732Z"
                              fill="#1D1B1B"
                            />
                          </svg>
                        </Link>
                        <Link
                          to={{
                            pathname:
                              'https://www.youtube.com/channel/UCEd4VIc8MOL4VdZy8U70hFQ',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`f-social__link ${theme === 'dark' ? 'light' : ''}`}
                        >
                          <svg
                            width="17"
                            height="12"
                            viewBox="0 0 17 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.2922 2.04813C16.1086 1.34573 15.5675 0.792538 14.8806 0.604805C13.6354 0.263672 8.64251 0.263672 8.64251 0.263672C8.64251 0.263672 3.64962 0.263672 2.40444 0.604805C1.71749 0.792568 1.17645 1.34573 0.992819 2.04813C0.65918 3.32128 0.65918 5.97758 0.65918 5.97758C0.65918 5.97758 0.65918 8.63388 0.992819 9.90702C1.17645 10.6094 1.71749 11.1396 2.40444 11.3273C3.64962 11.6684 8.64251 11.6684 8.64251 11.6684C8.64251 11.6684 13.6354 11.6684 14.8806 11.3273C15.5675 11.1396 16.1086 10.6094 16.2922 9.90702C16.6258 8.63388 16.6258 5.97758 16.6258 5.97758C16.6258 5.97758 16.6258 3.32128 16.2922 2.04813ZM7.00955 8.3893V3.56586L11.1826 5.97764L7.00955 8.3893Z"
                              fill="#1D1B1B"
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="f-social">
                        <Link
                          to={{
                            pathname: 'https://t.me/paiblock',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`f-social__link ${theme === 'dark' ? 'light' : ''}`}
                        >
                          <svg
                            width="18"
                            height="13"
                            viewBox="0 0 18 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2532 1.40575L14.6716 12.0516C14.4769 12.803 13.969 12.99 13.2472 12.636L9.31373 10.1014L7.41574 11.6977C7.2057 11.8813 7.03003 12.0349 6.62523 12.0349L6.90783 8.53194L14.1981 2.77155C14.5151 2.52444 14.1294 2.38752 13.7055 2.63464L4.69286 7.59692L0.812862 6.53501C-0.0311145 6.30459 -0.0463901 5.79701 0.988532 5.44304L16.1648 0.330479C16.8675 0.100063 17.4824 0.467393 17.2532 1.40575Z"
                              fill="#1D1B1B"
                            />
                          </svg>
                        </Link>
                        <Link
                          to={{
                            pathname: 'https://twitter.com/paiblock',
                          }}
                          className={`f-social__link ${theme === 'dark' ? 'light' : ''}`}
                          target="_blank"
                        >
                          <svg
                            width="18"
                            height="17"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#1D1B1B"
                              d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footers;
