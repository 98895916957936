import React, {
  useEffect,
  useState,
  useContext
} from 'react';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import SelectWrap from '../base/SelectWrap';
import routes from '../../../../routes';
import { ThemeContext } from '../../../../ThemeContext';

const SelectTrade = ({ isLogin }) => {
  const currentPair = useSelector(state => state.currentPair);
  const [theme, setTheme] = useState('');

  const { theme: contextTheme } = useContext(ThemeContext);
  
  useEffect(() => {
    console.log('Apppp')
    setTheme(contextTheme);
  }, [contextTheme]);
  
  return (
    <SelectWrap
      title={L.translate('Header.SelectTrade.trade')}
      listenerID="Trade"
      className={`${theme}`}
    >
      <ul className="drop-list__item">
        {/* {isLogin && ( */}
        <li>
          <Link to={routes.Pairs.path} className={`drop-btn ${theme}`}>
            {L.translate('Header.SelectTrade.list_of_pairs')}
          </Link>
        </li>
        {/* )} */}
        <li>
          <Link
            to={`${
              routes.Trade.SpotTrade.path
            }/${currentPair?.pair?.toUpperCase()}`}
            className={`drop-btn ${theme}`}
          >
            {L.translate('Header.SelectTrade.spot')}
          </Link>
        </li>
        {/* <li>
          <Link to={routes.Trade.MarginTrade.path} className="drop-btn">
            {L.translate('Header.SelectTrade.margin')}
          </Link>
        </li>
        <li>
          <Link to={routes.Trade.FuturesTrade.path} className="drop-btn">
            {L.translate('Header.SelectTrade.futures')}
          </Link>
        </li> */}
        {/* <li>
          <Link to={routes.Trade.ForexTrade.path} className="drop-btn">
            {L.translate('Header.SelectTrade.derivatives')}{' '}
            Forex rename Derivatives
          </Link>
        </li> */}
      </ul>
    </SelectWrap>
  );
};

export default SelectTrade;
