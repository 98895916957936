import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

function* getLimits() {
  try {
    const { data, status } = yield call(api.limits.getLimits);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_LIMITS_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_LIMITS_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetLimits() {
  yield takeLatest(types.GET_LIMITS_START, getLimits);
}

function* getFeesPerInputAndOutput() {
  try {
    const { data, status } = yield call(api.limits.getFeesPerInputAndOutput);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_FEES_PER_INPUT_AND_OUTPUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.GET_FEES_PER_INPUT_AND_OUTPUT_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
  }
}

export function* watcherGetFeesPerInputAndOutput() {
  yield takeLatest(
    types.GET_FEES_PER_INPUT_AND_OUTPUT_START,
    getFeesPerInputAndOutput,
  );
}
