import types from '../types';

const status = (
  state = { loading: false, assetsStatus: null, incedents: null },
  { type, payload },
) => {
  switch (type) {
    case types.GET_ASSETS_STATUS_LIST_START:
    case types.GET_INCIDENTS_LIST_START:
      return { ...state, loading: true };

    case types.GET_ASSETS_STATUS_LIST_SUCCESS:
      return { ...state, assetsStatus: payload, loading: false };
    case types.GET_INCIDENTS_LIST_SUCCESS:
      return { ...state, incedents: payload, loading: false };

    case types.GET_ASSETS_STATUS_LIST_FAILURE:
    case types.GET_INCIDENTS_LIST_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default status;
