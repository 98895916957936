import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../../types';
import api from '../../../services/api';
import notification from '../../../services/notification';

export function* postReset2FA({ payload }) {
  try {
    const { data, status } = yield call(api.auth.reset2FA, payload);
    console.log(data);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.RESET_2FA_SUCCESS, payload: data });
    notification({
      title: 'Reset 2FA',
      type: 'success',
      message: 'Your reset has been sent',
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.RESET_2FA_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
    if (error?.response?.data?.errors[0] === 'already_exist') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'Request to reset TOTP was already sent',
      });
    }
    if (error?.response?.data?.errors[0] === '2fa_already_disabled') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'Your 2FA has already been reset',
      });
    }
    if (error?.response?.data?.errors[0] === 'model_not_found') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'An account with this email not found',
      });
    }
  }
}

export function* postUploadSelfie({ payload }) {
  try {
    const { data, status } = yield call(api.auth.uploadSelfi, payload);
    console.log(data);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.UPLOAD_SELFI_RESET_2FA_SUCCESS, payload: data });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.UPLOAD_SELFI_RESET_2FA_FAILURE });
    if (error?.response?.status === 401)
      return console.log('Something went wrong!!!');
    if (error?.response?.data?.errors[0] === 'already_exist') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'Request to reset TOTP was already sent',
      });
    }
    if (error?.response?.data?.errors[0] === '2fa_already_disabled') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'Your 2FA has already been reset',
      });
    }
    if (error?.response?.data?.errors[0] === 'model_not_found') {
      notification({
        title: 'Reset 2FA',
        type: 'error',
        message: 'An account with this email not found',
      });
    }
  }
}

export function* watcherReset2FA() {
  yield takeLatest(types.RESET_2FA_START, postReset2FA);
}

export function* watcherUploadSelfie() {
  yield takeLatest(types.UPLOAD_SELFI_RESET_2FA_START, postUploadSelfie);
}
