import React from 'react';
import { put, takeLatest, call, all, delay } from 'redux-saga/effects';
import L from 'i18n-react';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';
import routes from '../../routes';
import { thisDemo } from '../../services/helpers';
import { openModal } from '../../components/Base/Modal';
import ModalDemoLogin from '../../components/Base/Modals/ModalDemoLogin';

function* checkSmsCode({ payload }) {
  try {
    const { data, status } = yield call(
      api.auth.checkSmsCode,
      payload.loginData,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.CHECK_SMS_CODE_SUCCESS, payload: data });
    const currentTime = new Date().getTime();
    const delay = data?.delay_active ? 600 : 60;

    payload.setTime(currentTime + delay * 1000);
    if (data?.delay_active) {
      notification({
        type: 'info',
        title: L.translate('Auth.Login.info_sms_code_T'),
        message: L.translate('Auth.Login.info_sms_code_M'),
      });
    }
    // if (data?.totp_required) {
    //   payload.handleShowTotp(true);
    // }
    if (
      payload.loginData.email === 'trader3@mail.com' &&
      (window.location.hostname.includes('orderbook.fun') ||
        window.location.hostname.includes('localhost'))
    ) {
      notification({
        type: 'info',
        title: 'SMS code',
        message: data.code,
        time: 15000,
      });
    }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.CHECK_SMS_CODE_FAILURE });
    if (error?.response?.status === 401) return;
    const errors = error?.response?.data?.errors;

    const currentTime = new Date().getTime();

    if (
      errors?.length &&
      errors?.find(element => element.includes('too_many_attempts_time_left'))
    ) {
      const arrError = errors
        ?.find(element => element.includes('too_many_attempts_time_left'))
        .split('_');
      const seconds = +arrError[arrError.length - 1];
      payload.setTime(currentTime + seconds * 1000);
    } else {
      // notification({ type: 'error', message: 'Something went wrong' });
      payload.setTime(currentTime + 60 * 1000);
    }

    if (errors?.totp?.includes('the_totp_field_is_required')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'You should fill 2FA field',
      });
    }

    if (errors?.includes('invalid_credentials')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Error. The data is incorrect',
      });
    }

    if (errors?.includes('email_not_confirmed')) {
      payload.history.push(routes.Auth.VerifyYourEmail.path, {
        email: payload.loginData.email,
      });
      notification({
        type: 'error',
        title: 'Login',
        message: 'Error. You must confirm your e-mail',
      });
    }

    if (errors?.includes('model_not_found')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'This email is not registred',
      });
    }
  }
}

function* sendSmsCode({ payload }) {
  try {
    const { data, status } = yield call(api.auth.sendSmsCode, {
      phone: `+${payload.loginData.phone.replace(/\D/g, '')}`,
      // email: payload.loginData.email,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.SEND_SMS_CODE_SUCCESS, payload: data });
    if (
      window.location.hostname.includes('orderbook.fun') ||
      window.location.hostname.includes('localhost')
    ) {
      notification({
        type: 'info',
        title: 'SMS code',
        message: data?.code,
        time: 15000,
      });
    }
    // const currentTime = new Date().getTime();
    // const delay = data?.delay_active ? 600 : 60;
    // payload.setTime(currentTime + delay * 1000);
    // if (data?.delay_active) {
    //   notification({
    //     type: 'info',
    //     title: L.translate('Auth.Login.info_sms_code_T'),
    //     message: L.translate('Auth.Login.info_sms_code_M'),
    //   });
    // }
    // if (data?.totp_required) {
    //   payload.handleShowTotp(true);
    // }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.SEND_SMS_CODE_FAILURE });
    if (error?.response?.status === 401) return;
    const errors = error?.response?.data?.errors;

    const currentTime = new Date().getTime();
    if (
      errors?.length &&
      errors?.find(element => element.includes('too_many_attempts_time_left'))
    ) {
      const arrError = errors
        ?.find(element => element.includes('too_many_attempts_time_left'))
        .split('_');
      const seconds = +arrError[arrError.length - 1];
      payload.setTime(currentTime + seconds * 1000);
    } else {
      // notification({ type: 'error', message: 'Something went wrong' });
      payload.setTime(currentTime + 60 * 1000);
    }
    if (errors?.email?.includes('the_email_has_already_been_taken')) {
      payload.history.push(routes.Auth.Login.path, {
        email: payload.loginData.email,
      });
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'Error. Email has already registered.',
      });
    }
    if (errors?.phone?.includes('the_phone_has_already_been_taken')) {
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'Error. Phone number has already registered.',
      });
    }
  }
}
// sms_code_invalid
function* postLogin({ payload }) {
  try {
    const { data, status } = yield call(api.auth.login, payload.loginData);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    if (payload?.loginData?.phone && payload.loginData.phone.replace('+', '')) {
      payload.history.push(routes.Auth.SmsLoginChecked.path, {
        phone: payload.loginData.phone,
        password: payload.loginData.password,
        device_hash: payload.loginData.device_hash,
      });
      if (
        window.location.hostname.includes('orderbook.fun') ||
        window.location.hostname.includes('localhost')
      ) {
        notification({
          type: 'info',
          title: 'SMS code',
          message: data?.sms_code,
          time: 15000,
        });
      }
      return;
    }
    
    yield put({ type: types.LOGIN_SUCCESS, payload: data });
    yield delay(100); 
    window.location.href = routes.Root.path;
 
    yield put({
      type: types.GET_KYC_VERIFICATIONS_START,
    });
    yield put({
      type: types.GET_TRADER_INFO_START,
    });
    yield put({
      type: types.GET_USER_CASHBACK_START,
    });
  } catch (error) {
    yield put({ type: types.LOGIN_FAILURE });
    if (error?.response?.status === 401) return;
    const errors = error?.response?.data?.errors;
    if (errors?.length && errors?.includes('phone_not_confirmed')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Please confirm your mobile phone.',
      });
      yield put({
        type: types.SEND_SMS_CODE_START,
        payload,
      });
      payload.history.push(routes.Auth.ConfirmMobilePhone.path, {
        phone: payload?.loginData?.phone,
        password: payload.password,
      });
    }
    if (errors?.length && errors?.[0]?.includes('need_device_approve')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Please confirm your new device.',
      });
      payload.history.push(routes.Auth.ApproveDevice.path, {
        data: payload.loginData,
      });
    }
    if (errors?.totp?.includes('the_totp_field_is_required')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'You should fill 2FA field',
      });
    }
    if (errors?.sms_code?.includes('the_sms_code_field_is_required')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'You should fill sms code field',
      });
    }
    if (errors?.length && errors?.includes('sms_code_invalid')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'SMS code invalid',
      });
    }
    if (errors?.length && errors?.includes('invalid_credentials')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Error. The data is incorrect',
      });
    }
    if (errors?.length && errors?.includes('email_not_confirmed')) {
      payload.history.push(routes.Auth.VerifyYourEmail.path, {
        email: payload.loginData.email,
      });
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'You must confirm your e-mail',
      });
    }
    if (errors?.length && errors?.includes('invalid_totp_code')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Error. The data is incorrect',
      });
    }
    if (errors?.length && errors?.includes('ip_not_verified')) {
      payload.history.push({
        pathname: routes.Auth.NewIP.path,
        state: {
          loginData: payload.loginData,
        },
      });
    }
    if (errors?.length && errors?.includes('model_not_found')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'This email is not registred',
      });
    }
  }
}

function* logout({ payload }) {
  try {
    if (payload.type === 'back') {
      const { status } = yield call(api.auth.logout);
      if (status < 200 || status >= 300) {
        throw new Error('Something went wrong');
      }

      payload.history.push(routes.Home.path);
    } else {
      payload.history.replace(routes.Auth.Login.path);
    }
    yield put({ type: types.LOGOUT_SUCCESS });

    notification({
      type: 'info',
      title: 'Logout',
      message: 'Your session was finished',
    });
  } catch (error) {
    yield put({ type: types.LOGOUT_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* putConfirmEmail({ payload }) {
  try {
    const { data, status } = yield call(api.auth.confirmEmail, {
      token: payload.token,
    });
    console.log(data);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    if (thisDemo()) {
      yield put({ type: types.LOGIN_SUCCESS, payload: data });
      payload.history.push(routes.Root.path);
      openModal(() => <ModalDemoLogin />);
    }

    //   const dbEmail = getLS('email_registration');
    //   if(dbEmail){
    // setLS(,{dbEmail})
    //   }
  } catch (error) {
    console.dir(error);
    yield put({ type: types.CONFIRM_EMAIL_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* getRefreshToken() {
  try {
    const { data, status } = yield call(api.auth.refreshToken);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_REFRESH_TOKEN_SUCCESS,
      payload: { token: data.token, last_login: data.token_expired_at },
    });
  } catch (error) {
    yield put({ type: types.GET_REFRESH_TOKEN_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* postResendEmailConfirmation({ body }) {
  try {
    const { data, status } = yield call(
      api.auth.postResendEmailConfirmation,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_RESEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: { token: data.token, last_login: data.token_expired_at },
    });
    notification({
      type: 'success',
      title: 'Verify Email',
      message: 'Message succesfully sent',
    });
  } catch (error) {
    yield put({ type: types.POST_RESEND_EMAIL_CONFIRMATION_FAILURE });
    if (error?.response?.status === 401) return;
    if (
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors?.includes('too_many_attempts')
    ) {
      notification({ type: 'error', message: 'Too many attempts' });
    }
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

// function* getCheckUserOnline() {
//   try {
//     const { status } = yield call(api.auth.getCheckUserOnline);
//     if (status < 200 || status >= 300) throw new Error('Something went wrong');
//     yield put({
//       type: types.GET_CHECK_USER_ONLINE_SUCCESS,
//     });
//   } catch (error) {
//     yield put({ type: types.GET_CHECK_USER_ONLINE_FAILURE });
//     if (error?.response?.status === 401) return;
//     console.log(error);
//     // notification({ type: 'error', message: 'Something went wrong' });
//   }
// }

function* updateUserToken({ payload }) {
  try {
    // const { status } = yield call(api.auth.getCheckUserOnline);
    // if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.UPDATE_USER_TOKEN_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.UPDATE_USER_TOKEN_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* getTokenBySmsCode({ payload, history }) {
  try {
    const { data, status } = yield call(api.auth.getTokenBySmsCode, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    yield put({
      type: types.GET_TOKEN_BY_SMS_CODE_SUCCESS,
    });
    yield put({ type: types.LOGIN_SUCCESS, payload: data });
    window.location.href = routes.Root.path;
    yield put({
      type: types.GET_KYC_VERIFICATIONS_START,
    });
    yield put({
      type: types.GET_TRADER_INFO_START,
    });
    yield put({
      type: types.GET_USER_CASHBACK_START,
    });
  } catch (error) {
    yield put({
      type: types.GET_TOKEN_BY_SMS_CODE_FAILURE,
    });
    if (error?.response?.status === 401) return;

    if (error?.response?.data?.errors?.includes('phone_not_confirmed')) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'Please confirm your mobile phone.',
      });
      history.push(routes.Auth.ConfirmMobilePhone.path, {
        phone: payload.phone,
        password: payload.password,
      });
    }
    // console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* postResendLoginSmsCode({ payload }) {
  try {
    const { data, status } = yield call(
      api.auth.postResendLoginSmsCode,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_RESEND_SMS_CODE_SUCCESS,
    });
  } catch (error) {
    yield put({ type: types.POST_RESEND_SMS_CODE_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* phoneConfirmation({ payload }) {
  try {
    const { data, status } = yield call(api.auth.phoneConfirmation, {
      token: payload.token,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.PHONE_CONFIRMATION_SUCCESS,
    });

    if (payload?.type === 'verify_phone') {
      notification({
        type: 'success',
        title: 'Verify Phone',
        message: 'You have phone verified successfully.',
      });

      yield put({
        type: types.GET_USER_SECURITY_DATA_START,
      });
      payload.history.push({
        pathname: routes.User.Security.path,
      });
      return;
    }
    notification({
      type: 'success',
      title: 'Sign Up',
      message: 'You have registered successfully',
    });
    payload.history.push({
      pathname: routes.Auth.Login.path,
    });
  } catch (error) {
    yield put({ type: types.PHONE_CONFIRMATION_FAILURE });
    if (error?.response?.status === 401) return;
    if (error?.response?.data?.errors?.includes('model_not_found')) {
      notification({
        type: 'error',
        title: 'Confirm',
        message: 'SMS code invalid',
      });
    }
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* resendPhoneConfirmation({ payload }) {
  try {
    const { data, status } = yield call(
      api.auth.resendPhoneConfirmation,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.RESEND_PHONE_CONFIRMATION_SUCCESS,
    });
    if (
      window.location.hostname.includes('orderbook.fun') ||
      window.location.hostname.includes('localhost')
    ) {
      notification({
        type: 'info',
        title: 'SMS code',
        message: data?.token,
        time: 15000,
      });
    }
  } catch (error) {
    yield put({ type: types.RESEND_PHONE_CONFIRMATION_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* postEmailSetConfirm({ payload }) {
  try {
    const { data, status } = yield call(api.auth.postEmailSetConfirm, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_EMAIL_SET_CONFIRMATION_SUCCESS,
    });
    notification({
      type: 'success',
      title: 'Verify Email',
      message: 'You have email verified successfully. Check your e-mail',
    });
  } catch (error) {
    yield put({ type: types.POST_EMAIL_SET_CONFIRMATION_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* postPhoneSetConfirm({ payload, history }) {
  try {
    const { data, status } = yield call(api.auth.postPhoneSetConfirm, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_PHONE_SET_CONFIRM_SUCCESS,
    });

    history.push(routes.Auth.ConfirmMobilePhone.path, {
      type: 'verify_phone',
      phone: payload.phone,
    });

    notification({
      type: 'success',
      title: 'Verify Mobile Phone',
      message: 'Please enter sms code for verify your mobile phone',
    });
  } catch (error) {
    yield put({ type: types.POST_PHONE_SET_CONFIRM_FAILURE });
    if (error?.response?.status === 401) return;

    if (
      error?.response?.data?.errors?.phone?.includes(
        'phone_number_already_exists',
      )
    ) {
      notification({
        type: 'error',
        title: 'Verify Mobile Phone',
        message: 'Error. Phone number has already registered.',
      });
      return;
    }

    if (error?.response?.data?.errors?.[0]?.includes('too_many_attempts')) {
      notification({
        type: 'error',
        title: 'Verify Mobile Phone',
        message: `Please try again after time runs out ${
          error?.response?.data?.errors?.[0]?.split('_')[
            error?.response?.data?.errors?.[0]?.split('_')?.length - 1
          ]
        }s`,
      });
      return;
    }
    console.dir(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* postPhoneTurnOff() {
  try {
    const { data, status } = yield call(api.auth.postPhoneTurnOff);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_PHONE_TURN_OFF_SUCCESS,
    });

    yield put({
      type: types.GET_USER_SECURITY_DATA_START,
    });

    yield put({
      type: types.GET_USER_DATA_START,
    });

    notification({
      type: 'success',
      title: 'Mobile Phone',
      message: 'Your mobile phone is cleared',
    });
  } catch (error) {
    yield put({ type: types.POST_PHONE_TURN_OFF_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
    // notification({ type: 'error', message: 'Something went wrong' });
  }
}

function* sendEmailCode() {
  try {
    const { data, status } = yield call(api.auth.sendEmailCode);

    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    yield put({ type: types.SEND_EMAIL_CODE_SUCCESS });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.SEND_EMAIL_CODE_FAILURE });
    if (error?.response?.status === 401) return;
  }
}

function* deviceConfirmation({ payload, history, loginData }) {
  try {
    const { data, status } = yield call(api.auth.deviceConfirmation, payload);

    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    yield put({ type: types.DEVICE_CONFIRMATION_SUCCESS });

    notification({
      type: 'success',
      title: 'Device',
      message: 'Your new device has been approved',
    });

    history.push(routes.Auth.Login.path, {
      type: 'confirm_device',
      email: loginData.email,
      password: loginData.password,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.DEVICE_CONFIRMATION_FAILURE });
    if (error?.response?.status === 401) return;

    if (error?.response?.data?.errors?.includes('code_is_invalid_or_expired')) {
      notification({
        type: 'error',
        title: 'Device confirmation',
        message: 'Error. Code is invalid.',
      });
    }
  }
}

export function* rootSagaAuth() {
  yield all([
    takeLatest(types.CONFIRM_EMAIL_START, putConfirmEmail),
    takeLatest(types.CHECK_SMS_CODE_START, checkSmsCode),
    takeLatest(types.SEND_SMS_CODE_START, sendSmsCode),
    takeLatest(types.LOGOUT_START, logout),
    takeLatest(types.LOGIN_START, postLogin),
    takeLatest(types.GET_REFRESH_TOKEN_START, getRefreshToken),
    takeLatest(
      types.POST_RESEND_EMAIL_CONFIRMATION_START,
      postResendEmailConfirmation,
    ),
    // takeLatest(types.GET_CHECK_USER_ONLINE_START, getCheckUserOnline),
    takeLatest(types.UPDATE_USER_TOKEN_START, updateUserToken),
    takeLatest(types.GET_TOKEN_BY_SMS_CODE_START, getTokenBySmsCode),
    takeLatest(types.POST_RESEND_SMS_CODE_START, postResendLoginSmsCode),
    takeLatest(types.PHONE_CONFIRMATION_START, phoneConfirmation),
    takeLatest(types.RESEND_PHONE_CONFIRMATION_START, resendPhoneConfirmation),
    takeLatest(types.POST_EMAIL_SET_CONFIRMATION_START, postEmailSetConfirm),
    takeLatest(types.POST_PHONE_SET_CONFIRM_START, postPhoneSetConfirm),
    takeLatest(types.POST_PHONE_TURN_OFF_START, postPhoneTurnOff),
    takeLatest(types.SEND_EMAIL_CODE_START, sendEmailCode),
    takeLatest(types.DEVICE_CONFIRMATION_START, deviceConfirmation),
  ]);
}
