import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';
import { closeModal } from '../../components/Base/Modal';

function* getAssetsStatus() {
  try {
    const { data, status } = yield call(api.status.getAssetsStatus);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_ASSETS_STATUS_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_ASSETS_STATUS_LIST_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* getIncedents({ payload }) {
  try {
    const { data, status } = yield call(api.status.getIncedents, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_INCIDENTS_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_INCIDENTS_LIST_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

export function* rootSagaStatus() {
  yield all([
    takeLatest(types.GET_ASSETS_STATUS_LIST_START, getAssetsStatus),
    takeLatest(types.GET_INCIDENTS_LIST_START, getIncedents),
  ]);
}
