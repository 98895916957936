import types from '../types';

const initialState = {
  info: {},
  loading: false,
};

const traderInfo = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TRADER_INFO_START:
      return { ...state, loading: true };
    case types.GET_TRADER_INFO_SUCCESS:
      return { loading: false, info: payload };
    case types.GET_TRADER_INFO_FAILURE:
      return { ...state, loading: false };
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default traderInfo;
