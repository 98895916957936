import types from '../types';

const initialState = {
  vacancies: [],
  loading: false,
};

const vacancy = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_VACANCY_START:
      return { ...state, loading: true };
    case types.GET_VACANCY_SUCCESS:
      return { loading: false, vacancies: payload };
    case types.GET_VACANCY_FAILURE:
      return { ...state, loading: false };
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default vacancy;
