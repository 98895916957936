import { fork, spawn } from 'redux-saga/effects';
import { rootSagaAuth } from './auth/actions';
import { watcherSetLanguage } from './lng/actions';
import * as assetPairs from './pairs/actions';
import * as spotTrade from './trade/spot/actions';
import * as marginTrade from './trade/margin/actions';
import { watcherResetPassword } from './auth/resetPassword/actions';
import * as reset2FA from './auth/reset2FA/actions';
import { watchOnSocket } from './sockets/action';
import { rootSagaWallets } from './wallets/actions';
import { watcherSignup } from './auth/signup/actions';
import { watcherSetCurrentPair } from './currentPair/actions';
import { watchersendContactUsForm } from './support/actions';
import * as createOrder from './trade/actions';
import { watcherPostCalculateLimitOrder } from './trade/calculate/limitOrder/actions';
import { watcherPostCalculateMarketOrder } from './trade/calculate/marketOrder/actions';
import {
  watcherGetUserTradeVolumeStatistics,
  watcherGetTradingVolumeLevels,
  watcherGetCurrentCahsback,
  watcherGetCashbacksByTrades,
} from './trade/tradeVolumes/actions';
import { watcherChat } from './chat/actions';
import { watcherVoting } from './voting/actions';
import { watchSubmitForm } from './project/actions';
import { watcherChangePassword } from './auth/changePassword/actions';
// import * as futures from './trade/futures/actions';
import { watcherGetUser } from './settings/actions';
import * as apiManagement from './settings/apiManagement/actions';
import { watcherIdentification } from './settings/identification/actions';
import { watcherSetModal } from './modal/actions';
import * as security from './settings/security/actions';
import {
  watcherGetDashboard,
  watcherUpdateTokenDiscount,
  watcherGetTokenDiscount,
} from './settings/dashboard/actions';
import { watcherSetCurrentContract } from './currentContract/actions';
import { rootSagaFutures } from './trade/futures/actions';
import { rootSagaReferralSystem } from './referralSystem/actions';
import {
  watcherGetExchangeRate,
  watcherMakeExchange,
  watcherGetExchangeHistory,
} from './bankTransfer/exchange/actions';
import {
  watcherVerifyUserIP,
  watcherRecentCode,
} from './auth/verifyUserIP/actions';
import {
  watcherVerifyUserStartKYC,
  watcherGetKycVerify,
} from './auth/verifyUserKYC/actions';
import { watcherSetInterval } from './interval/actions';
import { watcherVideos } from './videos/actions';
import { rootSagaFees } from './fees/actions';
import { watcherGetVacancy } from './vacancy/actions';
import { watcherGetTraderInfo } from './traderInfo/actions';
import {
  watcherGetAvailableCashback,
  watcherPostBuyCashback,
  watcherGetUserCashback,
  watcherUpdateUserCashbackRenewal,
} from './cashback/actions';
import {
  watcherGetLimits,
  watcherGetFeesPerInputAndOutput,
} from './limits/actions';
import { rootSagaStatus } from './status/actions';

export function* rootSaga() {
  // -----// sockets_start //-----//
  yield spawn(watchOnSocket);

  // -----// Other //-----//
  yield spawn(rootSagaAuth);
  yield spawn(watcherResetPassword);
  yield spawn(watcherChangePassword);
  yield spawn(watcherSignup);
  yield spawn(watcherSetLanguage);
  yield spawn(watchersendContactUsForm);
  yield spawn(assetPairs.watcherGetTopPairs);
  yield spawn(assetPairs.watcherGetAssetPairs);
  yield spawn(assetPairs.watcherGetFavoriteAssetPairs);
  yield spawn(assetPairs.watcherGetSpotAssetPairs);
  yield spawn(assetPairs.watcherGetMarginAssetPairs);
  yield spawn(assetPairs.watcherUpdateFavoriteAssetPair);
  yield spawn(assetPairs.watcherGetAssetsPairsVolume);
  yield spawn(reset2FA.watcherReset2FA);
  yield spawn(reset2FA.watcherUploadSelfie);
  yield spawn(watcherVerifyUserIP);
  yield spawn(watcherRecentCode);
  yield spawn(watcherGetVacancy);
  yield spawn(watcherGetKycVerify);
  yield spawn(watcherVerifyUserStartKYC);

  // -----// Get user data //-----//
  yield spawn(watcherGetUser);

  // -----// Get trader info //-----//
  yield spawn(watcherGetTraderInfo);

  // -----// Get chat //-----//
  yield spawn(watcherChat);

  // -----// Get vote //-----//
  yield spawn(watcherVoting);
  yield spawn(watchSubmitForm);

  // -----// Get status //-----//
  yield spawn(rootSagaStatus);

  // -----// Identification //-----//
  yield spawn(watcherIdentification);

  // -----// API management //-----//
  yield spawn(apiManagement.watcherGetTokens);
  yield spawn(apiManagement.watcherPostCreateTokens);
  yield spawn(apiManagement.watcherDisableTokens);
  yield spawn(apiManagement.watcherDeleteTokens);

  // -----// Dashboard //-----//
  yield spawn(watcherGetDashboard);
  yield spawn(watcherUpdateTokenDiscount);
  yield spawn(watcherGetTokenDiscount);

  // -----// Security //-----//
  yield spawn(security.watcherUsersResetPassword);
  yield spawn(security.watcherGetUserSecurityData);
  yield spawn(security.watcherEnableGoogle2FA);
  yield spawn(security.watcherDisableGoogle2FA);
  yield spawn(security.watcherGetSecretKey);
  yield spawn(security.watcherGetSecurityOptions);
  yield spawn(security.watcherPutSecurityOptions);
  yield spawn(security.watcherSetWithdrawalSecretPhrase);
  yield spawn(security.watcherGetUserUniqueDevices);
  yield spawn(security.watcherDeleteUserUniqueDevice);
  yield spawn(security.watcherGetUserWhitelistAddresses);
  yield spawn(security.watcherUpdateUserWhitelistAddresses);
  yield spawn(security.watcherCheckValidAddress);
  yield spawn(security.watcherGetUserLoginHistory);
  yield spawn(security.watcherPostApproveWithdrawal);
  yield spawn(security.watcherPostResendAproveWithdrawalCode);

  // -----// trade //-----//
  yield spawn(createOrder.watcherCreateOrder);
  yield spawn(createOrder.watcherCreateStopLimitOrder);

  // -----// trade volumes //-----//
  yield spawn(watcherGetUserTradeVolumeStatistics);
  yield spawn(watcherGetTradingVolumeLevels);
  yield spawn(watcherGetCurrentCahsback);
  yield spawn(watcherGetCashbacksByTrades);

  // -----// spot //-----//
  yield spawn(spotTrade.watcherSpotGetOrders);
  yield spawn(spotTrade.watcherSpotCancelOrder);
  yield spawn(spotTrade.watcherSpotCancelAllOrders);
  yield spawn(spotTrade.watcherSpotGetHistory);
  yield spawn(spotTrade.watcherSpotGetTradeHistory);
  yield spawn(spotTrade.watcherSpotGetOrderBook);
  yield spawn(spotTrade.watcherSpotGetRecentTrades);
  // -----// margin //-----//
  yield spawn(marginTrade.watcherMarginGetOrders);
  yield spawn(marginTrade.watcherMarginCancelOrder);
  yield spawn(marginTrade.watcherMarginCancelAllOrders);
  yield spawn(marginTrade.watcherMarginGetHistory);
  yield spawn(marginTrade.watcherMarginGetTradeHistory);
  yield spawn(marginTrade.watcherMarginLowRisk);
  yield spawn(marginTrade.watcherMarginBorrows);
  yield spawn(marginTrade.watcherMarginSingleBorrow);
  yield spawn(marginTrade.watcherMarginPostBorrow);
  yield spawn(marginTrade.watcherMarginGetRepay);
  yield spawn(marginTrade.watcherMarginPostRepay);
  yield spawn(marginTrade.watcherMarginPostTransform);

  // -----// futures //-----//
  yield spawn(rootSagaFutures); // testing spawn insted of spawn

  // -----// wallets // ----- //
  yield spawn(rootSagaWallets);

  // -----// referralSystem // ----- //
  yield spawn(rootSagaReferralSystem);

  // ---- // currentPair // ----- //
  yield spawn(watcherSetCurrentPair);

  // ---- // currentPair // ----- //
  yield spawn(watcherSetCurrentContract);

  // ---- // watcherPostCalculate // ----- //
  yield spawn(watcherPostCalculateMarketOrder);
  yield spawn(watcherPostCalculateLimitOrder);
  yield spawn(watcherSetModal);

  // ---- // instance exchange // ----- //
  yield spawn(watcherGetExchangeRate);
  yield spawn(watcherMakeExchange);
  yield spawn(watcherGetExchangeHistory);

  // ---- // cashback // ----- //
  yield spawn(watcherGetAvailableCashback);
  yield spawn(watcherPostBuyCashback);
  yield spawn(watcherGetUserCashback);
  yield spawn(watcherUpdateUserCashbackRenewal);

  // ---- // limits // ----- //
  yield spawn(watcherGetLimits);
  yield spawn(watcherGetFeesPerInputAndOutput);

  yield spawn(watcherSetInterval);
  yield spawn(rootSagaFees);
  yield spawn(watcherVideos);
}
