import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import L from 'i18n-react';
import routes from '../../../../routes';
import SelectWrap from '../base/SelectWrap';
import { ThemeContext } from '../../../../ThemeContext';

const SelectWallets = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
console.log('themeeee', theme)
  return (
    <SelectWrap
      title={L.translate('Header.SelectWallets.wallets')}
      listenerID="Wallets"
      className={`${theme}`}
    >
      <ul className="drop-list__item">
        <li>
          <NavLink
            to={routes.Wallets.SpotWallets.path}
            className={`drop-btn ${theme}`}
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.spot')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={routes.Wallets.MarginWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.margin')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to={routes.Wallets.FuturesWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.futures')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to={routes.Wallets.ReferralsWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.referrals')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to={routes.Wallets.P2PWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.P2P')}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to={routes.Wallets.ForexWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            Forex
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.Wallets.BinaryOptionsWallets.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            Binary Options
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to={routes.Wallets.AirdropWallet.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Wallets.Airdrop.airdrop')}
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to={routes.Wallets.TransactionHistory.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.transaction_history')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.Wallets.Orders.path}
            className="drop-btn"
            activeClassName="drop-btn active"
          >
            {L.translate('Header.SelectWallets.orders_info')}
          </NavLink>
        </li>
      </ul>
    </SelectWrap>
  );
};

export default SelectWallets;
