import React from 'react';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import L from 'i18n-react';
import api from '../../../services/api';
import notification from '../../../services/notification';
import types from '../../types';
import { openModal, closeModal } from '../../../components/Base/Modal';
import ModalError from '../../../components/Base/Modals/ModalError';
import ModalWithdrawEmailCodeChecked from '../../../components/Base/Modals/ModalWithdrawEmailCodeChecked';

function* postGenerateAddressForWallet({ payload }) {
  try {
    const { status } = yield call(
      api.wallets.crypto.postGenerateAddressForWallet,
      payload,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_GENERATE_ADRESS_WALLETS_SUCCESS });
    yield put({ type: types.GET_WALLETS_START });
    notification({
      type: 'success',
      title: 'Deposit',
      message: 'Address succesfully created',
    });
  } catch (error) {
    yield put({
      type: types.POST_GENERATE_ADRESS_WALLETS_FAILURE,
    });
    if (error.response.status === 401) return;
    if (error.response.data.errors.includes('verification_required')) {
      // notification({
      //   type: 'error',
      //   title: 'Withdrawal',
      //   message: 'You entered wrong address',
      // });
      openModal(() => (
        <ModalError
          textError={L.translate(
            'Wallets.Spot.FiatDeposit.NotificationModal.security_level_of_your_account_is_low',
          )}
        />
      ));
      return;
    }
    notification({
      type: 'error',
      title: 'Deposit',
      message: 'Deposit is disabled',
    });
  }
}

function* postCreateWithdrawalRequest({ body, clearFields }) {
  try {
    const { data, status } = yield call(
      api.wallets.crypto.postCreateWithdrawalRequest,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.POST_CREATE_WITHDRAWAL_REQUEST_SUCCESS,
      payload: data,
    });
    console.log(data.withdrawal.status);

    notification({
      type: 'success',
      title: 'Withdrawal',
      message: 'Your request is successfully created',
    });
    if (data.withdrawal.status === 'ip_approve_waiting') {
      openModal(() => (
        <ModalWithdrawEmailCodeChecked
          id={data?.withdrawal?.id}
          clearFields={clearFields}
        />
      ));
    } else {
      yield call(clearFields);
      closeModal();
    }
  } catch (error) {
    yield put({
      type: types.POST_CREATE_WITHDRAWAL_REQUEST_FAILURE,
    });

    if (
      error?.response?.data?.errors?.totp?.includes(
        'the_totp_field_is_required',
      )
    ) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'The Google Authentication field is required',
      });
      return;
    }
    if (
      error?.response?.data?.errors?.email_code?.includes(
        'the_email_code_field_is_required',
      )
    ) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'The email code field is required',
      });
      return;
    }
    if (
      error?.response?.data?.errors?.sms_code?.includes(
        'the_sms_code_field_is_required',
      )
    ) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'The mobile code field is required',
      });
      return;
    }
    if (
      error?.response?.data?.errors?.includes('first_you_must_verify_your_identity')
    ) {
      closeModal();
      clearFields();
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'First you must verify your identity.',
      });
      return;
    }

    if (error.response.data.errors.includes('wrong_wallet_address')) {
      notification({
        type: 'error',
        title: 'Withdrawal',
        message: 'You entered wrong address',
      });
    }
    if (error?.response?.data?.errors?.includes('invalid_totp_code')) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'You entered wrong 2FA code',
      });
    }
    if (error?.response?.data?.errors?.includes('invalid_email_code')) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'You entered wrong email code',
      });
    }
    if (error?.response?.data?.errors?.includes('invalid_sms_code')) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: 'You entered wrong mobile code',
      });
    }
    if (
      error?.response?.data?.errors?.includes('not_enough_ethereum_deposits')
    ) {
      notification({
        type: 'error',
        title: 'Withdraw',
        message: `${L.translate(
          'Wallets.Spot.CryptoWithdraw.Notification.not_enough_ethereum_deposits',
        )} ${error?.response?.data?.data?.amount}`,
      });
    }
    if (
      error?.response?.data?.errors?.includes('min_quantity_limit_exceeded')
    ) {
      notification({
        type: 'error',
        title: 'Withdrawal',
        message: 'Min quantity limit exceeded',
      });
    }
    if (error?.response?.data?.errors?.includes('not_enough_eth_funds')) {
      notification({
        type: 'error',
        title: 'Withdrawal',
        message: 'Not enough ETH funds',
      });
    }
    if (
      error?.response?.data?.errors?.includes(
        'withdrawal_address_not_from_whitelist',
      )
    ) {
      notification({
        type: 'error',
        title: 'Withdrawal',
        message: 'Withdrawal address not from whitelist',
      });
    }
  }
}

function* deleteWithdrawalDepositRequest({ body, query }) {
  try {
    console.log(body);
    const { status } = yield call(
      api.wallets.crypto.deleteWithdrawalDepositRequest,
      body,
    );
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.DELETE_WITHDRAWAL_DEPOSIT_SUCCESS,
    });
    yield put({
      type: types.GET_WALLETS_TRANSACTIONS_START,
      query,
    });
  } catch (error) {
    yield put({
      type: types.DELETE_WITHDRAWAL_DEPOSIT_FAILURE,
    });
  }
}

export function* rootSagaWalletsCrypto() {
  yield all([
    takeLatest(
      types.DELETE_WITHDRAWAL_DEPOSIT_START,
      deleteWithdrawalDepositRequest,
    ),
    takeLatest(
      types.POST_CREATE_WITHDRAWAL_REQUEST_START,
      postCreateWithdrawalRequest,
    ),
    takeLatest(
      types.POST_GENERATE_ADRESS_WALLETS_START,
      postGenerateAddressForWallet,
    ),
  ]);
}
