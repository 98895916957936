import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import types from '../types';
import { encryptor } from '../encryptor';
import { period } from '../../components/Base/Chart/datafeed/historyProvider';
import { setLS } from '../../services/localStorage';

const initialState = {
  clearChart: false,
  loader: false,
  socket_token: false,
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_START:
      return { ...state, loader: true };
    case types.LOGIN_SUCCESS:
      console.log({payload},"=============================================")
      localStorage.setItem("firoz_token",payload.token)
      return { ...payload, loader: false };
    case types.LOGIN_FAILURE:
      return { ...state, loader: false };
    case types.UPDATE_USER_TOKEN_SUCCESS:
      return { ...state, token: payload };

    case types.VERIFY_USER_KYC_START:
      return { ...state, loader: true };
    case types.VERIFY_USER_KYC_FAILURE:
      return { ...state, loader: false };
    // user information

    case types.GET_USER_DATA_SUCCESS:
      return { ...state, ...payload };

    // uploading/deliting personal documents
    case types.DELETE_DOCUMENTS_START:
    case types.UPDATE_DOCUMENTS_START:
      return { ...state, loadingFile: payload.document_id };

    case types.DELETE_DOCUMENTS_FAILURE:
    case types.UPDATE_DOCUMENTS_FAILURE:
      return { ...state, loadingFile: null };

    case types.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        clearChart: true,
        loadingFile: null,
        documents: state.documents.map(document =>
          document.id === payload.document_id
            ? { ...document, files: [] } // put empty array
            : document,
        ),
      };

      case types.GET_REFRESH_TOKEN_SUCCESS:
        localStorage.setItem("firoz_token", payload.token); // Update token in localStorage
        return { 
          ...state, 
          token: payload.token, 
          token_expired_at: payload.token_expired_at 
        };

    case types.UPDATE_DOCUMENTS_SUCCESS: // update file properties in redux state
      return {
        ...state,
        loadingFile: null,
        documents: state.documents.map(document =>
          document.id === state.loadingFile
            ? { ...document, files: [payload] } // put uploaded file
            : document,
        ),
      };

    case types.GET_USER_CASHBACK_SUCCESS:
      return { ...state, cashback: payload };
    case types.UPDATTE_USER_CASHBACK_RENEWAL_SUCCESS:
      return {
        ...state,
        cashback: {
          ...state.cashback,
          auto_renewal: +!state.cashback.auto_renewal,
        },
      };

    // update user personal date
    case types.UPDATE_USER_DATA_SUCCESS:
      return { ...state, data: payload };

    case types.LOGOUT_SUCCESS:
      period.clearChart = false;
      return initialState;

    case types.VERIFY_USER_KYC_SUCCESS:
      return { ...state, kyc: { ...state.kyc, payload }, loader: false };

    case types.GET_KYC_VERIFICATIONS_SUCCESS:
      return { ...state, kyc: { ...state.kyc, ...payload } };

    default:
      return state;
  }
};

const authConfig = {
  key: 'Betconix_auth',
  storage,
  white: ['socket_token', 'token', 'token_expired_at'],
  transforms: [encryptor],
};

export default persistReducer(authConfig, user);
// export default user;
