import types from '../types';

const initialState = {
  current_page: 0,
  last_page: 0,
  messages: [],
  per_page: 0,
  supported_langs: [],
  chat_users: {},
  total: 0,
};

const chat = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_MESSAGES_SUCCESS:
      if (payload.priority === 'moderator') {
        return payload;
      }
      return {
        ...state,
        ...payload,
        messages: [...payload.messages, ...state.messages],
      };

    case types.DELETE_MESSAGE_FROM_DATABASE_SUCCESS: {
      return {
        ...state,
        messages: state.messages.filter(el => el.id !== payload.id),
      };
    }

    case types.DELETE_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: state.messages.map(el =>
          el.id === payload.id ? { ...el, status: 'deleted' } : el,
        ),
      };
    }

    case types.UPDATE_CHAT_MESSAGES:
      if (payload.action === 'created') {
        return { ...state, messages: [...state.messages, payload.data] };
      }
      if (payload.action === 'updated') {
        return {
          ...state,
          messages: state.messages.map(el =>
            el.id === payload.data.id
              ? { ...el, message: payload.data.message }
              : el,
          ),
        };
      }
      if (payload.action === 'soft_deleted') {
        return {
          ...state,
          messages: state.messages.filter(el => el.id !== payload.data.id),
        };
      }
      return { ...state, messages: [...state.messages, payload.data] };

    case types.GET_CHAT_USERS_SUCCESS:
      return { ...state, chat_users: payload };

    case types.UPDATE_USER_CHAT_STATUS_SUCCESS:
      return {
        ...state,
        chat_users: {
          ...state.chat_users,
          users: state.chat_users.users.map(el =>
            el.user_id === payload.user_id
              ? { ...el, status: payload.status }
              : el,
          ),
        },
      };

    case types.LOGOUT_SUCCESS:
    case types.UPDATE_CHAT_LANGUAGE_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default chat;
