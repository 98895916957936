import React from 'react';
import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import L from 'i18n-react';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';
import { openModal } from '../../components/Base/Modal';
import ModalError from '../../components/Base/Modals/ModalError';

function* getMessages({ payload, scrollToBottom, priority = '' }) {
  try {
    const { data, status } = yield call(api.chat.getMessages, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_MESSAGES_SUCCESS,
      payload: { ...data, priority },
    });
    scrollToBottom();
  } catch (error) {
    yield put({ type: types.GET_MESSAGES_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* postMessage({ payload, scrollToBottom }) {
  try {
    const { data, status } = yield call(api.chat.postMessage, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.POST_MESSAGE_SUCCESS, payload: data });
    scrollToBottom();
  } catch (error) {
    yield put({ type: types.POST_MESSAGE_FAILURE });
    if (error?.response?.status === 401) return;
    console.dir(error);
    if (error?.response?.data?.error?.includes('deposit_required')) {
      notification({
        type: 'error',
        title: 'Chat',
        message: 'You need to deposit to be able to use Chat.',
      });
      openModal(() => (
        <ModalError
          textError={L.translate(
            'Chat.NotificationModal.need_to_deposit_to_be_able_to_use_chat',
          )}
        />
      ));
    }
  }
}

function* editMessage({ payload }) {
  try {
    const { data, status } = yield call(api.chat.editMessage, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.EDIT_MESSAGE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.EDIT_MESSAGE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* deleteMessage({ payload }) {
  try {
    const { status } = yield call(api.chat.deleteMessage, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.DELETE_MESSAGE_SUCCESS, payload });
  } catch (error) {
    yield put({ type: types.DELETE_MESSAGE_FAILURE });
    if (error?.response?.status === 401) return;
    console.dir(error);
  }
}

function* deleteMessageFromDatabase({ payload }) {
  try {
    const { status } = yield call(api.chat.deleteMessageFromDatabase, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.DELETE_MESSAGE_FROM_DATABASE_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.DELETE_MESSAGE_FROM_DATABASE_FAILURE });
    if (error?.response?.status === 401) return;
    console.dir(error);
  }
}

function* updateChatLanguage({ payload }) {
  try {
    const { status } = yield call(api.chat.updateChatLanguage, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.UPDATE_CHAT_LANGUAGE_SUCCESS });
  } catch (error) {
    yield put({ type: types.UPDATE_CHAT_LANGUAGE_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* updateChatName({ payload }) {
  try {
    const { status } = yield call(api.chat.updateChatName, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.UPDATE_CHAT_NAME_SUCCESS });
    yield put({ type: types.GET_USER_DATA_START });
  } catch (error) {
    yield put({ type: types.UPDATE_CHAT_NAME_FAILURE });
    if (error?.response?.status === 401) return;
    if (
      error?.response?.data?.errors?.chat_name &&
      error?.response?.data?.errors?.chat_name?.includes(
        'the_chat_name_must_be_at_least5_characters',
      )
    ) {
      notification({
        type: 'Error',
        title: 'Chat Name',
        message: 'The chat name must be at least 5 characters',
      });
    }
  }
}

function* getChatUsers({ payload }) {
  try {
    const { data, status } = yield call(api.chat.getChatUsers, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_CHAT_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: types.GET_CHAT_USERS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

function* updateUserChatStatus({ payload }) {
  try {
    const { status } = yield call(api.chat.updateUserChatStatus, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.UPDATE_USER_CHAT_STATUS_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({ type: types.UPDATE_USER_CHAT_STATUS_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

export function* watcherChat() {
  yield all([
    takeLatest(types.GET_MESSAGES_START, getMessages),
    takeLatest(types.POST_MESSAGE_START, postMessage),
    takeLatest(types.EDIT_MESSAGE_START, editMessage),
    takeLatest(types.DELETE_MESSAGE_START, deleteMessage),
    takeLatest(
      types.DELETE_MESSAGE_FROM_DATABASE_START,
      deleteMessageFromDatabase,
    ),
    takeLatest(types.UPDATE_CHAT_LANGUAGE_START, updateChatLanguage),
    takeLatest(types.UPDATE_CHAT_NAME_START, updateChatName),
    takeLatest(types.GET_CHAT_USERS_START, getChatUsers),
    takeLatest(types.UPDATE_USER_CHAT_STATUS_START, updateUserChatStatus),
  ]);
}
