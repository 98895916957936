import React, { useEffect, useRef, useContext } from 'react';
import { useLocation, NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import L from 'i18n-react';
import { ThemeContext } from '../../../ThemeContext';
// import SelectAnalytics from './SelectAnalytics';
// import SelectOrders from './SelectOrders';
// import SelectDownload from './SelectDownload';
import SelectTrade from './SelectTrade';
import SelectWallets from './SelectWallets';
import routes from '../../../routes';
import SelectUser from './SelectUser';
import SelectLanguage from './SelectLanguage';
import Download from './Download';
import SelectPairs from './SelectPairs';
import BalanceBlock from './BalanceBlock';
import SelectContracts from './SelectContracts';
import { tokenSelector } from '../../../redux/auth/selectors';
import { languageSelector } from '../../../redux/lng/selectors';
import { thisDemo, toHome } from '../../../services/helpers';
import notification from '../../../services/notification';
import { useMediaQuery } from 'react-responsive';

const Headers = ({ isLogin }) => {
  const refMobileMenu = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const language = useSelector(languageSelector); // need to update language
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isApiDocsRoute = window.location.pathname ;
  const themeHeader = location.pathname.includes(routes.Trade.path);
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' });
  const isAuthenticated = useSelector(state => state.settings.user.isAuthenticated);

  const toggleMobileMenu = e => {
    if (e.target === refMobileMenu.current) {
      refMobileMenu.current.classList.toggle('active');
      return;
    }
    refMobileMenu.current.classList.add('active');
  };
  const verifyAuth = e => {
    // const to = e?.currentTarget?.dataset?.to;
    // if (to && isLogin) {
    //   history.replace(to);
    // } else {
    // notification({
    //   type: 'error',
    //   message: 'You need to login ',
    // });
    //   history.push(routes.Auth.Login.path);
    // }
    if (!isLogin) {
      notification({
        type: 'error',
        message: 'You need to login ',
      });
    }
  };
  console.log('headerrrr', location.pathname)
  useEffect(() => {
    if (refMobileMenu.current.classList.contains('active')) {
      refMobileMenu.current.classList.remove('active');
    }
  }, [location.pathname, language]);
  return (
    <>
      <header
        className={isApiDocsRoute === '/api_docs' ? 'header-hidden' : 'header'}
      >
        <div class="d-flex header-container common header-leftside" style={isMobile ? {width: '40%'} : {width: 'auto'}}>
          <a
            href={toHome('')}
            className="page-logo"
          >
            <img
              src={themeHeader ? '/img/stablecoin.marketupdated-1.svg' : '/img/stablecoin.marketupdated-1.svg'}
              alt=""
            />
          </a>
          <div
            className="main-nav"
            ref={refMobileMenu}
            role="presentation"
            onClick={toggleMobileMenu}
          >
            <div  className={`main-nav__inner ${theme}`} style={{ zIndex: 100, marginLeft: '-13px' }}>
              <ul className="nav-list">
                {isLogin &&
                  thisDemo() &&
                  location.pathname.includes(routes.Trade.path) && (
                    <BalanceBlock />
                  )}
                <li>
                  {/* <button
                  type="button"
                  data-to={routes.BuyCrypto.Exchange.path}
                  onClick={verifyAuth}
                >
                  {L.translate('Header.SelectBuyCrypto.exchange')}
                </button> */}
                  <NavLink
                    to={
                      isLogin
                        ? routes.BuyCrypto.Exchange.path
                        : routes.Auth.Login.path
                    }
                    // data-to={routes.BuyCrypto.BuyCrypto.path}
                    onClick={verifyAuth}
                    className={`${theme}`}
                  >
                    {L.translate('Header.SelectBuyCrypto.exchange')}
                  </NavLink>
                </li>
                {/* <li>
                  <button
                  type="button"
                  data-to={routes.BuyCrypto.BuyCrypto.path}
                  onClick={verifyAuth}
                >
                  {L.translate('Header.SelectBuyCrypto.buy_crypto')}
                </button>
                  <NavLink
                    to={
                      isLogin
                        ? routes.BuyCrypto.BuyCrypto.path
                        : routes.Auth.Login.path
                    }
                    // data-to={routes.BuyCrypto.BuyCrypto.path}
                    onClick={verifyAuth}
                    className={`${theme}`}
                  >
                    {L.translate('Header.SelectBuyCrypto.buy_crypto')}
                  </NavLink>
                </li> */}
                {!isLogin && (
                  <li style={{ width: 'max-content'}}>
                    <NavLink to={routes.Cashback.path} className={`${theme}`}>
                      {L.translate('Header.vip.title')}
                    </NavLink>
                  </li>
                )}
                {/* <SelectTrade isLogin={isLogin} /> */}
                <li>
                <NavLink
                  to={routes.Pairs.path}
                  style={{ color: '#EE6829' }}
                  className={`${theme}`}
                >
                 {L.translate('Header.SelectTrade.list_of_pairs')}
                </NavLink>
                </li>
                <li>
                <NavLink
                  to={routes.Trade.SpotTrade.path}
                  style={{ color: '#EE6829' }}
                  className={`${theme}`}
                >
                  {L.translate('Header.SelectTrade.spot')}
                </NavLink>
                </li>
                {isLogin ? (
                  <>
                    <SelectWallets />
                    <li>
                      <NavLink to={routes.Cashback.path} className={`${theme}`}>
                        Cashback{' '}
                        <span
                          style={{ color: '#EE6829', paddingLeft: '3px' }}
                        >
                          {' '}
                          VIP
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={routes.Vote.path}
                        style={{ color: '#EE6829' }}
                        className={`${theme}`}
                      >
                        Launchpad
                      </NavLink>
                    </li>
                    {/* {!thisDemo() && (
                    <li>
                      <NavLink to={routes.Referral.path}>
                        <span className="account-bav__text">
                          {L.translate('ReferralsPage.referral_system')}
                        </span>
                      </NavLink>
                    </li>
                  )} */}
                  </>
                ) : (
                  <>
                    <li>
                      <NavLink
                        to={routes.Vote.path}
                        style={{ color: '#EE6829' }}
                        className={`${theme}`}
                      >
                        Launchpad
                      </NavLink>
                    </li>
                    <li className='auth'>
                    <NavLink
                      to={routes.Auth.Login.path}
                      style={{ color: '#EE6829' }}
                      className={`${theme}`}
                    >
                      Login
                    </NavLink>
                  </li>
                  <li className='auth'>
                    <NavLink
                      to={routes.Auth.Signup.path}
                      style={{ color: '#EE6829' }}
                      className={`${theme}`}
                    >
                      Sign up
                    </NavLink>
                  </li>
                  </>
                )}
                {isMobile && (
                  <li>
                    <div className='dark-light-toggle' style={{ paddingLeft: '0' }}>
                      <input type="checkbox" class="checkbox" id="checkbox" onClick={toggleTheme} />
                      <label for="checkbox" class="checkbox-label">
                        <i class="fas fa-moon"></i>
                        <i class="fas fa-sun"></i>
                        <span class="ball"></span>
                      </label>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex header-container common header-rightside">
          {/* {location.pathname.includes(routes.Trade.path) ? (
                        <SelectPairs />
                      ) : null} */}
          {isLogin ? (
            <SelectUser />
          ) : (
            <div className="cred header_sign_btn_group">
              <NavLink
                to={routes.Auth.Login.path}
                className={`page-btn page-btn--type2 header-btn ${theme}`}
              >
                {L.translate('Global.login')}
              </NavLink>
              <NavLink to={routes.Auth.Signup.path} className="page-btn header-btn">
                {L.translate('Global.sign_up')}
              </NavLink>
            </div>
          )}
          <SelectLanguage />
          {!isMobile && (<Download />)}
          {/* <div style={{ marginTop: '10px' }}>
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"/>
          </svg>
          </div> */}
          {!isMobile && (<div className='dark-light-toggle'>
            <input type="checkbox" class="checkbox" id="checkbox" onClick={toggleTheme} />
            <label for="checkbox" class="checkbox-label">
              <i class="fas fa-moon"></i>
              <i class="fas fa-sun"></i>
              <span class="ball"></span>
            </label>
          </div>)}
          {/* <SelectDownload /> */}
          {/* {isLogin ? (
                        <div role="presentation" className="top-action">
                          <NavLink to="/#" className="top-action__btn">
                            <i className="fa fa-bell" />
                          </NavLink>
                          <span className="count">7</span>
                        </div>
                      ) : null} */}
          <div className="cred">
            <button
              type="button"
              className={`${theme === 'dark' ? 'nav-btn nav-dark' : 'nav-btn'}`}
              onClick={toggleMobileMenu}
              style={{  marginTop: '10px' }}
            >
              <i className="fa fa-bars" />
            </button>
          </div>
        </div>
      </header>
    </>
  );
};
export default React.memo(Headers);