import React from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { currentPairSelector } from '../../../../redux/currentPair/selectors';
import { toCrop, numberWithCommas } from '../../../../services/helpers';

const BalanceBlock = () => {
  const pair = useSelector(currentPairSelector);
  const buyCode = pair?.split('_')[1];
  const buyBalance = useSelector(state => state.wallets.spot?.[buyCode]);
  const balance = buyBalance?.balance || 0;
  // const sellBalance = useSelector(
  //   state => state.wallets.spot?.[sellCode]?.balance,
  // );
  // console.log(buyBalance, sellBalance);
  return (
    <li>
      <p style={{ color: '#FFA227' }}>
        {L.translate('Header.BalanceBlock.balance')}{' '}
        {(buyBalance?.asset?.code === 'usd' &&
          numberWithCommas(toCrop(4)(balance))) ||
          (buyBalance?.asset?.code === 'usdt' &&
            numberWithCommas(toCrop(4)(balance))) ||
          numberWithCommas(toCrop(8)(balance))}{' '}
        {buyBalance?.asset?.code.toUpperCase()}
      </p>
    </li>
  );
};

export default BalanceBlock;
