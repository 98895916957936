import types from '../types';

const initialState = {
  data: [],
  loading: false,
};

const cashback = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_AVAILABLE_CASHBACK_START:
      return { ...state, loading: true };
    case types.GET_AVAILABLE_CASHBACK_SUCCESS:
      return { loading: false, data: payload };
    case types.GET_AVAILABLE_CASHBACK_FAILURE:
      return { ...state, loading: false };
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default cashback;
