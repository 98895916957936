export default {
  // -----// LOGIN //-----//
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  // -----// UPDATE_USER_TOKEN //-----//
  UPDATE_USER_TOKEN_START: 'UPDATE_USER_TOKEN_START',
  UPDATE_USER_TOKEN_SUCCESS: 'UPDATE_USER_TOKEN_SUCCESS',
  UPDATE_USER_TOKEN_FAILURE: 'UPDATE_USER_TOKEN_FAILURE',

  // -----// GET_TOKEN_BY_SMS_CODE //-----//
  GET_TOKEN_BY_SMS_CODE_START: 'GET_TOKEN_BY_SMS_CODE_START',
  GET_TOKEN_BY_SMS_CODE_SUCCESS: 'GET_TOKEN_BY_SMS_CODE_SUCCESS',
  GET_TOKEN_BY_SMS_CODE_FAILURE: 'GET_TOKEN_BY_SMS_CODE_FAILURE',

  // -----// POST_RESEND_SMS_CODE //-----//
  POST_RESEND_SMS_CODE_START: 'POST_RESEND_SMS_CODE_START',
  POST_RESEND_SMS_CODE_SUCCESS: 'POST_RESEND_SMS_CODE_SUCCESS',
  POST_RESEND_SMS_CODE_FAILURE: 'POST_RESEND_SMS_CODE_FAILURE',

  // -----// CHECK_USER_ONLINE //-----//
  GET_CHECK_USER_ONLINE_START: 'GET_CHECK_USER_ONLINE_START',
  GET_CHECK_USER_ONLINE_SUCCESS: 'GET_CHECK_USER_ONLINE_SUCCESS',
  GET_CHECK_USER_ONLINE_FAILURE: 'GET_CHECK_USER_ONLINE_FAILURE',

  // -----// CHECK_SMS_CODE //-----//
  CHECK_SMS_CODE_START: 'CHECK_SMS_CODE_START',
  CHECK_SMS_CODE_SUCCESS: 'CHECK_SMS_CODE_SUCCESS',
  CHECK_SMS_CODE_FAILURE: 'CHECK_SMS_CODE_FAILURE',

  // -----// SEND_SMS_CODE //-----//
  SEND_SMS_CODE_START: 'SEND_SMS_CODE_START',
  SEND_SMS_CODE_SUCCESS: 'SEND_SMS_CODE_SUCCESS',
  SEND_SMS_CODE_FAILURE: 'SEND_SMS_CODE_FAILURE',

  // -----// SEND_SMS_CODE //-----//
  SEND_EMAIL_CODE_START: 'SEND_EMAIL_CODE_START',
  SEND_EMAIL_CODE_SUCCESS: 'SEND_EMAIL_CODE_SUCCESS',
  SEND_EMAIL_CODE_FAILURE: 'SEND_EMAIL_CODE_FAILURE',

  // -----// SET_MODAL //-----//
  SET_MODAL_START: 'SET_MODAL_START',
  SET_MODAL_SUCCESS: 'SET_MODAL_SUCCESS',
  SET_MODAL_FAILURE: 'SET_MODAL_FAILURE',

  // -----// POST_RESEND_EMAIL_CONFIRMATION //-----//
  POST_RESEND_EMAIL_CONFIRMATION_START: 'POST_RESEND_EMAIL_CONFIRMATION_START',
  POST_RESEND_EMAIL_CONFIRMATION_SUCCESS:
    'POST_RESEND_EMAIL_CONFIRMATION_SUCCESS',
  POST_RESEND_EMAIL_CONFIRMATION_FAILURE:
    'POST_RESEND_EMAIL_CONFIRMATION_FAILURE',

  // -----// POST_EMAIL_SET_CONFIRMATION //-----//
  POST_EMAIL_SET_CONFIRMATION_START: 'POST_EMAIL_SET_CONFIRMATION_START',
  POST_EMAIL_SET_CONFIRMATION_SUCCESS: 'POST_EMAIL_SET_CONFIRMATION_SUCCESS',
  POST_EMAIL_SET_CONFIRMATION_FAILURE: 'POST_EMAIL_SET_CONFIRMATION_FAILURE',

  // -----// POST_PHONE_SET_CONFIRM //-----//
  POST_PHONE_SET_CONFIRM_START: 'POST_PHONE_SET_CONFIRM_START',
  POST_PHONE_SET_CONFIRM_SUCCESS: 'POST_PHONE_SET_CONFIRM_SUCCESS',
  POST_PHONE_SET_CONFIRM_FAILURE: 'POST_PHONE_SET_CONFIRM_FAILURE',


  SUBMIT_FORM_REQUEST : 'SUBMIT_FORM_REQUEST',

  // -----// POST_PHONE_TURN_OFF //-----//
  POST_PHONE_TURN_OFF_START: 'POST_PHONE_TURN_OFF_START',
  POST_PHONE_TURN_OFF_SUCCESS: 'POST_PHONE_TURN_OFF_SUCCESS',
  POST_PHONE_TURN_OFF_FAILURE: 'POST_PHONE_TURN_OFF_FAILURE',

  // -----// GET_FEES //-----//
  GET_FEES_START: 'GET_FEES_START',
  GET_FEES_SUCCESS: 'GET_FEES_SUCCESS',
  GET_FEES_FAILURE: 'GET_FEES_FAILURE',

  // -----// LOGOUT //-----//
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  CLEAR_USER_STATE: 'CLEAR_USER_STATE',
  // ----- // RESET_PASSWORD // ----- //
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  // ----- // CHANGE_PASSWORD // ----- //
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  // ----- // SIGNUP // ----- //
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',

  // ----- // PHONE_CONFIRMATION // ----- //
  PHONE_CONFIRMATION_START: 'PHONE_CONFIRMATION_START',
  PHONE_CONFIRMATION_SUCCESS: 'PHONE_CONFIRMATION_SUCCESS',
  PHONE_CONFIRMATION_FAILURE: 'PHONE_CONFIRMATION_FAILURE',

  // ----- // RESEND_PHONE_CONFIRMATION // ----- //
  RESEND_PHONE_CONFIRMATION_START: 'RESEND_PHONE_CONFIRMATION_START',
  RESEND_PHONE_CONFIRMATION_SUCCESS: 'RESEND_PHONE_CONFIRMATION_SUCCESS',
  RESEND_PHONE_CONFIRMATION_FAILURE: 'RESEND_PHONE_CONFIRMATION_FAILURE',

  // ----- // DEVICE_CONFIRMATION // ----- //
  DEVICE_CONFIRMATION_START: 'DEVICE_CONFIRMATION_START',
  DEVICE_CONFIRMATION_SUCCESS: 'DEVICE_CONFIRMATION_SUCCESS',
  DEVICE_CONFIRMATION_FAILURE: 'DEVICE_CONFIRMATION_FAILURE',

  // ----- // SET_CURRENT_PAIR // ----- //
  SET_CURRENT_PAIR_START: 'SET_CURRENT_PAIR_START',
  SET_CURRENT_PAIR_SUCCESS: 'SET_CURRENT_PAIR_SUCCESS',
  SET_CURRENT_PAIR_FAILURE: 'SET_CURRENT_PAIR_FAILURE',

  // ----- // SET_CURRENT_CONTRACT // ----- //
  SET_CURRENT_CONTRACT_START: 'SET_CURRENT_CONTRACT_START',
  SET_CURRENT_CONTRACT_SUCCESS: 'SET_CURRENT_CONTRACT_SUCCESS',
  SET_CURRENT_CONTRACT_FAILURE: 'SET_CURRENT_CONTRACT_FAILURE',

  // ----- // SET_LANGUAGE // ----- //
  SET_LANGUAGE_START: 'SET_LANGUAGE_START',
  SET_LANGUAGE_SUCCESS: 'SET_LANGUAGE_SUCCESS',
  SET_LANGUAGE_FAILURE: 'SET_LANGUAGE_FAILURE',

  // ----- // SOCKET // ----- //
  SOCKET_OPEN: 'SOCKET_OPEN',
  SOCKET_CLOSE: 'SOCKET_CLOSE',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_SEND_CHANNEL: 'SOCKET_SEND_CHANNEL',
  SOCKET_DISCONNECTED: 'SOCKET_DISCONNECTED',

  // ----- // GET_ASSETS // ----- //
  GET_ASSETS_START: 'GET_ASSETS_START',
  GET_ASSETS_SUCCESS: 'GET_ASSETS_SUCCESS',
  GET_ASSETS_FAILURE: 'GET_ASSETS_FAILURE',

  // ----- // GET_ASSET_PAIRS // ----- //
  GET_ASSET_PAIRS_START: 'GET_ASSET_PAIRS_START',
  GET_ASSET_PAIRS_SUCCESS: 'GET_ASSET_PAIRS_SUCCESS',
  GET_ASSET_PAIRS_FAILURE: 'GET_ASSET_PAIRS_FAILURE',

  // ----- // GET_ASSET_PAIRS_VOLUME // ----- //
  GET_ASSET_PAIRS_VOLUME_START: 'GET_ASSET_PAIRS_VOLUME_START',
  GET_ASSET_PAIRS_VOLUME_SUCCESS: 'GET_ASSET_PAIRS_VOLUME_SUCCESS',
  GET_ASSET_PAIRS_VOLUME_FAILURE: 'GET_ASSET_PAIRS_VOLUME_FAILURE',

  // ----- // UPDATE_ASSET_PAIRS // ----- //
  UPDATE_ASSET_PAIRS: 'UPDATE_ASSET_PAIRS',

  // ----- // GET_FAVORITE_ASSET_PAIRS // ----- //
  GET_FAVORITE_ASSET_PAIRS_START: 'GET_FAVORITE_ASSET_PAIRS_START',
  GET_FAVORITE_ASSET_PAIRS_SUCCESS: 'GET_FAVORITE_ASSET_PAIRS_SUCCESS',
  GET_FAVORITE_ASSET_PAIRS_FAILURE: 'GET_FAVORITE_ASSET_PAIRS_FAILURE',

  // ----- // GET_SPOT_ASSET_PAIRS // ----- //
  GET_SPOT_ASSET_PAIRS_START: 'GET_SPOT_ASSET_PAIRS_START',
  GET_SPOT_ASSET_PAIRS_SUCCESS: 'GET_SPOT_ASSET_PAIRS_SUCCESS',
  GET_SPOT_ASSET_PAIRS_FAILURE: 'GET_SPOT_ASSET_PAIRS_FAILURE',

  // ----- // GET_SPOT_ASSET_PAIRS // ----- //
  GET_MARGIN_ASSET_PAIRS_START: 'GET_MARGIN_ASSET_PAIRS_START',
  GET_MARGIN_ASSET_PAIRS_SUCCESS: 'GET_MARGIN_ASSET_PAIRS_SUCCESS',
  GET_MARGIN_ASSET_PAIRS_FAILURE: 'GET_MARGIN_ASSET_PAIRS_FAILURE',

  // ----- // UPDATE_FAVORITE_ASSET_PAIRS // ----- //
  UPDATE_FAVORITE_ASSET_PAIR_START: 'UPDATE_FAVORITE_ASSET_PAIR_START',
  UPDATE_FAVORITE_ASSET_PAIR_SUCCESS: 'UPDATE_FAVORITE_ASSET_PAIR_SUCCESS',
  UPDATE_FAVORITE_ASSET_PAIR_FAILURE: 'UPDATE_FAVORITE_ASSET_PAIR_FAILURE',

  // ----- // GET_TOPICS // ----- //
  GET_TOPICS_START: 'GET_TOPICS_START',
  GET_TOPICS_SUCCESS: 'GET_TOPICS_SUCCESS',
  GET_TOPICS_FAILURE: 'GET_TOPICS_FAILURE',

  // ----- // SEND_CONTACTUS_FORM // ----- //
  SEND_CONTACTUS_FORM_START: 'SEND_CONTACTUS_FORM_START',
  SEND_CONTACTUS_FORM_SUCCESS: 'SEND_CONTACTUS_FORM_SUCCESS',
  SEND_CONTACTUS_FORM_FAILURE: 'SEND_CONTACTUS_FORM_FAILURE',

  // ----- // CONFIRM_EMAIL // ----- //
  CONFIRM_EMAIL_START: 'CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',

  // ----- // EXCHANGE_RATE // ----- //
  EXCHANGE_RATE_START: 'EXCHANGE_RATE_START',
  EXCHANGE_RATE_SUCCESS: 'EXCHANGE_RATE_SUCCESS',
  EXCHANGE_RATE_FAILURE: 'EXCHANGE_RATE_FAILURE',

  // ----- // EXCHANGE // ----- //
  EXCHANGE_START: 'EXCHANGE_START',
  EXCHANGE_SUCCESS: 'EXCHANGE_SUCCESS',
  EXCHANGE_FAILURE: 'EXCHANGE_FAILURE',

  // ----- // GET_EXCHANGE_HISTORY // ----- //
  GET_EXCHANGE_HISTORY_START: 'GET_EXCHANGE_HISTORY_START',
  GET_EXCHANGE_HISTORY_SUCCESS: 'GET_EXCHANGE_HISTORY_SUCCESS',
  GET_EXCHANGE_HISTORY_FAILURE: 'GET_EXCHANGE_HISTORY_FAILURE',

  // ----- // GET_TOP_PAIRS // ----- //
  GET_TOP_PAIRS_START: 'GET_TOP_PAIRS_START',
  GET_TOP_PAIRS_SUCCESS: 'GET_TOP_PAIRS_SUCCESS',
  GET_TOP_PAIRS_FAILURE: 'GET_TOP_PAIRS_FAILURE',

  /**
   * USERS
   */

  /**
   * TOKENS
   */

  // ----- // GET_TOKENS // ----- //
  GET_TOKENS_START: 'GET_TOKENS_START',
  GET_TOKENS_SUCCESS: 'GET_TOKENS_SUCCESS',
  GET_TOKENS_FAILURE: 'GET_TOKENS_FAILURE',

  // ----- // CREATE_TOKENS // ----- //
  CREATE_TOKENS_START: 'CREATE_TOKENS_START',
  CREATE_TOKENS_SUCCESS: 'CREATE_TOKENS_SUCCESS',
  CREATE_TOKENS_FAILURE: 'CREATE_TOKENS_FAILURE',

  // ----- // DISABLE_TOKENS // ----- //
  DISABLE_TOKENS_START: 'DISABLE_TOKENS_START',
  DISABLE_TOKENS_SUCCESS: 'DISABLE_TOKENS_SUCCESS',
  DISABLE_TOKENS_FAILURE: 'DISABLE_TOKENS_FAILURE',

  // ----- // DELETE_TOKENS // ----- //
  DELETE_TOKENS_START: 'DELETE_TOKENS_START',
  DELETE_TOKENS_SUCCESS: 'DELETE_TOKENS_SUCCESS',
  DELETE_TOKENS_FAILURE: 'DELETE_TOKENS_FAILURE',

  // ----- // RESET_PASSWORD // ----- //
  USERS_RESET_PASSWORD_START: 'USERS_RESET_PASSWORD_START',
  USERS_RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
  USERS_RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

  // ----- // GET_USER_DATA // ----- //
  GET_USER_DATA_START: 'GET_USER_DATA_START',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

  // ----- // GET_USER_SECURITY_DATA // ----- //
  GET_USER_SECURITY_DATA_START: 'GET_USER_SECURITY_DATA_START',
  GET_USER_SECURITY_DATA_SUCCESS: 'GET_USER_SECURITY_DATA_SUCCESS',
  GET_USER_SECURITY_DATA_FAILURE: 'GET_USER_SECURITY_DATA_FAILURE',

  // ----- // UPDATE_USER_DATA // ----- //
  UPDATE_USER_DATA_START: 'UPDATE_USER_DATA_START',
  UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE: 'UPDATE_USER_DATA_FAILURE',

  // ----- // UPDATE_DOCUMENTS // ----- //
  UPDATE_DOCUMENTS_START: 'UPDATE_DOCUMENTS_START',
  UPDATE_DOCUMENTS_SUCCESS: 'UPDATE_DOCUMENTS_SUCCESS',
  UPDATE_DOCUMENTS_FAILURE: 'UPDATE_DOCUMENTS_FAILURE',

  // ----- // DELETE_DOCUMENTS // ----- //
  DELETE_DOCUMENTS_START: 'DELETE_DOCUMENTS_START',
  DELETE_DOCUMENTS_SUCCESS: 'DELETE_DOCUMENTS_SUCCESS',
  DELETE_DOCUMENTS_FAILURE: 'DELETE_DOCUMENTS_FAILURE',

  // ----- // DASHBOARD // ----- //
  GET_DASHBOARD_START: 'GET_DASHBOARD_START',
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

  // ----- // GET_IDENTIFICATION // ----- //
  GET_IDENTIFICATION_START: 'GET_IDENTIFICATION_START',
  GET_IDENTIFICATION_SUCCESS: 'GET_IDENTIFICATION_SUCCESS',
  GET_IDENTIFICATION_FAILURE: 'GET_IDENTIFICATION_FAILURE',

  // ----- // RESET 2FA // ----- //
  RESET_2FA_START: 'RESET_2FA_START',
  RESET_2FA_SUCCESS: 'RESET_2FA_SUCCESS',
  RESET_2FA_FAILURE: 'RESET_2FA_FAILURE',

  // ----- // UPLOAD SELFI FOR RESET 2FA // ----- //
  UPLOAD_SELFI_RESET_2FA_START: 'UPLOAD_SELFI_RESET_2FA_START',
  UPLOAD_SELFI_RESET_2FA_SUCCESS: 'UPLOAD_SELFI_RESET_2FA_SUCCESS',
  UPLOAD_SELFI_RESET_2FA_FAILURE: 'UPLOAD_SELFI_RESET_2FA_FAILURE',

  // ----- // ENABLE GOOGLE 2FA // ----- //
  ENABLE_GOOGLE_2FA_START: 'ENABLE_GOOGLE_2FA_START',
  ENABLE_GOOGLE_2FA_SUCCESS: 'ENABLE_GOOGLE_2FA_SUCCESS',
  ENABLE_GOOGLE_2FA_FAILURE: 'ENABLE_GOOGLE_2FA_FAILURE',

  // ----- // DISABLE GOOGLE 2FA // ----- //
  DISABLE_GOOGLE_2FA_START: 'DISABLE_GOOGLE_2FA_START',
  DISABLE_GOOGLE_2FA_SUCCESS: 'DISABLE_GOOGLE_2FA_SUCCESS',
  DISABLE_GOOGLE_2FA_FAILURE: 'DISABLE_GOOGLE_2FA_FAILURE',

  // ----- // GET SECRET KEY // ----- //
  GET_SECRET_KEY_START: 'GET_SECRET_KEY_START',
  GET_SECRET_KEY_SUCCESS: 'GET_SECRET_KEY_SUCCESS',
  GET_SECRET_KEY_FAILURE: 'GET_SECRET_KEY_FAILURE',

  // ----- // VERIFY_USER_IP // ----- //
  VERIFY_USER_IP_START: 'VERIFY_USER_IP_START',
  VERIFY_USER_IP_SUCCESS: 'VERIFY_USER_IP_SUCCESS',
  VERIFY_USER_IP_FAILURE: 'VERIFY_USER_IP_FAILURE',

  // ----- // VERIFY_USER_KYC // ----- //
  VERIFY_USER_KYC_START: 'VERIFY_USER_KYC_START',
  VERIFY_USER_KYC_SUCCESS: 'VERIFY_USER_KYC_SUCCESS',
  VERIFY_USER_KYC_FAILURE: 'VERIFY_USER_KYC_FAILURE',

  // ----- // GET_AUTH_KYC_TOKEN // ----- //
  GET_AUTH_KYC_TOKEN_START: 'GET_AUTH_KYC_TOKEN_START',
  GET_AUTH_KYC_TOKEN_SUCCESS: 'GET_AUTH_KYC_TOKEN_SUCCESS',
  GET_AUTH_KYC_TOKEN_FAILURE: 'GET_AUTH_KYC_TOKEN_FAILURE',

  // ----- // GET_VERIFY_USER_KYC_STATUS // ----- //
  GET_VERIFY_USER_KYC_STATUS_START: 'GET_VERIFY_USER_KYC_STATUS_START',
  GET_VERIFY_USER_KYC_STATUS_SUCCESS: 'GET_VERIFY_USER_KYC_STATUS_SUCCESS',
  GET_VERIFY_USER_KYC_STATUS_FAILURE: 'GET_VERIFY_USER_KYC_STATUS_FAILURE',

  // ----- // GET_KYC_VERIFICATIONS // ----- //
  GET_KYC_VERIFICATIONS_START: 'GET_KYC_VERIFICATIONS_START',
  GET_KYC_VERIFICATIONS_SUCCESS: 'GET_KYC_VERIFICATIONS_SUCCESS',
  GET_KYC_VERIFICATIONS_FAILURE: 'GET_KYC_VERIFICATIONS_FAILURE',

  // ----- // UPDATE_KYC_VERIFICATIONS // ----- //
  UPDATE_KYC_VERIFICATIONS_START: 'UPDATE_KYC_VERIFICATIONS_START',
  UPDATE_KYC_VERIFICATIONS_SUCCESS: 'UPDATE_KYC_VERIFICATIONS_SUCCESS',
  UPDATE_KYC_VERIFICATIONS_FAILURE: 'UPDATE_KYC_VERIFICATIONS_FAILURE',

  // ----- // RECENT_CODE // ----- //
  RECENT_CODE_START: 'RECENT_CODE_START',
  RECENT_CODE_SUCCESS: 'RECENT_CODE_SUCCESS',
  RECENT_CODE_FAILURE: 'RECENT_CODE_FAILURE',

  // ----- // UPDATE_TOKEN_DISKOUNT // ----- //
  UPDATE_TOKEN_DISKOUNT_START: 'UPDATE_TOKEN_DISKOUNT_START',
  UPDATE_TOKEN_DISKOUNT_SUCCESS: 'UPDATE_TOKEN_DISKOUNT_SUCCESS',
  UPDATE_TOKEN_DISKOUNT_FAILURE: 'UPDATE_TOKEN_DISKOUNT_FAILURE',

  // ----- // GET_TOKEN_DISKOUNT // ----- //
  GET_TOKEN_DISKOUNT_START: 'GET_TOKEN_DISKOUNT_START',
  GET_TOKEN_DISKOUNT_SUCCESS: 'GET_TOKEN_DISKOUNT_SUCCESS',
  GET_TOKEN_DISKOUNT_FAILURE: 'GET_TOKEN_DISKOUNT_FAILURE',

  // ----- // GET_VACANCY // ----- //
  GET_VACANCY_START: 'GET_VACANCY_START',
  GET_VACANCY_SUCCESS: 'GET_VACANCY_SUCCESS',
  GET_VACANCY_FAILURE: 'GET_VACANCY_FAILURE',

  /**
   * CASHBACK
   */
  // ----- // GET_AVAILABLE_CASHBACK // ----- //
  GET_AVAILABLE_CASHBACK_START: 'GET_AVAILABLE_CASHBACK_START',
  GET_AVAILABLE_CASHBACK_SUCCESS: 'GET_AVAILABLE_CASHBACK_SUCCESS',
  GET_AVAILABLE_CASHBACK_FAILURE: 'GET_AVAILABLE_CASHBACK_FAILURE',

  // ----- // GET_USER_CASHBACK // ----- //
  GET_USER_CASHBACK_START: 'GET_USER_CASHBACK_START',
  GET_USER_CASHBACK_SUCCESS: 'GET_USER_CASHBACK_SUCCESS',
  GET_USER_CASHBACK_FAILURE: 'GET_USER_CASHBACK_FAILURE',

  // ----- // UPDATTE_USER_CASHBACK_RENEWAL // ----- //
  UPDATTE_USER_CASHBACK_RENEWAL_START: 'UPDATTE_USER_CASHBACK_RENEWAL_START',
  UPDATTE_USER_CASHBACK_RENEWAL_SUCCESS:
    'UPDATTE_USER_CASHBACK_RENEWAL_SUCCESS',
  UPDATTE_USER_CASHBACK_RENEWAL_FAILURE:
    'UPDATTE_USER_CASHBACK_RENEWAL_FAILURE',

  // ----- // POST_BUY_CASHBACK // ----- //
  POST_BUY_CASHBACK_START: 'POST_BUY_CASHBACK_START',
  POST_BUY_CASHBACK_SUCCESS: 'POST_BUY_CASHBACK_SUCCESS',
  POST_BUY_CASHBACK_FAILURE: 'POST_BUY_CASHBACK_FAILURE',

  // ----- // GET_USER_TRADE_VOLUME_STATISTICS // ----- //
  GET_USER_TRADE_VOLUME_STATISTICS_START:
    'GET_USER_TRADE_VOLUME_STATISTICS_START',
  GET_USER_TRADE_VOLUME_STATISTICS_SUCCESS:
    'GET_USER_TRADE_VOLUME_STATISTICS_SUCCESS',
  GET_USER_TRADE_VOLUME_STATISTICS_FAILURE:
    'GET_USER_TRADE_VOLUME_STATISTICS_FAILURE',

  // ----- // GET_TRADING_VOLUME_LEVELS // ----- //
  GET_TRADING_VOLUME_LEVELS_START: 'GET_TRADING_VOLUME_LEVELS_START',
  GET_TRADING_VOLUME_LEVELS_SUCCESS: 'GET_TRADING_VOLUME_LEVELS_SUCCESS',
  GET_TRADING_VOLUME_LEVELS_FAILURE: 'GET_TRADING_VOLUME_LEVELS_FAILURE',

  // ----- // GET_CURRENT_CASHBACK_RATE // ----- //
  GET_CURRENT_CASHBACK_RATE_START: 'GET_CURRENT_CASHBACK_RATE_START',
  GET_CURRENT_CASHBACK_RATE_SUCCESS: 'GET_CURRENT_CASHBACK_RATE_SUCCESS',
  GET_CURRENT_CASHBACK_RATE_FAILURE: 'GET_CURRENT_CASHBACK_RATE_FAILURE',

  // ----- // GET_CASHBACKS_BY_TRADES // ----- //
  GET_CASHBACKS_BY_TRADES_START: 'GET_CASHBACKS_BY_TRADES_START',
  GET_CASHBACKS_BY_TRADES_SUCCESS: 'GET_CASHBACKS_BY_TRADES_SUCCESS',
  GET_CASHBACKS_BY_TRADES_FAILURE: 'GET_CASHBACKS_BY_TRADES_FAILURE',

  /**
   * LIMITS_AND_FEES
   */
  // ----- // GET_LIMITS // ----- //
  GET_LIMITS_START: 'GET_LIMITS_START',
  GET_LIMITS_SUCCESS: 'GET_LIMITS_SUCCESS',
  GET_LIMITS_FAILURE: 'GET_LIMITS_FAILURE',
  // ----- // GET_FEES_PER_INPUT_AND_OUTPUT // ----- //
  GET_FEES_PER_INPUT_AND_OUTPUT_START: 'GET_FEES_PER_INPUT_AND_OUTPUT_START',
  GET_FEES_PER_INPUT_AND_OUTPUT_SUCCESS:
    'GET_FEES_PER_INPUT_AND_OUTPUT_SUCCESS',
  GET_FEES_PER_INPUT_AND_OUTPUT_FAILURE:
    'GET_FEES_PER_INPUT_AND_OUTPUT_FAILURE',

  /**
   * SPOT TRADE
   */
  // ----- // GET_ORDERS_LIST // ----- //
  SPOT_GET_ORDERS_LIST_START: 'SPOT_GET_ORDERS_LIST_START',
  SPOT_GET_ORDERS_LIST_SUCCESS: 'SPOT_GET_ORDERS_LIST_SUCCESS',
  SPOT_GET_ORDERS_LIST_FAILURE: 'SPOT_GET_ORDERS_LIST_FAILURE',
  UPDATE_SPOT_OPEN_ORDERS: 'UPDATE_SPOT_OPEN_ORDERS',

  // ----- // CANCEL_OPEN_ORDER // ----- //
  SPOT_CANCEL_OPEN_ORDER_START: 'SPOT_CANCEL_OPEN_ORDER_START',
  SPOT_CANCEL_OPEN_ORDER_SUCCESS: 'SPOT_CANCEL_OPEN_ORDER_SUCCESS',
  SPOT_CANCEL_OPEN_ORDER_FAILURE: 'SPOT_CANCEL_OPEN_ORDER_FAILURE',

  // ----- // CANCEL_ALL_ORDERS // ----- //
  SPOT_CANCEL_ALL_ORDERS_START: 'SPOT_CANCEL_ALL_ORDERS_START',
  SPOT_CANCEL_ALL_ORDERS_SUCCESS: 'SPOT_CANCEL_ALL_ORDES_SUCCESS',
  SPOT_CANCEL_ALL_ORDERS_FAILURE: 'SPOT_CANCEL_ALL_ORDERS_FAILURE',

  // ----- // CANCEL_ALL_ASSETS_ORDERS // ----- //
  SPOT_CANCEL_ALL_ASSETS_ORDERS_START: 'SPOT_CANCEL_ALL_ASSETS_ORDERS_START',
  SPOT_CANCEL_ALL_ASSETS_ORDERS_SUCCESS:
    'SPOT_CANCEL_ALL_ASSETS_ORDERS_SUCCESS',
  SPOT_CANCEL_ALL_ASSETS_ORDERS_FAILURE:
    'SPOT_CANCEL_ALL_ASSETS_ORDERS_FAILURE',

  // ----- // GET_ORDERS_HISTORY // ----- //
  SPOT_GET_ORDERS_HISTORY_START: 'SPOT_GET_ORDERS_HISTORY_START',
  SPOT_GET_ORDERS_HISTORY_SUCCESS: 'SPOT_GET_ORDERS_HISTORY_SUCCESS',
  SPOT_GET_ORDERS_HISTORY_FAILURE: 'SPOT_GET_ORDERS_HISTORY_FAILURE',
  UPDATE_SPOT_CLOSED_ORDERS: 'UPDATE_SPOT_CLOSED_ORDERS',

  // ----- // GET_TRADE_HISTORY // ----- //
  SPOT_GET_TRADE_HISTORY_START: 'SPOT_GET_TRADE_HISTORY_START',
  SPOT_GET_TRADE_HISTORY_SUCCESS: 'SPOT_GET_TRADE_HISTORY_SUCCESS',
  SPOT_GET_TRADE_HISTORY_FAILURE: 'SPOT_GET_TRADE_HISTORY_FAILURE',

  // ----- // GET_ORDER_BOOK // ----- //
  SPOT_GET_ORDER_BOOK_START: 'SPOT_GET_ORDER_BOOK_START',
  SPOT_GET_ORDER_BOOK_SUCCESS: 'SPOT_GET_ORDER_BOOK_SUCCESS',
  SPOT_GET_ORDER_BOOK_FAILURE: 'SPOT_GET_ORDER_BOOK_FAILURE',
  UPDATE_SPOT_ORDER_BOOK: 'UPDATE_SPOT_ORDER_BOOK',

  // ----- // GET_RECENT_TRADES // ----- //
  SPOT_GET_RECENT_TRADES_START: 'SPOT_GET_RECENT_TRADES_START',
  SPOT_GET_RECENT_TRADES_SUCCESS: 'SPOT_GET_RECENT_TRADES_SUCCESS',
  SPOT_GET_RECENT_TRADES_FAILURE: 'SPOT_GET_RECENT_TRADES_FAILURE',
  UPDATE_SPOT_RECENT_TRADES: 'UPDATE_SPOT_RECENT_TRADES',

  /**
   * MARGIN TRADE
   */
  // ----- // GET_ORDERS_LIST // ----- //
  MARGIN_GET_ORDERS_LIST_START: 'MARGIN_GET_ORDERS_LIST_START',
  MARGIN_GET_ORDERS_LIST_SUCCESS: 'MARGIN_GET_ORDERS_LIST_SUCCESS',
  MARGIN_GET_ORDERS_LIST_FAILURE: 'MARGIN_GET_ORDERS_LIST_FAILURE',
  UPDATE_MARGIN_OPEN_ORDERS: 'UPDATE_MARGIN_OPEN_ORDERS',

  // ----- // CANCEL_OPEN_ORDER // ----- //
  MARGIN_CANCEL_OPEN_ORDER_START: 'MARGIN_CANCEL_OPEN_ORDER_START',
  MARGIN_CANCEL_OPEN_ORDER_SUCCESS: 'MARGIN_CANCEL_OPEN_ORDER_SUCCESS',
  MARGIN_CANCEL_OPEN_ORDER_FAILURE: 'MARGIN_CANCEL_OPEN_ORDER_FAILURE',

  // ----- // CANCEL_ALL_ORDERS // ----- //
  MARGIN_CANCEL_ALL_ORDERS_START: 'MARGIN_CANCEL_ALL_ORDERS_START',
  MARGIN_CANCEL_ALL_ORDERS_SUCCESS: 'MARGIN_CANCEL_ALL_ORDES_SUCCESS',
  MARGIN_CANCEL_ALL_ORDERS_FAILURE: 'MARGIN_CANCEL_ALL_ORDERS_FAILURE',

  // ----- // GET_ORDERS_HISTORY // ----- //
  MARGIN_GET_ORDERS_HISTORY_START: 'MARGIN_GET_ORDERS_HISTORY_START',
  MARGIN_GET_ORDERS_HISTORY_SUCCESS: 'MARGIN_GET_ORDERS_HISTORY_SUCCESS',
  MARGIN_GET_ORDERS_HISTORY_FAILURE: 'MARGIN_GET_ORDERS_HISTORY_FAILURE',
  UPDATE_MARGIN_CLOSED_ORDERS: 'UPDATE_MARGIN_CLOSED_ORDERS',

  // ----- // GET_TRADE_HISTORY // ----- //
  MARGIN_GET_TRADE_HISTORY_START: 'MARGIN_GET_TRADE_HISTORY_START',
  MARGIN_GET_TRADE_HISTORY_SUCCESS: 'MARGIN_GET_TRADE_HISTORY_SUCCESS',
  MARGIN_GET_TRADE_HISTORY_FAILURE: 'MARGIN_GET_TRADE_HISTORY_FAILURE',

  // ----- // GET_LOW_RISK // ----- //
  MARGIN_GET_LOW_RISK_START: 'MARGIN_GET_LOW_RISK_START',
  MARGIN_GET_LOW_RISK_SUCCESS: 'MARGIN_GET_LOW_RISK_SUCCESS',
  MARGIN_GET_LOW_RISK_FAILURE: 'MARGIN_GET_LOW_RISK_FAILURE',

  // ----- // GET_BALANCE // ----- //
  MARGIN_GET_BALANCE_START: 'MARGIN_GET_BALANCE_START',
  MARGIN_GET_BALANCE_SUCCESS: 'MARGIN_GET_BALANCE_SUCCESS',
  MARGIN_GET_BALANCE_FAILURE: 'MARGIN_GET_BALANCE_FAILURE',

  // ----- // GET_BORROWS // ----- //
  MARGIN_GET_BORROWS_START: 'MARGIN_GET_BORROWS_START',
  MARGIN_GET_BORROWS_SUCCESS: 'MARGIN_GET_BORROWS_SUCCESS',
  MARGIN_GET_BORROWS_FAILURE: 'MARGIN_GET_BORROWS_FAILURE',

  // ----- // GET_SINGLE_BORROW // ----- //
  MARGIN_GET_SINGLE_BORROW_START: 'MARGIN_GET_SINGLE_BORROW_START',
  MARGIN_GET_SINGLE_BORROW_SUCCESS: 'MARGIN_GET_SINGLE_BORROW_SUCCESS',
  MARGIN_GET_SINGLE_BORROW_FAILURE: 'MARGIN_GET_SINGLE_BORROW_FAILURE',

  // ----- // GET_SINGLE_REPAY // ----- //
  MARGIN_GET_SINGLE_REPAY_START: 'MARGIN_GET_SINGLE_REPAY_START',
  MARGIN_GET_SINGLE_REPAY_SUCCESS: 'MARGIN_GET_SINGLE_REPAY_SUCCESS',
  MARGIN_GET_SINGLE_REPAY_FAILURE: 'MARGIN_GET_SINGLE_REPAY_FAILURE',

  // ----- // MARGIN_POST_REPAY // ----- //
  MARGIN_POST_REPAY_START: 'MARGIN_POST_REPAY_START',
  MARGIN_POST_REPAY_SUCCESS: 'MARGIN_POST_REPAY_SUCCESS',
  MARGIN_POST_REPAY_FAILURE: 'MARGIN_POST_REPAY_FAILURE',

  // ----- // MARGIN_POST_TRANSFORM // ----- //
  MARGIN_POST_TRANSFORM_START: 'MARGIN_POST_TRANSFORM_START',
  MARGIN_POST_TRANSFORM_SUCCESS: 'MARGIN_POST_TRANSFORM_SUCCESS',
  MARGIN_POST_TRANSFORM_FAILURE: 'MARGIN_POST_TRANSFORM_FAILURE',

  // ----- // MARGIN_POST_BORROW // ----- //
  MARGIN_POST_BORROW_START: 'MARGIN_POST_BORROW_START',
  MARGIN_POST_BORROW_SUCCESS: 'MARGIN_POST_BORROW_SUCCESS',
  MARGIN_POST_BORROW_FAILURE: 'MARGIN_POST_BORROW_FAILURE',

  // ----- // TEMPORARY_SELECTED_TRADE // ----- //
  TEMPORARY_SELECTED_TRADE: 'TEMPORARY_SELECTED_TRADE',

  // ----- // GET_WALLETS // ----- //
  GET_WALLETS_START: 'GET_WALLETS_START',
  GET_WALLETS_SUCCESS: 'GET_WALLETS_SUCCESS',
  GET_WALLETS_FAILURE: 'GET_WALLETS_FAILURE',
  UPDATE_WALLETS: 'UPDATE_WALLETS',

  // ----- // GET_WALLET // ----- //
  GET_WALLET_START: 'GET_WALLET_START',
  GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
  GET_WALLET_FAILURE: 'GET_WALLET_FAILURE',

  // ----- // GET_TRANSAK_LINK // ----- //
  GET_TRANSAK_LINK_START: 'GET_TRANSAK_LINK_START',
  GET_TRANSAK_LINK_SUCCESS: 'GET_TRANSAK_LINK_SUCCESS',
  GET_TRANSAK_LINK_FAILURE: 'GET_TRANSAK_LINK_FAILURE',

  // ----- // GET_WALLETS_TRANSACTIONS // ----- //
  GET_WALLETS_TRANSACTIONS_START: 'GET_WALLETS_TRANSACTIONS_START',
  GET_WALLETS_TRANSACTIONS_SUCCESS: 'GET_WALLETS_TRANSACTIONS_SUCCESS',
  GET_WALLETS_TRANSACTIONS_FAILURE: 'GET_WALLETS_TRANSACTIONS_FAILURE',

  // ----- // GET_FIAT_TRANSACTIONS // ----- //
  GET_FIAT_TRANSACTIONS_START: 'GET_FIAT_TRANSACTIONS_START',
  GET_FIAT_TRANSACTIONS_SUCCESS: 'GET_FIAT_TRANSACTIONS_SUCCESS',
  GET_FIAT_TRANSACTIONS_FAILURE: 'GET_FIAT_TRANSACTIONS_FAILURE',

  // ----- // GET_WALLETS_TRANSFER // ----- //
  GET_WALLETS_TRANSFER_START: 'GET_WALLETS_TRANSFER_START',
  GET_WALLETS_TRANSFER_SUCCESS: 'GET_WALLETS_TRANSFER_SUCCESS',
  GET_WALLETS_TRANSFER_FAILURE: 'GET_WALLETS_TRANSFER_FAILURE',

  // ----- // GET_WALLETS_DEPOSITS // ----- //
  GET_WALLETS_DEPOSITS_START: 'GET_WALLETS_DEPOSITS_START',
  GET_WALLETS_DEPOSITS_SUCCESS: 'GET_WALLETS_DEPOSITS_SUCCESS',
  GET_WALLETS_DEPOSITS_FAILURE: 'GET_WALLETS_DEPOSITS_FAILURE',

  // ----- // GET_WALLETS_WITHDRAWALS // ----- //
  GET_WALLETS_WITHDRAWALS_START: 'GET_WALLETS_WITHDRAWALS_START',
  GET_WALLETS_WITHDRAWALS_SUCCESS: 'GET_WALLETS_WITHDRAWALS_SUCCESS',
  GET_WALLETS_WITHDRAWALS_FAILURE: 'GET_WALLETS_WITHDRAWALS_FAILURE',

  // ----- // POST_GENERATE_ADRESS_WALLETS // ----- //
  POST_GENERATE_ADRESS_WALLETS_START: 'POST_GENERATE_ADRESS_WALLETS_START',
  POST_GENERATE_ADRESS_WALLETS_SUCCESS: 'POST_GENERATE_ADRESS_WALLETS_SUCCESS',
  POST_GENERATE_ADRESS_WALLETS_FAILURE: 'POST_GENERATE_ADRESS_WALLETS_FAILURE',

  // ----- // GET_PROFIT // ----- //
  GET_PROFIT_START: 'GET_PROFIT_START',
  GET_PROFIT_SUCCESS: 'GET_PROFIT_SUCCESS',
  GET_PROFIT_FAILURE: 'GET_PROFIT_FAILURE',

  // ----- // POST_CREATE_WITHDRAWAL_REQUEST // ----- //
  POST_CREATE_WITHDRAWAL_REQUEST_START: 'POST_CREATE_WITHDRAWAL_REQUEST_START',
  POST_CREATE_WITHDRAWAL_REQUEST_SUCCESS:
    'POST_CREATE_WITHDRAWAL_REQUEST_SUCCESS',
  POST_CREATE_WITHDRAWAL_REQUEST_FAILURE:
    'POST_CREATE_WITHDRAWAL_REQUEST_FAILURE',

  // ----- // POST_CREATE_BANK_DEPOSITE_REQUEST // ----- //
  // POST_CREATE_BANK_DEPOSITE_REQUEST_START:
  //   'POST_CREATE_BANK_DEPOSITE_REQUEST_START',
  // POST_CREATE_BANK_DEPOSITE_REQUEST_SUCCESS:
  //   'POST_CREATE_BANK_DEPOSITE_REQUEST_SUCCESS',
  // POST_CREATE_BANK_DEPOSITE_REQUEST_FAILURE:
  //   'POST_CREATE_BANK_DEPOSITE_REQUEST_FAILURE',

  // ----- // POST_PAYEER_DEPOSIT // ----- //
  POST_PAYEER_DEPOSIT_START: 'POST_PAYEER_DEPOSIT_START',
  POST_PAYEER_DEPOSIT_SUCCESS: 'POST_PAYEER_DEPOSIT_SUCCESS',
  POST_PAYEER_DEPOSIT_FAILURE: 'POST_PAYEER_DEPOSIT_FAILURE',

  // ----- // POST_PAYEER_WIDTHDRAW // ----- //
  POST_PAYEER_WIDTHDRAW_START: 'POST_PAYEER_WIDTHDRAW_START',
  POST_PAYEER_WIDTHDRAW_SUCCESS: 'POST_PAYEER_WIDTHDRAW_SUCCESS',
  POST_PAYEER_WIDTHDRAW_FAILURE: 'POST_PAYEER_WIDTHDRAW_FAILURE',

  // ----- // POST_PRMONEY_DEPOSIT // ----- //
  POST_PRMONEY_DEPOSIT_START: 'POST_PRMONEY_DEPOSIT_START',
  POST_PRMONEY_DEPOSIT_SUCCESS: 'POST_PRMONEY_DEPOSIT_SUCCESS',
  POST_PRMONEY_DEPOSIT_FAILURE: 'POST_PRMONEY_DEPOSIT_FAILURE',

  // ----- // POST_PRMONEY_WIDTHDRAW // ----- //
  // POST_PRMONEY_WIDTHDRAW_START: 'POST_PRMONEY_WIDTHDRAW_START',
  // POST_PRMONEY_WIDTHDRAW_SUCCESS: 'POST_PRMONEY_WIDTHDRAW_SUCCESS',
  // POST_PRMONEY_WIDTHDRAW_FAILURE: 'POST_PRMONEY_WIDTHDRAW_FAILURE',

  // ----- // DELETE_WITHDRAWAL_DEPOSIT // ----- //
  DELETE_WITHDRAWAL_DEPOSIT_START: 'DELETE_WITHDRAWAL_DEPOSIT_START',
  DELETE_WITHDRAWAL_DEPOSIT_SUCCESS: 'DELETE_WITHDRAWAL_DEPOSIT_SUCCESS',
  DELETE_WITHDRAWAL_DEPOSIT_FAILURE: 'DELETE_WITHDRAWAL_DEPOSIT_FAILURE',

  // ----- // POST_PALMIRA_DEPOSIT // ----- //
  POST_PALMIRA_DEPOSIT_START: 'POST_PALMIRA_DEPOSIT_START',
  POST_PALMIRA_DEPOSIT_SUCCESS: 'POST_PALMIRA_DEPOSIT_SUCCESS',
  POST_PALMIRA_DEPOSIT_FAILURE: 'POST_PALMIRA_DEPOSIT_FAILURE',

  // ----- // POST_PERFECTMONEY_DEPOSIT // ----- //
  POST_PERFECTMONEY_DEPOSIT_START: 'POST_PERFECTMONEY_DEPOSIT_START',
  POST_PERFECTMONEY_DEPOSIT_SUCCESS: 'POST_PERFECTMONEY_DEPOSIT_SUCCESS',
  POST_PERFECTMONEY_DEPOSIT_FAILURE: 'POST_PERFECTMONEY_DEPOSIT_FAILURE',

  // ----- // POST_PERFECTMONEY_WITHDRAW // ----- //
  POST_PERFECTMONEY_WITHDRAW_START: 'POST_PERFECTMONEY_WITHDRAW_START',
  POST_PERFECTMONEY_WITHDRAW_SUCCESS: 'POST_PERFECTMONEY_WITHDRAW_SUCCESS',
  POST_PERFECTMONEY_WITHDRAW_FAILURE: 'POST_PERFECTMONEY_WITHDRAW_FAILURE',

  // ----- // POST_SETTLEPAY_WITHDRAW // ----- //
  POST_SETTLEPAY_WITHDRAW_START: 'POST_SETTLEPAY_WITHDRAW_START',
  POST_SETTLEPAY_WITHDRAW_SUCCESS: 'POST_SETTLEPAY_WITHDRAW_SUCCESS',
  POST_SETTLEPAY_WITHDRAW_FAILURE: 'POST_SETTLEPAY_WITHDRAW_FAILURE',

  // ----- // POST_PAYPAL_DEPOSIT // ----- //
  POST_PAYPAL_DEPOSIT_START: 'POST_PAYPAL_DEPOSIT_START',
  POST_PAYPAL_DEPOSIT_SUCCESS: 'POST_PAYPAL_DEPOSIT_SUCCESS',
  POST_PAYPAL_DEPOSIT_FAILURE: 'POST_PAYPAL_DEPOSIT_FAILURE',

  // ----- // POST_PAYPAL_DEPOSIT_APPROVE // ----- //
  POST_PAYPAL_DEPOSIT_APPROVE_START: 'POST_PAYPAL_DEPOSIT_APPROVE_START',
  POST_PAYPAL_DEPOSIT_APPROVE_SUCCESS: 'POST_PAYPAL_DEPOSIT_APPROVE_SUCCESS',
  POST_PAYPAL_DEPOSIT_APPROVE_FAILURE: 'POST_PAYPAL_DEPOSIT_APPROVE_FAILURE',

  // ----- // POST_PAYPAL_WITHDRAW // ----- //
  POST_PAYPAL_WITHDRAW_START: 'POST_PAYPAL_WITHDRAW_START',
  POST_PAYPAL_WITHDRAW_SUCCESS: 'POST_PAYPAL_WITHDRAW_SUCCESS',
  POST_PAYPAL_WITHDRAW_FAILURE: 'POST_PAYPAL_WITHDRAW_FAILURE',

  // ----- // GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES // ----- //
  GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_START:
    'GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_START',
  GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_SUCCESS:
    'GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_SUCCESS',
  GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_FAILURE:
    'GET_PAYPAL_WITHDRAW_RECIPIENT_TYPES_FAILURE',

  // ----- // POST_TRUST_DEPOSIT // ----- //
  POST_TRUST_DEPOSIT_START: 'POST_TRUST_DEPOSIT_START',
  POST_TRUST_DEPOSIT_SUCCESS: 'POST_TRUST_DEPOSIT_SUCCESS',
  POST_TRUST_DEPOSIT_FAILURE: 'POST_TRUST_DEPOSIT_FAILURE',

  // ----- // POST_SETTLEPAY_DEPOSIT // ----- //
  POST_SETTLEPAY_DEPOSIT_START: 'POST_SETTLEPAY_DEPOSIT_START',
  POST_SETTLEPAY_DEPOSIT_SUCCESS: 'POST_SETTLEPAY_DEPOSIT_SUCCESS',
  POST_SETTLEPAY_DEPOSIT_FAILURE: 'POST_SETTLEPAY_DEPOSIT_FAILURE',

  // ----- // CREATE_ORDER // ----- //
  CREATE_ORDER_START: 'CREATE_ORDER_START',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',

  // ----- // CREATE_SPOT_LIMIT_ORDER // ----- //
  CREATE_STOP_LIMIT_ORDER_START: 'CREATE_STOP_LIMIT_ORDER_START',
  CREATE_STOP_LIMIT_ORDER_SUCCESS: 'CREATE_STOP_LIMIT_ORDER_SUCCESS',
  CREATE_STOP_LIMIT_ORDER_FAILURE: 'CREATE_STOP_LIMIT_ORDER_FAILURE',

  // ----- // POST_CALCULATE_MARKET_ORDER // ----- //
  POST_CALCULATE_MARKET_ORDER_START: 'POST_CALCULATE_MARKET_ORDER_START',
  POST_CALCULATE_MARKET_ORDER_SUCCESS: 'POST_CALCULATE_MARKET_ORDER_SUCCESS',
  POST_CALCULATE_MARKET_ORDER_FAILURE: 'POST_CALCULATE_MARKET_ORDER_FAILURE',
  POST_PUBLIC_CALCULATE_MARKET_ORDER_START:
    'POST_PUBLIC_CALCULATE_MARKET_ORDER_START',
  POST_PUBLIC_CALCULATE_MARKET_ORDER_SUCCESS:
    'POST_PUBLIC_CALCULATE_MARKET_ORDER_SUCCESS',
  POST_PUBLIC_CALCULATE_MARKET_ORDER_FAILURE:
    'POST_PUBLIC_CALCULATE_MARKET_ORDER_FAILURE',
  CALCULATE_MARKET_ORDER_CLEAR: 'CALCULATE_MARKET_ORDER_CLEAR',

  // ----- // POST_CALCULATE_LIMIT_ORDER // ----- //
  POST_CALCULATE_LIMIT_ORDER_START: 'POST_CALCULATE_LIMIT_ORDER_START',
  POST_CALCULATE_LIMIT_ORDER_SUCCESS: 'POST_CALCULATE_LIMIT_ORDER_SUCCESS',
  POST_CALCULATE_LIMIT_ORDER_FAILURE: 'POST_CALCULATE_LIMIT_ORDER_FAILURE',
  POST_PUBLIC_CALCULATE_LIMIT_ORDER_START:
    'POST_PUBLIC_CALCULATE_LIMIT_ORDER_START',
  POST_PUBLIC_CALCULATE_LIMIT_ORDER_SUCCESS:
    'POST_PUBLIC_CALCULATE_LIMIT_ORDER_SUCCESS',
  POST_PUBLIC_CALCULATE_LIMIT_ORDER_FAILURE:
    'POST_PUBLIC_CALCULATE_LIMIT_ORDER_FAILURE',
  CALCULATE_LIMIT_ORDER_CLEAR: 'CALCULATE_LIMIT_ORDER_CLEAR',
  /**
   * FUTURES TRADE
   */
  // ----- // GET_OPENED_ORDERS // ----- //
  FUTURES_GET_OPENED_ORDERS_START: 'FUTURES_GET_OPENED_ORDERS_START',
  FUTURES_GET_OPENED_ORDERS_SUCCESS: 'FUTURES_GET_OPENED_ORDERS_SUCCESS',
  FUTURES_GET_OPENED_ORDERS_FAILURE: 'FUTURES_GET_OPENED_ORDERS_FAILURE',
  UPDATE_FUTURES_ORDERS: 'UPDATE_FUTURES_ORDERS', // for OPENED_ORDERS and ORDERS_HISTORY

  // ----- // GET_OPENED_POSITIONS // ----- //
  FUTURES_GET_OPENED_POSITIONS_START: 'FUTURES_GET_OPENED_POSITIONS_START',
  FUTURES_GET_OPENED_POSITIONS_SUCCESS: 'FUTURES_GET_OPENED_POSITIONS_SUCCESS',
  FUTURES_GET_OPENED_POSITIONS_FAILURE: 'FUTURES_GET_OPENED_POSITIONS_FAILURE',
  UPDATE_FUTURES_OPENED_POSITIONS: 'UPDATE_FUTURES_OPENED_POSITIONS',

  // ----- // GET_ORDERS_HISTORY // ----- //
  FUTURES_GET_ORDERS_HISTORY_START: 'FUTURES_GET_ORDERS_HISTORY_START',
  FUTURES_GET_ORDERS_HISTORY_SUCCESS: 'FUTURES_GET_ORDERS_HISTORY_SUCCESS',
  FUTURES_GET_ORDERS_HISTORY_FAILURE: 'FUTURES_GET_ORDERS_HISTORY_FAILURE',

  // ----- // GET_FILLS // ----- //
  FUTURES_GET_FILLS_START: 'FUTURES_GET_FILLS_START',
  FUTURES_GET_FILLS_SUCCESS: 'FUTURES_GET_FILLS_SUCCESS',
  FUTURES_GET_FILLS_FAILURE: 'FUTURES_GET_FILLS_FAILURE',
  UPDATE_FUTURES_FILLS: 'UPDATE_FUTURES_FILLS', // FOR WEBSOCKETS, NOT USED YET

  // ----- // GET_RECENT_TRADES // ----- //
  FUTURES_GET_RECENT_TRADES_START: 'FUTURES_GET_RECENT_TRADES_START',
  FUTURES_GET_RECENT_TRADES_SUCCESS: 'FUTURES_GET_RECENT_TRADES_SUCCESS',
  FUTURES_GET_RECENT_TRADES_FAILURE: 'FUTURES_GET_RECENT_TRADES_FAILURE',
  UPDATE_FUTURES_RECENT_TRADES: 'UPDATE_FUTURES_RECENT_TRADES',

  // ----- // GET_ORDER_BOOK // ----- //
  FUTURES_GET_ORDER_BOOK_START: 'FUTURES_GET_ORDER_BOOK_START',
  FUTURES_GET_ORDER_BOOK_SUCCESS: 'FUTURES_GET_ORDER_BOOK_SUCCESS',
  FUTURES_GET_ORDER_BOOK_FAILURE: 'FUTURES_GET_ORDER_BOOK_FAILURE',
  UPDATE_FUTURES_ORDER_BOOK: 'UPDATE_FUTURES_ORDER_BOOK',

  // ----- // GET_CONTRACTS_LIST // ----- //
  FUTURES_GET_CONTRACTS_LIST_START: 'FUTURES_GET_CONTRACTS_LIST_START',
  FUTURES_GET_CONTRACTS_LIST_SUCCESS: 'FUTURES_GET_CONTRACTS_LIST_SUCCESS',
  FUTURES_GET_CONTRACTS_LIST_FAILURE: 'FUTURES_GET_CONTRACTS_LIST_FAILURE',
  UPDATE_FUTURES_CONTRACTS: 'UPDATE_FUTURES_CONTRACTS',

  // ----- // CANCEL_ORDER // ----- //
  FUTURES_CANCEL_ORDER_START: 'FUTURES_CANCEL_ORDER_START',
  FUTURES_CANCEL_ORDER_SUCCESS: 'FUTURES_CANCEL_ORDER_SUCCESS',
  FUTURES_CANCEL_ORDER_FAILURE: 'FUTURES_CANCEL_ORDER_FAILURE',

  // ----- // CANCEL_ALL_ORDERS // ----- //
  FUTURES_CANCEL_ALL_ORDERS_START: 'FUTURES_CANCEL_ALL_ORDERS_START',
  FUTURES_CANCEL_ALL_ORDERS_SUCCESS: 'FUTURES_CANCEL_ALL_ORDERS_SUCCESS',
  FUTURES_CANCEL_ALL_ORDERS_FAILURE: 'FUTURES_CANCEL_ALL_ORDERS_FAILURE',

  // ----- // FUTURES_SELECT_CONTRACT // ----- //
  FUTURES_SELECT_CONTRACT: 'FUTURES_SELECT_CONTRACT',

  // ----- // FUTURES_CREATE_ORDER // ----- //
  FUTURES_CREATE_ORDER_START: 'FUTURES_CREATE_ORDER_START',
  FUTURES_CREATE_ORDER_SUCCESS: 'FUTURES_CREATE_ORDER_SUCCESS',
  FUTURES_CREATE_ORDER_FAILURE: 'FUTURES_CREATE_ORDER_FAILURE',

  // ----- // GET_USER_BALANCE // ----- //
  FUTURES_GET_USER_BALANCE_START: 'FUTURES_GET_USER_BALANCE_START',
  FUTURES_GET_USER_BALANCE_SUCCESS: 'FUTURES_GET_USER_BALANCE_SUCCESS',
  FUTURES_GET_USER_BALANCE_FAILURE: 'FUTURES_GET_USER_BALANCE_FAILURE',
  UPDATE_FUTURES_USER_BALANCE: 'UPDATE_FUTURES_USER_BALANCE',

  // ----- // FUTURES_ADJUST_LEVERAGE // ----- //
  FUTURES_ADJUST_LEVERAGE_START: 'FUTURES_ADJUST_LEVERAGE_START',
  FUTURES_ADJUST_LEVERAGE_SUCCESS: 'FUTURES_ADJUST_LEVERAGE_SUCCESS',
  FUTURES_ADJUST_LEVERAGE_FAILURE: 'FUTURES_ADJUST_LEVERAGE_FAILURE',

  // ----- // FUTURES_ADJUST_MARGIN_MODE // ----- //
  FUTURES_ADJUST_MARGIN_MODE_START: 'FUTURES_ADJUST_MARGIN_MODE_START',
  FUTURES_ADJUST_MARGIN_MODE_SUCCESS: 'FUTURES_ADJUST_MARGIN_MODE_SUCCESS',
  FUTURES_ADJUST_MARGIN_MODE_FAILURE: 'FUTURES_ADJUST_MARGIN_MODE_FAILURE',

  // ----- // FUTURES_GET_FAVORITE // ----- //
  FUTURES_GET_FAVORITE_START: 'FUTURES_GET_FAVORITE_START',
  FUTURES_GET_FAVORITE_SUCCESS: 'FUTURES_GET_FAVORITE_SUCCESS',
  FUTURES_GET_FAVORITE_FAILURE: 'FUTURES_GET_FAVORITE_FAILURE',

  // ----- // FUTURES_TOGGLE_FAVORITE // ----- //
  FUTURES_TOGGLE_FAVORITE_START: 'FUTURES_TOGGLE_FAVORITE_START',
  FUTURES_TOGGLE_FAVORITE_SUCCESS: 'FUTURES_TOGGLE_FAVORITE_SUCCESS',
  FUTURES_TOGGLE_FAVORITE_FAILURE: 'FUTURES_TOGGLE_FAVORITE_FAILURE',

  // // ----- // FUTURES_POST_ADJUST_LEVERAGE // ----- //
  // FUTURES_POST_ADJUST_LEVERAGE_START: 'FUTURES_POST_ADJUST_LEVERAGE_START',
  // FUTURES_POST_ADJUST_LEVERAGE_SUCCESS: 'FUTURES_POST_ADJUST_LEVERAGE_SUCCESS',
  // FUTURES_POST_ADJUST_LEVERAGE_FAILURE: 'FUTURES_POST_ADJUST_LEVERAGE_FAILURE',

  // // ----- // FUTURES_POST_ADJUST_MARGIN_MODE // ----- //
  // FUTURES_POST_ADJUST_MARGIN_MODE_START:
  //   'FUTURES_POST_ADJUST_MARGIN_MODE_START',
  // FUTURES_POST_ADJUST_MARGIN_MODE_SUCCESS:
  //   'FUTURES_POST_ADJUST_MARGIN_MODE_SUCCESS',
  // FUTURES_POST_ADJUST_MARGIN_MODE_FAILURE:
  //   'FUTURES_POST_ADJUST_MARGIN_MODE_FAILURE',

  // ----- // REFERRAL_GET_INVITATIONS // ----- //
  REFERRAL_GET_INVITATIONS_START: 'REFERRAL_GET_INVITATIONS_START',
  REFERRAL_GET_INVITATIONS_SUCCESS: 'REFERRAL_GET_INVITATIONS_SUCCESS',
  REFERRAL_GET_INVITATIONS_FAILURE: 'REFERRAL_GET_INVITATIONS_FAILURE',

  // ----- // REFERRAL_GET_BONUSES // ----- //
  REFERRAL_GET_BONUSES_START: 'REFERRAL_GET_BONUSES_START',
  REFERRAL_GET_BONUSES_SUCCESS: 'REFERRAL_GET_BONUSES_SUCCESS',
  REFERRAL_GET_BONUSES_FAILURE: 'REFERRAL_GET_BONUSES_FAILURE',

  // ----- // GET_USER_REFERRAL_BONUSES // ----- //
  GET_USER_REFERRAL_BONUSES_START: 'GET_USER_REFERRAL_BONUSES_START',
  GET_USER_REFERRAL_BONUSES_SUCCESS: 'GET_USER_REFERRAL_BONUSES_SUCCESS',
  GET_USER_REFERRAL_BONUSES_FAILURE: 'GET_USER_REFERRAL_BONUSES_FAILURE',

  // ----- // REFERRAL_SEND_INVITATION // ----- //
  REFERRAL_SEND_INVITATION_START: 'REFERRAL_SEND_INVITATION_START',
  REFERRAL_SEND_INVITATION_SUCCESS: 'REFERRAL_SEND_INVITATION_SUCCESS',
  REFERRAL_SEND_INVITATION_FAILURE: 'REFERRAL_SEND_INVITATION_FAILURE',

  // ----- // GET_REFERRAL_BALANCE // ----- //
  GET_REFERRAL_BALANCE_START: 'GET_REFERRAL_BALANCE_START',
  GET_REFERRAL_BALANCE_SUCCESS: 'GET_REFERRAL_BALANCE_SUCCESS',
  GET_REFERRAL_BALANCE_FAILURE: 'GET_REFERRAL_BALANCE_FAILURE',

  // ----- // GET_REFERRALS_TRANSFERS // ----- //
  GET_REFERRALS_TRANSFERS_START: 'GET_REFERRALS_TRANSFERS_START',
  GET_REFERRALS_TRANSFERS_SUCCESS: 'GET_REFERRALS_TRANSFERS_SUCCESS',
  GET_REFERRALS_TRANSFERS_FAILURE: 'GET_REFERRALS_TRANSFERS_FAILURE',

  // ----- // POST_REFERRALS_TRANSFERS // ----- //
  POST_REFERRALS_TRANSFERS_START: 'POST_REFERRALS_TRANSFERS_START',
  POST_REFERRALS_TRANSFERS_SUCCESS: 'POST_REFERRALS_TRANSFERS_SUCCESS',
  POST_REFERRALS_TRANSFERS_FAILURE: 'POST_REFERRALS_TRANSFERS_FAILURE',

  // ----- // POST_FUTURES_TRANSFERS // ----- //
  POST_FUTURES_TRANSFERS_START: 'POST_FUTURES_TRANSFERS_START',
  POST_FUTURES_TRANSFERS_SUCCESS: 'POST_FUTURES_TRANSFERS_SUCCESS',
  POST_FUTURES_TRANSFERS_FAILURE: 'POST_FUTURES_TRANSFERS_FAILURE',

  // ----- // GET_AIRDROP_TRANSACTIONS // ----- //
  GET_AIRDROP_TRANSACTIONS_START: 'GET_AIRDROP_TRANSACTIONS_START',
  GET_AIRDROP_TRANSACTIONS_SUCCESS: 'GET_AIRDROP_TRANSACTIONS_SUCCESS',
  GET_AIRDROP_TRANSACTIONS_FAILURE: 'GET_AIRDROP_TRANSACTIONS_FAILURE',

  // ----- // POST_AIRDROP_TRANSFERS // ----- //
  POST_AIRDROP_TRANSFERS_START: 'POST_AIRDROP_TRANSFERS_START',
  POST_AIRDROP_TRANSFERS_SUCCESS: 'POST_AIRDROP_TRANSFERS_SUCCESS',
  POST_AIRDROP_TRANSFERS_FAILURE: 'POST_AIRDROP_TRANSFERS_FAILURE',

  // ----- // GET_REFRESH_TOKEN // ----- //
  GET_REFRESH_TOKEN_START: 'GET_REFRESH_TOKEN_START',
  GET_REFRESH_TOKEN_SUCCESS: 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE: 'GET_REFRESH_TOKEN_FAILURE',

  /**
   * HOME PAGE
   */
  // ----- // GET_VIDEOS // ----- //
  GET_VIDEOS_START: 'GET_VIDEOS_START',
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  GET_VIDEOS_FAILURE: 'GET_VIDEOS_FAILURE',

  // ----- // GET_TRADER_INFO // ----- //
  GET_TRADER_INFO_START: 'GET_TRADER_INFO_START',
  GET_TRADER_INFO_SUCCESS: 'GET_TRADER_INFO_SUCCESS',
  GET_TRADER_INFO_FAILURE: 'GET_TRADER_INFO_FAILURE',

  // ----- // GET_PAYEER_SYSTEM // ----- //
  GET_PAYEER_SYSTEM_START: 'GET_PAYEER_SYSTEM_START',
  GET_PAYEER_SYSTEM_SUCCESS: 'GET_PAYEER_SYSTEM_SUCCESS',
  GET_PAYEER_SYSTEM_FAILURE: 'GET_PAYEER_SYSTEM_FAILURE',

  // ----- // GET_PRMONEY_SYSTEM // ----- //
  GET_PRMONEY_SYSTEM_START: 'GET_PRMONEY_SYSTEM_START',
  GET_PRMONEY_SYSTEM_SUCCESS: 'GET_PRMONEY_SYSTEM_SUCCESS',
  GET_PRMONEY_SYSTEM_FAILURE: 'GET_PRMONEY_SYSTEM_FAILURE',

  /**
   * CHAT
   */
  // ----- // GET_MESSAGES // ----- //
  GET_MESSAGES_START: 'GET_MESSAGES_START',
  GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
  GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',

  // ----- // GET_CHAT_USERS // ----- //
  GET_CHAT_USERS_START: 'GET_CHAT_USERS_START',
  GET_CHAT_USERS_SUCCESS: 'GET_CHAT_USERS_SUCCESS',
  GET_CHAT_USERS_FAILURE: 'GET_CHAT_USERS_FAILURE',

  // ----- // UPDATE_USER_CHAT_STATUS // ----- //
  UPDATE_USER_CHAT_STATUS_START: 'UPDATE_USER_CHAT_STATUS_START',
  UPDATE_USER_CHAT_STATUS_SUCCESS: 'UPDATE_USER_CHAT_STATUS_SUCCESS',
  UPDATE_USER_CHAT_STATUS_FAILURE: 'UPDATE_USER_CHAT_STATUS_FAILURE',

  // ----- // GET_MESSAGES_FOR_ADMIN // ----- //
  GET_MESSAGES_FOR_ADMIN_START: 'GET_MESSAGES_FOR_ADMIN_START',
  GET_MESSAGES_FOR_ADMIN_SUCCESS: 'GET_MESSAGES_FOR_ADMIN_SUCCESS',
  GET_MESSAGES_FOR_ADMIN_FAILURE: 'GET_MESSAGES_FOR_ADMIN_FAILURE',

  // ----- // POST_MESSAGE // ----- //
  POST_MESSAGE_START: 'POST_MESSAGE_START',
  POST_MESSAGE_SUCCESS: 'POST_MESSAGE_SUCCESS',
  POST_MESSAGE_FAILURE: 'POST_MESSAGE_FAILURE',

  // ----- // EDIT_MESSAGE // ----- //
  EDIT_MESSAGE_START: 'EDIT_MESSAGE_START',
  EDIT_MESSAGE_SUCCESS: 'EDIT_MESSAGE_SUCCESS',
  EDIT_MESSAGE_FAILURE: 'EDIT_MESSAGE_FAILURE',

  // ----- // DELETE_MESSAGE // ----- //
  DELETE_MESSAGE_START: 'DELETE_MESSAGE_START',
  DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAILURE: 'DELETE_MESSAGE_FAILURE',

  // ----- // DELETE_MESSAGE_FROM_DATABASE // ----- //
  DELETE_MESSAGE_FROM_DATABASE_START: 'DELETE_MESSAGE_FROM_DATABASE_START',
  DELETE_MESSAGE_FROM_DATABASE_SUCCESS: 'DELETE_MESSAGE_FROM_DATABASE_SUCCESS',
  DELETE_MESSAGE_FROM_DATABASE_FAILURE: 'DELETE_MESSAGE_FROM_DATABASE_FAILURE',

  // ----- // UPDATE_CHAT_LANGUAGE // ----- //
  UPDATE_CHAT_LANGUAGE_START: 'UPDATE_CHAT_LANGUAGE_START',
  UPDATE_CHAT_LANGUAGE_SUCCESS: 'UPDATE_CHAT_LANGUAGE_SUCCESS',
  UPDATE_CHAT_LANGUAGE_FAILURE: 'UPDATE_CHAT_LANGUAGE_FAILURE',

  // ----- // UPDATE_CHAT_NAME // ----- //
  UPDATE_CHAT_NAME_START: 'UPDATE_CHAT_NAME_START',
  UPDATE_CHAT_NAME_SUCCESS: 'UPDATE_CHAT_NAME_SUCCESS',
  UPDATE_CHAT_NAME_FAILURE: 'UPDATE_CHAT_NAME_FAILURE',

  // ----- // UPDATE_MESSAGES // ----- //
  UPDATE_CHAT_MESSAGES: 'UPDATE_CHAT_MESSAGES',

  /**
   * VOTE
   */
  // ----- // GET_VOTING_LIST // ----- //
  GET_VOTING_LIST_START: 'GET_VOTING_LIST_START',
  GET_VOTING_LIST_SUCCESS: 'GET_VOTING_LIST_SUCCESS',
  GET_VOTING_LIST_FAILURE: 'GET_VOTING_LIST_FAILURE',

  /**
   * STATUS_PAGE
   */
  // ----- // GET_ASSETS_STATUS_LIST // ----- //
  GET_ASSETS_STATUS_LIST_START: 'GET_ASSETS_STATUS_LIST_START',
  GET_ASSETS_STATUS_LIST_SUCCESS: 'GET_ASSETS_STATUS_LIST_SUCCESS',
  GET_ASSETS_STATUS_LIST_FAILURE: 'GET_ASSETS_STATUS_LIST_FAILURE',
  // ----- // GET_INCIDENTS_LIST // ----- //
  GET_INCIDENTS_LIST_START: 'GET_INCIDENTS_LIST_START',
  GET_INCIDENTS_LIST_SUCCESS: 'GET_INCIDENTS_LIST_SUCCESS',
  GET_INCIDENTS_LIST_FAILURE: 'GET_INCIDENTS_LIST_FAILURE',

  /**
   * SECURITY
   */
  // ----- // GET_SECURITY_OPTIONS // ----- //
  GET_SECURITY_OPTIONS_START: 'GET_SECURITY_OPTIONS_START',
  GET_SECURITY_OPTIONS_SUCCESS: 'GET_SECURITY_OPTIONS_SUCCESS',
  GET_SECURITY_OPTIONS_FAILURE: 'GET_SECURITY_OPTIONS_FAILURE',

  // ----- // PUT_SECURITY_OPTIONS // ----- //
  PUT_SECURITY_OPTIONS_START: 'PUT_SECURITY_OPTIONS_START',
  PUT_SECURITY_OPTIONS_SUCCESS: 'PUT_SECURITY_OPTIONS_SUCCESS',
  PUT_SECURITY_OPTIONS_FAILURE: 'PUT_SECURITY_OPTIONS_FAILURE',

  // ----- // SET_WITHDRAWAL_SECRET_PHRASE // ----- //
  SET_WITHDRAWAL_SECRET_PHRASE_START: 'SET_WITHDRAWAL_SECRET_PHRASE_START',
  SET_WITHDRAWAL_SECRET_PHRASE_SUCCESS: 'SET_WITHDRAWAL_SECRET_PHRASE_SUCCESS',
  SET_WITHDRAWAL_SECRET_PHRASE_FAILURE: 'SET_WITHDRAWAL_SECRET_PHRASE_FAILURE',

  // ----- // GET_USER_UNIQUE_DEVICES // ----- //
  GET_USER_UNIQUE_DEVICES_START: 'GET_USER_UNIQUE_DEVICES_START',
  GET_USER_UNIQUE_DEVICES_SUCCESS: 'GET_USER_UNIQUE_DEVICES_SUCCESS',
  GET_USER_UNIQUE_DEVICES_FAILURE: 'GET_USER_UNIQUE_DEVICES_FAILURE',

  // ----- // DELETE_USER_UNIQUE_DEVICE // ----- //
  DELETE_USER_UNIQUE_DEVICE_START: 'DELETE_USER_UNIQUE_DEVICE_START',
  DELETE_USER_UNIQUE_DEVICE_SUCCESS: 'DELETE_USER_UNIQUE_DEVICE_SUCCESS',
  DELETE_USER_UNIQUE_DEVICE_FAILURE: 'DELETE_USER_UNIQUE_DEVICE_FAILURE',

  // ----- // GET_USER_WHITELIST_ADDRESSES // ----- //
  GET_USER_WHITELIST_ADDRESSES_START: 'GET_USER_WHITELIST_ADDRESSES_START',
  GET_USER_WHITELIST_ADDRESSES_SUCCESS: 'GET_USER_WHITELIST_ADDRESSES_SUCCESS',
  GET_USER_WHITELIST_ADDRESSES_FAILURE: 'GET_USER_WHITELIST_ADDRESSES_FAILURE',

  // ----- // UPDATE_USER_WHITELIST_ADDRESSES // ----- //
  UPDATE_USER_WHITELIST_ADDRESSES_START:
    'UPDATE_USER_WHITELIST_ADDRESSES_START',
  UPDATE_USER_WHITELIST_ADDRESSES_SUCCESS:
    'UPDATE_USER_WHITELIST_ADDRESSES_SUCCESS',
  UPDATE_USER_WHITELIST_ADDRESSES_FAILURE:
    'UPDATE_USER_WHITELIST_ADDRESSES_FAILURE',

  // ----- // CHECK_USER_VALID_ADDRESS // ----- //
  CHECK_USER_VALID_ADDRESS_START: 'CHECK_USER_VALID_ADDRESS_START',
  CHECK_USER_VALID_ADDRESS_SUCCESS: 'CHECK_USER_VALID_ADDRESS_SUCCESS',
  CHECK_USER_VALID_ADDRESS_FAILURE: 'CHECK_USER_VALID_ADDRESS_FAILURE',

  // ----- // GET_USER_LOGIN_HISTORY // ----- //
  GET_USER_LOGIN_HISTORY_START: 'GET_USER_LOGIN_HISTORY_START',
  GET_USER_LOGIN_HISTORY_SUCCESS: 'GET_USER_LOGIN_HISTORY_SUCCESS',
  GET_USER_LOGIN_HISTORY_FAILURE: 'GET_USER_LOGIN_HISTORY_FAILURE',

  // ----- // POST_APPROVE_WITHDRAWAL // ----- //
  POST_APPROVE_WITHDRAWAL_START: 'POST_APPROVE_WITHDRAWAL_START',
  POST_APPROVE_WITHDRAWAL_SUCCESS: 'POST_APPROVE_WITHDRAWAL_SUCCESS',
  POST_APPROVE_WITHDRAWAL_FAILURE: 'POST_APPROVE_WITHDRAWAL_FAILURE',

  // ----- // POST_RESEND_APPROVE_WITHDRAWAL_CODE // ----- //
  POST_RESEND_APPROVE_WITHDRAWAL_CODE_START:
    'POST_RESEND_APPROVE_WITHDRAWAL_CODE_START',
  POST_RESEND_APPROVE_WITHDRAWAL_CODE_SUCCESS:
    'POST_RESEND_APPROVE_WITHDRAWAL_CODE_SUCCESS',
  POST_RESEND_APPROVE_WITHDRAWAL_CODE_FAILURE:
    'POST_RESEND_APPROVE_WITHDRAWAL_CODE_FAILURE',
};
