import _axios from 'axios';
import routes from '../routes';

const axios = _axios.create({ baseURL: "https://base.coinstable.io/",headers:{
  Authorization: "Bearer "+localStorage.getItem("firoz_token")
} })
  axios.interceptors.response.use(
    response => {
      console.log('Response:', response);
      return response;
    },
    error => {
      if (error.response.status === 401) {
        if (window.location.pathname !== routes.Auth.Login.path) {          
          window.location.href = routes.Auth.Login.path;
          localStorage.removeItem("firoz_token");
        }
      } else if (error.response.status === 500) {
        console.error("Server error - please try again later.");
      }
      return Promise.reject(error);
    }
  );
export default {
  auth: {
    login: data => axios.post(`https://base.coinstable.io/api/login`, data),
    logout: () => axios.post(`/api/logout`),
    sendSmsCode: body => axios.post(`/api/sms_code`, body),
    checkSmsCode: body => axios.post(`/api/sms_login`, body),
    resetPassword: data => axios.post(`/api/password_reset`, data),
    signup: data => axios.post(`https://base.coinstable.io/api/register`, data),
    changePassword: data => axios.put(`/api/user/password`, data),
    reset2FA: data => axios.post(`/api/2fa/reset`, data),
    uploadSelfi: data => axios.post(`api/2fa/reset/selfie`, data),
    confirmEmail: data => axios.put(`/api/email_confirmation`, data),
    verifyUserIP: data => axios.put(`/api/whitelist_ip`, data),
    recentCode: email => axios.post(`/api/whitelist_ip/resend`, email),
    refreshToken: () => axios.get(`/api/token/refresh`),
    getKYCAuthToken: () => axios.get('/api/user/kyc/auth'),
    postResendEmailConfirmation: body =>
      axios.post('/api/resend_email_confirmation', body),
    // getCheckUserOnline: () => axios.get('/api/ping'),
    getTokenBySmsCode: data => axios.post(`/api/get_token_by_sms_code`, data),
    postResendLoginSmsCode: data => axios.post(`/api/resend_sms_code`, data),
    phoneConfirmation: data => axios.put(`/api/phone_confirmation`, data),
    resendPhoneConfirmation: data =>
      axios.post(`/api/resend_phone_confirmation`, data),
    postEmailSetConfirm: data =>
      axios.post(`/api/secure/email_set_confirm`, data),
    postPhoneSetConfirm: data =>
      axios.post(`/api/secure/phone_set_confirm`, data),
    postPhoneTurnOff: () => axios.post(`/api/secure/phone_turn_off`),
    sendEmailCode: () => axios.get(`/api/settings/wallets/send_email_code`),
    deviceConfirmation: data => axios.post('/api/device_confirmation', data),
    verifyUserStartKYC: data => axios.post('/api/kyc/start', data),
    getKyc: ({ kycId }) =>
    axios.get(`/api/voting/${kycId}`),
  },
  fees: { getFees: body => axios.get('/api/user/fees', body) },
  settings: {
    user: () => axios.get('/api/user', { params: { with_doc: true } }),
    apiManagement: {
      getToken: () => axios.get('/api/api_tokens'),
      createToken: data => axios.post('/api/api_tokens', data),
      disable: data => axios.put(`/api/api_tokens/${data.id}`, data),
      delete: data => axios.delete(`/api/api_tokens/${data}`),
    },
    identification: {
      updatePersonaData: data => axios.put('/api/user/data/update', data),
      updateDocument: data => axios.post('/api/user/document/file', data),
      deleteDocument: id => axios.delete(`/api/user/document/file/${id}`),
      getKycVerifications: () => axios.get('/api/kyc'),
    },
    security: {
      resetPassword: data => axios.put('/api/settings/change_password', data),
      getUserData: () => axios.get(`/api/user/security`),
      enableGoogle2FA: data => axios.post(`/api/settings/2fa/enable`, data),
      disableGoogle2FA: data => axios.post(`/api/settings/2fa/disable`, data),
      getSecretKey: () => axios.get('/api/settings/2fa/generate_secret_key'),
      getSecurityOptions: () => axios.get('/api/security/options'),
      putSecurityOptions: data => axios.put('/api/security/options', data),
      setWithdrawalSecretPhrase: data =>
        axios.put('/api/security/options/withdrawal_secret', data),
      getUserUniqueDevices: () => axios.get('/api/user/security/devices'),
      deleteUserUniqueDevice: ({ params }) =>
        axios.delete(`/api/user/security/device/delete`, { params }),
      getUserWhitelistAddresses: () => axios.get('/api/user/white_addresses'),
      updateUserWhitelistAddresses: data =>
        axios.put('/api/user/white_addresses', data),
      checkValidAddress: data => axios.post('/api/user/check_address', data),
      getUserLoginHistory: ({ params }) =>
        axios.get(`/api/security/login_history`, { params }),
      postAproveWithdrawal: body =>
        axios.post(`/api/security/payment/approve`, body),
      postResendAproveWithdrawalCode: body =>
        axios.post(`/api/security/payment/crypto/resend`, body),
    },
    dashboard: {
      getData: () => axios.get('/api/user/dashboard'),
      updateTokenDiscount: data => axios.put(`/api/user/token_discount`, data),
      getTokenDiscount: () => axios.get(`/api/user/token_discount`),
    },
  },
  projects:{
    submitFormData: (payload) => axios.post('/api/projects', payload),
  },

  wallets: {
    getWalletsList: () => axios.get('/api/settings/wallets'),
    getTransakLink: () => axios.get(`/api/payment/transak`),
    getAssets: () => axios.get(`/api/assets`),
    getWallet: id => axios.get(`/api/settings/wallets/${id}`),
    getWalletsTransactions: query =>
      axios.get(`/api/settings/wallets/transactions${query}`),
    getFiatTransactions: query => axios.get(`/api/payment/history${query}`),
    getWalletsTransfer: body =>
      axios.get(`/api/settings/wallets/transfers`, body),
    getWalletsDeposits: query =>
      axios.get(`/api/settings/wallets/deposits/${query}`),
    getWalletsWithdrawals: query =>
      axios.get(`/api/settings/wallets/withdrawals/${query}`),
    getProfit: () => axios.get('/api/settings/wallets/profit'),
    airdrop: {
      getAirdropTransactions: body =>
        axios.get(`/api/settings/dividends`, body),
      postTransferToSpot: body =>
        axios.post(`/api/settings/dividends/transfer_to_spot`, body),
    },
    futures: {
      postTransferToSpot: body =>
        axios.post(`/api/futures/transfer_to_spot`, body),
      postTransferToFutures: body =>
        axios.post(`/api/futures/transfer_to_futures`, body),
    },
    referrals: {
      getReferralbalance: () => axios.get('/api/settings/referral_wallets'),
      getReferralsTransfers: body =>
        axios.get(`/api/settings/referral_wallets/transfers`, body),
      postTransferToSpot: body =>
        axios.post(`/api/settings/referral_wallets/transfer_to_spot`, body),
    },
    fiat: {
      // postCreateBankDepositRequest: body =>
      //   axios.post(`/api/settings/wallets/bank_deposit`, body),
      getPayeerSystem: () => axios.get('/api/payment/payeer/paymentsystems'),
      postPayeerDeposit: body =>
        axios.post(`/api/payment/payeer/deposit/new`, null, body),
      postPayeerWidthdraw: body =>
        axios.post(`/api/payment/payeer/withdraw/new`, null, body),
      getPrMoneySystem: () => axios.get('/api/payment/prmoney/paymentsystems'),
      postPrMoneyDeposit: body =>
        axios.post(`/api/payment/prmoney/deposit/new`, null, body),
      // postPrMoneyWidthdraw: body =>
      //   axios.post(`/api/payment/prmoney/withdraw/new`, null, body),
      postPalmiraDeposit: body =>
        axios.post(`/api/payment/palmira/deposit/new`, null, body),
      postPerfectMoneyDeposit: body =>
        axios.post(`/api/payment/perfectmoney/deposit/new`, null, body),
      postPerfectMoneyWithdraw: body =>
        axios.post(`/api/payment/perfectmoney/withdraw/new`, null, body),
      postSettlePayWithdraw: body =>
        axios.post(`/api/payment/settlepay/withdraw/new`, null, body),
      postPaypalDeposit: body =>
        axios.post(`/api/payment/paypal/deposit/new`, null, body),
      postPaypalDepositApprove: body =>
        axios.post(`/api/payment/paypal/deposit/approve`, null, body),
      postPaypalWidthdraw: body =>
        axios.post(`/api/payment/paypal/withdraw/new`, null, body),
      getPaypalRecipientTypes: () =>
        axios.get(`/api/payment/paypal/recipient_types`),
      postTrustDeposit: body =>
        axios.post(`/api/payment/trust/deposit/new`, null, body),
      postSettlePayDeposit: body =>
        axios.post(`/api/payment/settlepay/deposit/new`, null, body),
    },
    crypto: {
      postGenerateAddressForWallet: payload =>
        axios.post(`/api/settings/wallets/${payload?.id}/generate_address`, {
          network: payload?.network,
        }),
      postCreateWithdrawalRequest: body =>
        axios.post(`/api/settings/wallets/withdrawal_request`, body),
      deleteWithdrawalDepositRequest: body =>
        axios.delete(`/api/settings/wallets/transactions`, body),
    },
  },
  referralSystem: {
    getInvitation: body => axios.get('/api/user/invitations', body),
    getReferralBonuses: () => axios.get('/api/user/bonuses'),
    postSendInvitation: body =>
      axios.post('/api/user/invitation/send', null, body),
    getUserReferralBonuses: body =>
      axios.get('/api/user/referral/bonuses', body),
  },
  trading: {
    getTopPairs: () => axios.get('/api/top_pairs'),
    getAssetPairs: () => axios.get('/api/assets_pairs'),
    getAssetsPairsVolume: () => axios.get('/api/assets_pairs/volume'),
    getOrderBook: ({ activePair, data }) =>
      axios.get(`/api/order_book/${activePair}`, data),
    getSpotAssetPairs: () => axios.get('/api/assets_pairs/spot'), // for spot pairs list
    getMarginAssetPairs: () => axios.get('/api/assets_pairs/margin'), // for margin market list
    getFavoriteAssetPairs: () => axios.get('/api/assets_pairs/favorite'), // for fav market list
    updateFavoriteAssetPair: body =>
      axios.put('/api/assets_pairs/favorite', body),
    postCalculateMarketOrder: data =>
      axios.post(`/api/calculate_market_order`, data),
    postCalculateLimitOrder: data =>
      axios.post(`/api/calculate_limit_order`, data),
    postPublicCalculateMarketOrder: data =>
      axios.post(`/api/custom/calculate_market_order`, data),
    postPublicCalculateLimitOrder: data =>
      axios.post(`/api/custom/calculate_limit_order`, data),
    getRecentTrades: (activePair, data) =>
      axios.get(`/api/trades/${activePair}`, data),

    // ---- tables block / market page
    getOrders: data => axios.get('/api/my_orders/opened', data),
    cancelOrder: activePair => axios.post(`/api/orders/${activePair}/cancel`),
    cancelAllOrders: data => axios.post(`/api/order/cancel/all`, data),
    cancelOpenedLimitOrder: id =>
      axios.post(`/api/stop_limit_orders/${id}/cancel`),
    getTradesHistory: data => axios.get('/api/trades_history', data),
    getOrdersBook: ({ activePair, limit }) =>
      axios.get(`/api/order_book/${activePair}/${limit}`),
    getHistory: data => axios.get('/api/orders_history', data),
    getFunds: () => axios.get('/api/settings/wallet'),
    loadRecentTrades: ({ activePair, params }) =>
      axios.get(`/api/trades/${activePair}`, { params }),
    createOrder: ({ payload }) => axios.post('/api/orders', payload),
    createStopLimitOrder: ({ payload }) =>
      axios.post('/api/stop_limit_orders', payload),

    getUserTradeVolumeStatistics: () => axios.get('/api/volume_statistics'),
    getTradingVolumeLevels: () => axios.get('/api/trade_levels'),
    getCurrentCahsback: () => axios.get('/api/current_cashback'),
    getCashbacksByTrades: ({ params }) =>
      axios.get('/api/orders_transactions', { params }),

    margin: {
      getOpenOrders: data => axios.get(`/api/my_orders/opened/margin`, data),
      getOrderHistory: data => axios.get('/api/my_orders/all/margin', data),
      getTradeHistory: data =>
        axios.get('/api/orders_transactions/margin', data),
      cancelAllOpenedOrders: data =>
        axios.post(`/api/order/cancel/all/margin`, data),
    },

    futures: {
      getOpenedOrders: () => axios.get(`/api/futures/orders/active`),
      getOpenedPositions: () => axios.get(`/api/futures/positions/opened`),
      getOrdersHistory: () => axios.get(`/api/futures/orders/history`),
      getFills: () => axios.get(`/api/futures/fills`),
      getRecentTrades: pair => axios.get(`/api/futures/recent_trades/${pair}`),
      getOrderBook: pair => axios.get(`/api/futures/order_book/${pair}`),
      getContractsList: () => axios.get(`/api/futures/contracts`),
      cancelOrder: id => axios.delete(`/api/futures/orders/${id}`),
      cancelAllOrders: body => {
        axios.delete(`/api/futures/orders/all`, body);
      },
      createOrder: payload => axios.post(`/api/futures/orders`, payload), // for futures we have one api for all oreder types
      getUserBalance: () => axios.get('/api/futures/balance'),
      adjustLeverage: body => axios.post('/api/futures/adjust_leverage', body),
      adjustMarginMode: body =>
        axios.post('/api/futures/adjust_margin_mode', body),
      getFavoriteContracts: () => axios.get('/api/futures/contracts/favorite'),
      toggleFavorite: payload =>
        axios.put(`/api/futures/contracts/favorite/toggle/${payload}`),
    },
  },
  chart: {
    getHistoryChart: query => axios.get(`/api/history${query}`),
    getFuturesHistoryChart: query => axios.get(`/api/futures/history${query}`),
  },
  support: {
    getTopics: () => axios.get('/api/topics'),
    postContactUs: data => axios.post('/api/contact_us', data),
  },
  margin: {
    getLowRisk: () => axios.get('/api/low_risk'),
    openMargin: () => axios.post('/api/open_margin'),
    getBalance: () => axios.get('/api/margin_balances'),
    getBorrow: params => axios.get('/api/get_borrow', { params }),
    getBorrows: () => axios.get('/api/get_borrows'),
    getRepay: params => axios.get('/api/get_repay', { params }),
    getRapyas: () => axios.get('/api/get_repays'),
    getBalanceHistory: () => axios.get('/api/user_margin_balance_history'),
    getInterestHistory: () => axios.get('/api/interest_history'),
    transferToMargin: data => axios.post('/api/transfer_to_margin', data),
    transferToExchange: data => axios.post('/api/transfer_to_exchange', data),
    transferToSpot: data => axios.post('/api/transfer_to_spot', data),
    borrowing: data => axios.post('/api/borrowing_margin_balances', data),
    repay: data => axios.post('/api/repay_margin_balances', data),
  },
  bankTransfer: {
    exchangeRate: assets => axios.post(`/api/exchange_rate`, assets),
    makeExchange: data => axios.post(`/api/exchange`, data),
    // getExchangeHistory: () => axios.get(`api/exchanges`),
    getExchangeHistory: ({ params }) => axios.get(`api/exchanges`, { params }),
  },
  vacancy: {
    getVacancy: () => axios.get('api/vacancy'),
  },
  traderInfo: {
    getTraderInfo: () => axios.get('api/info/main'),
  },
  videos: {
    getVideos: () => axios.get('/api/media'),
  },
  cashback: {
    getAvailableCashback: () => axios.get('/api/cashback'),
    postBuyCashback: data => axios.post('/api/cashback/buy', data),
    getUserCashback: () => axios.get('/api/cashback/my'),
    updateUserCashbackRenewal: () =>
      axios.put('/api/cashback/auto_renewal/switch'),
  },
  limits: {
    getLimits: () => axios.get('/api/pairs/fees'),
    getFeesPerInputAndOutput: () => axios.get('/api/commissions'),
  },
  chat: {
    getMessages: params => axios.get('/api/chat/messages', { params }),
    postMessage: data => axios.post('/api/chat/message', data),
    editMessage: data => axios.put('/api/chat/message', data),
    deleteMessage: ({ id }) => axios.delete(`/api/chat/message/${id}`),
    deleteMessageFromDatabase: ({ id }) =>
      axios.delete(`/api/chat/message/force/${id}`),
    updateChatLanguage: data => axios.put('/api/chat/lang', data),
    updateChatName: data => axios.put('/api/chat/name', data),
    getChatUsers: params => axios.get('/api/chat/users', { params }),
    updateUserChatStatus: data => axios.put('/api/chat/user_status', data),
  },
  voting: {
    getVotingList: params =>
      axios.get(`/api/votings/${params.type}`, {
        params: {
          lang: params.lang,
          current_page: params.current_page,
          per_page: params.per_page,
          status: params.status ? params.status : 'active',
        },
      }),
  },
  status: {
    getAssetsStatus: () => axios.get('/api/assets_status'),
    getIncedents: ({ params }) => axios.get('/api/incidents', { params }),
  },
};
