import types from '../../types';

const initialState = {
  userTradeStatistics: [],
  tradeLevels: [],
  cashbackRate: {},
  cashbacksByTrades: {},
  loading: false,
};

const tradeVolumes = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_TRADE_VOLUME_STATISTICS_START:
      return { ...state, loading: true };
    case types.GET_USER_TRADE_VOLUME_STATISTICS_SUCCESS:
      return { ...state, loading: false, userTradeStatistics: payload };
    case types.GET_USER_TRADE_VOLUME_STATISTICS_FAILURE:
      return { ...state, loading: false };

    case types.GET_TRADING_VOLUME_LEVELS_START:
      return { ...state, loading: true };
    case types.GET_TRADING_VOLUME_LEVELS_SUCCESS:
      return { ...state, loading: false, tradeLevels: payload };
    case types.GET_TRADING_VOLUME_LEVELS_FAILURE:
      return { ...state, loading: false };

    case types.GET_CURRENT_CASHBACK_RATE_START:
      return { ...state, loading: true };
    case types.GET_CURRENT_CASHBACK_RATE_SUCCESS:
      return { ...state, loading: false, cashbackRate: payload };
    case types.GET_CURRENT_CASHBACK_RATE_FAILURE:
      return { ...state, loading: false };

    case types.GET_CASHBACKS_BY_TRADES_START:
      return { ...state, loading: true };
    case types.GET_CASHBACKS_BY_TRADES_SUCCESS:
      return { ...state, loading: false, cashbacksByTrades: payload };
    case types.GET_CASHBACKS_BY_TRADES_FAILURE:
      return { ...state, loading: false };

    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        userTradeStatistics: [],
        cashbackRate: {},
        cashbacksByTrades: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default tradeVolumes;
