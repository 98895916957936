import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';

export function* getVideos() {
  try {
    const { data, status } = yield call(api.videos.getVideos);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.GET_VIDEOS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.GET_VIDEOS_FAILURE });
    if (error?.response?.status === 401) return;
    console.log('Something went wrong!!!');
  }
}

export function* watcherVideos() {
  yield takeLatest(types.GET_VIDEOS_START, getVideos);
}
