import types from '../../types';

const initialState = {
  securityData: [],
  secretKey: null,
  loader: false,
  securityOptions: null,
  uniqueDevices: null,
  whitelistAddresses: null,
  loginHistory: null,
};

const UserSecurityData = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_SECURITY_DATA_START:
    case types.GET_SECRET_KEY_START:
    case types.GET_SECURITY_OPTIONS_START:
    case types.GET_USER_UNIQUE_DEVICES_START:
    case types.GET_USER_WHITELIST_ADDRESSES_START:
    case types.UPDATE_USER_WHITELIST_ADDRESSES_START:
    case types.GET_USER_LOGIN_HISTORY_START:
      return { ...state, loader: true };
    case types.GET_USER_SECURITY_DATA_SUCCESS:
      return { ...state, securityData: payload, loader: false };
    case types.GET_SECRET_KEY_SUCCESS:
      return { ...state, secretKey: payload, loader: false };
    case types.GET_SECURITY_OPTIONS_SUCCESS:
      return { ...state, securityOptions: payload, loader: false };
    case types.GET_USER_UNIQUE_DEVICES_SUCCESS:
      return { ...state, uniqueDevices: payload, loader: false };

    case types.DELETE_USER_UNIQUE_DEVICE_SUCCESS:
      return {
        ...state,
        uniqueDevices: state.uniqueDevices.filter(el => el.id === payload),
        loader: false,
      };

    case types.GET_USER_WHITELIST_ADDRESSES_SUCCESS: {
      return { ...state, whitelistAddresses: payload, loader: false };
    }
    case types.UPDATE_USER_WHITELIST_ADDRESSES_SUCCESS: {
      return { ...state, whitelistAddresses: payload, loader: false };
    }
    case types.GET_USER_LOGIN_HISTORY_SUCCESS: {
      return { ...state, loginHistory: payload, loader: false };
    }

    case types.GET_USER_SECURITY_DATA_FAILURE:
    case types.GET_SECRET_KEY_FAILURE:
    case types.GET_SECURITY_OPTIONS_FAILURE:
    case types.GET_USER_UNIQUE_DEVICES_FAILURE:
    case types.GET_USER_WHITELIST_ADDRESSES_FAILURE:
    case types.UPDATE_USER_WHITELIST_ADDRESSES_FAILURE:
    case types.GET_USER_LOGIN_HISTORY_FAILURE:
      return { ...state, loader: false };
    case types.LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default UserSecurityData;
