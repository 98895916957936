import React from 'react';
import L from 'i18n-react';
import { isAndroid, isIOS } from 'react-device-detect';

const DownloadAppBanner = ({ showBanner, onShowBanner }) => {
  return (
    <>
      {showBanner && (isAndroid || isIOS) ? (
        <div className="download-app">
          <div className="app-banner">
            <div className="d-flex app-banner__content">
              <div className="d-flex app-banner__logo">
              <img
                src='/img/coinstable-logo.svg'
                alt=""
                style={{ width: '45px' , height:'32px'}}
              />
              </div>
              <div className="app-banner__info">
                <p className="app-banner__info-title">Coinstable APP</p>
                <p className="app-banner__info-text">
                  {L.translate('HomePage.MobileAppSection.subtitle')}
                </p>
              </div>
            </div>
            <div className="d-flex app-banner__action">
              <a
                href={
                  isAndroid
                    ? 'https://play.google.com/store/apps/details?id=app.lifestyle.paiblock&hl=en'
                    : 'https://apps.apple.com/us/app/paiblock/id1502890083'
                }
                className="d-flex app-banner__action-download"
              >
                <svg
                  className="stroke"
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1.15594V15.5309"
                    stroke="#5F3600"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.03125 9.06213L8.5 15.5309L14.9688 9.06213"
                    stroke="#5F3600"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.3125 18.4059H15.6875"
                    stroke="#5F3600"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
              <button
                className="d-flex app-banner__action-close"
                type="button"
                onClick={onShowBanner}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.2188 0.78125L0.78125 18.2188"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.2188 18.2188L0.78125 0.78125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DownloadAppBanner;
