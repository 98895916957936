import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { chatSelector } from '../../../redux/chat/selectors';
import types from '../../../redux/types';
import routes from '../../../routes';
import { transformData } from '../../../services/helpers';
import notification from '../../../services/notification';
import SocketChat from '../../HOC/SocketChat';

const Chat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editMessage, setEditMEssage] = useState({
    message: '',
    edit: false,
    id: null,
  });
  const [message, setMessage] = useState('');
  const [currentTab, setCurrentTab] = useState('en');
  const [currentPage, setCurrentPage] = useState(1);
  const [onlineCounter, setOnlineCounter] = useState('-');
  const [skipMessages, setSkipMessages] = useState(0);

  const dispatch = useDispatch();
  const listRef = useRef(null);
  const history = useHistory();
  const userChat = useSelector(state => state?.user?.chat);
  const hasUserDeposit = useSelector(state => state?.user?.data?.has_deposit);
  const chatMessages = useSelector(chatSelector);

  const perPage = 15;
  const languagesChat = chatMessages?.supported_langs?.reduce((acc, el) => {
    acc[el] = el;
    return acc;
  }, {});

  useEffect(() => {
    const randFirst = 1000 + Math.random() * (3000 + 1 - 1000);
    let result = randFirst;

    const intervalId = setInterval(() => {
      const randSecond = 15 + Math.random() * (50 + 1 - 15);
      const randThird = 15 + Math.random() * (50 + 1 - 15);

      if (result < 1000) {
        result = result + randSecond + randThird;
      } else if (result > 3000) {
        result = result - randSecond - randThird;
      } else if (randSecond > randThird) {
        result = result + randSecond + randThird;
      } else if (randSecond > randThird) {
        result = result - randSecond - randThird;
      }

      setOnlineCounter(Math.floor(result));
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const handleTab = e => {
    setCurrentTab(e.target.name);
    setCurrentPage(1);
  };

  const scrollToBottom = () => {
    listRef.current.scrollTo({
      top: listRef.current.scrollHeight - 100,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setSkipMessages(chatMessages?.messages?.length);
  }, [chatMessages?.messages?.length]);

  const handleScroll = () => {
    if (!listRef.current) return;
    if (
      listRef?.current?.scrollTop === 0 &&
      skipMessages < chatMessages?.total
    ) {
      const body = {
        params: {
          per_page: perPage,
          skip: skipMessages,
          filter: JSON.stringify({ status: 'active' }),
        },
      };

      dispatch({
        type: types.GET_MESSAGES_START,
        payload: { ...body.params },
        scrollToBottom: () => {
          listRef.current.scrollTo({
            top: 700,
            behavior: 'smooth',
          });
        },
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: types.UPDATE_CHAT_LANGUAGE_START,
        payload: {
          lang: currentTab,
        },
      });
      const body = {
        params: {
          per_page: perPage,
          current_page: currentPage,
          filter: JSON.stringify({ status: 'active' }),
        },
      };
      setTimeout(() => {
        dispatch({
          type: types.GET_MESSAGES_START,
          payload: { ...body.params },
          scrollToBottom,
        });
      }, 300);
    }
  }, [dispatch, currentTab, isOpen]);

  const handleToggle = () => {
    if (!userChat) {
      notification({
        type: 'Error',
        title: 'Chat Name',
        message: 'To be able to write messages please enter your chat name',
      });
      history.push(routes.User.Identification.path);
      setIsOpen(false);
      return;
    }
    setIsOpen(!isOpen);
    setCurrentPage(1);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (userChat?.status !== 'active') {
      notification({
        type: 'Error',
        title: 'Banned',
        message:
          'Sorry you were banned, for detailed information please send a request to our support team to coinstable.io',
      });
      return;
    }

    if (editMessage.edit) {
      dispatch({
        type: types.EDIT_MESSAGE_START,
        payload: {
          id: editMessage.id,
          message: editMessage.message,
        },
      });

      setEditMEssage({
        message: '',
        edit: false,
        id: null,
      });
      setMessage('');
      return;
    }
    dispatch({
      type: types.POST_MESSAGE_START,
      payload: {
        lang: languagesChat[currentTab],
        message,
      },
      scrollToBottom,
    });
  
    setMessage('');
  };

  const handleMessageChange = e => {
    if (!userChat) {
      notification({
        type: 'Error',
        title: 'Chat Name',
        message: 'To be able to write messages please enter your chat name',
      });
      history.push(routes.User.ChatSettings.path);
      setIsOpen(false);
      return;
    }
    if (e.target.value.length === 500) {
      notification({
        type: 'Error',
        title: 'Chat Message',
        message: 'Message length cannot exceed 500 characters',
      });
      return;
    }
    if (editMessage.edit) {
      setEditMEssage({ ...editMessage, message: e.target.value });
      return;
    }
    setMessage(e.target.value);
  };

  const messageClick = (id, text, name) => {
    if (!userChat) {
      notification({
        type: 'Error',
        title: 'Chat Name',
        message: 'To be able to write messages please enter your chat name',
      });
      history.push(routes.User.Identification.path);
      setIsOpen(false);
      return;
    }
    if (name !== userChat?.nick_name) {
      return;
    }
    if (userChat?.status !== 'active') {
      notification({
        type: 'Error',
        title: 'Banned',
        message:
          'Sorry you were banned, for detailed information please send a request to our support team to coinstable.io',
      });
      return;
    }
    setEditMEssage({ ...editMessage, message: text, edit: true, id });
  };

  const handleMouseEnter = (e, name) => {
    if (name === userChat?.nick_name) {
      e.currentTarget.classList.add('widget-message--with-action');
    }
  };

  const handleMouseLeave = (e, name) => {
    if (name === userChat?.nick_name) {
      e.currentTarget.classList.remove('widget-message--with-action');
    }
  };

  const handleDeleteMEssage = id => {
    if (userChat?.status !== 'active') {
      notification({
        type: 'Error',
        title: 'Banned',
        message:
          'Sorry you were banned, for detailed information please send a request to our support team to coinstable.io',
      });
      return;
    }
    dispatch({
      type: types.DELETE_MESSAGE_START,
      payload: {
        id,
      },
    });
  };

  return (
    <>
      {isOpen ? (
        <>
          <SocketChat lang={languagesChat[currentTab] || 'en'} />
          <div className="chat-window-block">
            <div className="chat-window">
              <div className="chat-window-header">
                <button
                  className="chat-minimize"
                  type="button"
                  onClick={handleToggle}
                >
                  <svg
                    className="fill"
                    data-icon="window-minimize"
                    role="img"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M480 480H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h448c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                    />
                  </svg>
                </button>
                <div className="chat-info">
                  <div className="chat-status chat-status--type1">
                    <div className="chat-status__dot" />
                    <p className="chat-status__text">Online</p>
                  </div>
                  <p className="chat-info__count">{onlineCounter}</p>
                </div>
                <div className="chat-lang chat-window-header__lang">
                  {chatMessages?.supported_langs?.length > 0
                    ? chatMessages?.supported_langs?.map(el => (
                        <button
                          type="button"
                          className={
                            currentTab === el
                              ? 'chat-lang__btn active'
                              : 'chat-lang__btn'
                          }
                          name={el}
                          onClick={handleTab}
                          key={el}
                        >
                          {el.toUpperCase()}
                        </button>
                      ))
                    : null}
                </div>
              </div>
              <div className="chat-window-body">
                <div
                  className="chat-widget-list"
                  ref={listRef}
                  onScroll={handleScroll}
                >
                  {chatMessages?.messages?.length > 0
                    ? chatMessages?.messages.map((el, idx) => (
                        <div
                          onMouseEnter={e => handleMouseEnter(e, el.nick_name)}
                          onMouseLeave={e => handleMouseLeave(e, el.nick_name)}
                          className={`${
                            idx % 2 === 0
                              ? 'widget-message'
                              : 'widget-message widget-message--type2'
                          } ${
                            el.user_role === 'moderator'
                              ? 'widget-message--admin'
                              : ''
                          }`}
                          key={el.id}
                        >
                          <div className="widget-message__header">
                            <p
                              className={`widget-message__author ${
                                el.nick_name === userChat?.nick_name
                                  ? 'widget-message__author--type2'
                                  : ''
                              }`}
                            >
                              {el.nick_name === userChat?.nick_name && (
                                <span className="widget-message__author-dot" />
                              )}

                              {el.nick_name}
                            </p>
                            <span className="widget-message__date">
                              {transformData(el.created_at)}
                            </span>
                          </div>
                          <div className="widget-message__text">
                            <p>{el.message}</p>
                          </div>
                          {el.nick_name === userChat?.nick_name && (
                            <div className="widget-message-actions">
                              <button
                                className="message-action"
                                type="button"
                                onClick={() =>
                                  messageClick(el.id, el.message, el.nick_name)
                                }
                              >
                                <svg className="fill" viewBox="0 0 576 512">
                                  <path
                                    fill="currentColor"
                                    d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                                  />
                                </svg>
                              </button>
                              <button
                                className="message-action message-action--close"
                                type="button"
                                onClick={() => handleDeleteMEssage(el.id)}
                              >
                                <svg
                                  className="fill"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.72431 0.281387C1.53527 0.0987908 1.28208 -0.00224601 1.01927 3.78936e-05C0.756455 0.00232179 0.505056 0.107744 0.319214 0.293598C0.133373 0.479452 0.0279575 0.730868 0.0256738 0.993696C0.02339 1.25652 0.12442 1.50973 0.307004 1.69879L6.09551 7.48768L0.306002 13.2766C0.210268 13.369 0.133908 13.4797 0.0813763 13.602C0.0288447 13.7243 0.00119436 13.8558 3.78454e-05 13.9889C-0.00111867 14.122 0.024242 14.254 0.0746405 14.3772C0.125039 14.5004 0.199466 14.6123 0.293578 14.7064C0.38769 14.8005 0.499604 14.875 0.622787 14.9254C0.745971 14.9758 0.877958 15.0011 1.01105 15C1.14414 14.9988 1.27567 14.9712 1.39795 14.9186C1.52024 14.8661 1.63085 14.7897 1.72331 14.694L7.51282 8.90508L13.3013 14.694C13.4904 14.8766 13.7436 14.9776 14.0064 14.9753C14.2692 14.973 14.5206 14.8676 14.7064 14.6818C14.8923 14.4959 14.9977 14.2445 15 13.9817C15.0022 13.7188 14.9012 13.4656 14.7186 13.2766L8.93013 7.48768L14.7186 1.69879C14.9012 1.50973 15.0022 1.25652 15 0.993696C14.9977 0.730868 14.8923 0.479452 14.7064 0.293598C14.5206 0.107744 14.2692 0.00232179 14.0064 3.78936e-05C13.7436 -0.00224601 13.4904 0.0987908 13.3013 0.281387L7.51282 6.07028L1.72431 0.280385V0.281387Z"
                                    fill="black"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div className="chat-enter">
                <form className="field-wrap" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    className="form-item form-item--icon-right"
                    placeholder="Comment"
                    value={message || editMessage.message}
                    onChange={handleMessageChange}
                  />
                  <button className="chat-enter__btn" type="submit">
                    <svg
                      className="fill"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.806 0.107249L0.41475 8.98398C-0.186291 9.32922 -0.109916 10.1658 0.487804 10.4181L4.01767 11.8986L13.558 3.49329C13.7406 3.33062 13.9996 3.5796 13.8435 3.76882L5.84404 13.512V16.1843C5.84404 16.9677 6.79043 17.2765 7.25533 16.7088L9.36395 14.1427L13.5015 15.8756C13.973 16.0747 14.511 15.7793 14.5973 15.2714L16.9882 0.930521C17.1011 0.259952 16.3805 -0.224716 15.806 0.107249Z"
                        fill="#FFA227"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <button
          className="open-widget-chat"
          type="button"
          onClick={handleToggle}
          style={{ width: '55px', height: '55px' }}
        >
          <svg
            className="stroke"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.4446 12.0886C24.449 13.8054 24.0479 15.499 23.2739 17.0314C22.3561 18.8677 20.9452 20.4122 19.1993 21.492C17.4533 22.5717 15.4412 23.1441 13.3883 23.1449C11.6715 23.1493 9.97788 22.7482 8.44546 21.9742L1.03125 24.4456L3.50265 17.0314C2.72863 15.499 2.32751 13.8054 2.33199 12.0886C2.33278 10.0357 2.90511 8.02357 3.98486 6.27759C5.06462 4.53161 6.60915 3.12072 8.44546 2.20296C9.97788 1.42893 11.6715 1.02782 13.3883 1.03229H14.0386C16.7498 1.18187 19.3106 2.32622 21.2306 4.24624C23.1506 6.16626 24.295 8.72702 24.4446 11.4382V12.0886Z"
              stroke="white"
              strokeWidth="1.27419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.59766 9.23489H18.1541"
              stroke="white"
              strokeWidth="1.27419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.59766 12.4203H18.1541"
              stroke="white"
              strokeWidth="1.27419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.59766 15.6058H13.6944"
              stroke="white"
              strokeWidth="1.27419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default Chat;
