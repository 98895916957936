import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

export function* sendContactUsForm({ payload, clearFields }) {
  try {
    const { data, status } = yield call(api.support.postContactUs, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({ type: types.SEND_CONTACTUS_FORM_SUCCESS, payload: data });
    notification({
      type: 'success',
      message: 'Success. Your message has been sent.',
    });
    clearFields();
  } catch (error) {
    console.dir(error);
    yield put({ type: types.SEND_CONTACTUS_FORM_FAILURE });
    if (error?.response?.status === 401) return;
    console.log(error);
  }
}

export function* watchersendContactUsForm() {
  yield takeLatest(types.SEND_CONTACTUS_FORM_START, sendContactUsForm);
}
