export const userSettingsSelector = state => state.settings.user;
export const dashboardSettingsSelector = state =>
  state.settings.dashboard.settings; // fix was here
export const apiManagemenSettingstSelector = state =>
  state.settings.apiManagement;
export const filesSettingsSelector = state => state.settings.files;
export const filesDataSettingsSelector = state => state.settings.files.data;

export const userSecurityDataSettingsSelector = state =>
  state.settings.security.securityData;

export const secretKeySettingsSelector = state =>
  state.settings.security.secretKey;

export const tokenDiscountsDataSettingsSelector = state =>
  state.settings.dashboard.discounts;

export const securityOptionsSelector = state =>
  state.settings.security.securityOptions;
export const uniqueDevicesSelector = state =>
  state.settings.security.uniqueDevices;
export const whitelistAddressesSelector = state =>
  state.settings.security.whitelistAddresses;
export const loginHistorySelector = state =>
  state.settings.security.loginHistory;
