import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import L from 'i18n-react';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

function* getVotingList({ payload }) {
  try {
    const { data, status } = yield call(api.voting.getVotingList, payload);
    if (status < 200 || status >= 300) throw new Error('Something went wrong');
    yield put({
      type: types.GET_VOTING_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: types.GET_VOTING_LIST_FAILURE });
    if (error?.response?.status === 401) return;
    console.error(error);
  }
}

export function* watcherVoting() {
  yield all([takeLatest(types.GET_VOTING_LIST_START, getVotingList)]);
}
