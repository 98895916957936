import { combineReducers } from 'redux';
import language from './lng/reducer';
import assetPairs from './pairs/reducers';
import user from './auth/reducer';
import trade from './trade/reducers';
import socket from './sockets/reducer';
import { wallets, assets, payments, transak } from './wallets/reducer';
import currentPair from './currentPair/reducer';
import contactUS from './support/reducer';
import temporary from './temporary/reducers';
import settings from './settings/reducers';
import modal from './modal/reducer';
import currentContract from './currentContract/reducer';
import referralSystem from './referralSystem/reducers';
import instanceExchange from './bankTransfer/exchange/reducer';
import interval from './interval/reducer';
import fees from './fees/reducer';
import vacancy from './vacancy/reducer';
import traderInfo from './traderInfo/reducer';
import videos from './videos/reducer';
import cashback from './cashback/reducer';
import limits from './limits/reducer';
import decimals from './decimals/reducers';
import chat from './chat/reducer';
import vote from './voting/reducer';
import status from './status/reducer';
import project from './project/reducer';


const rootReducers = combineReducers({
  decimals,
  transak,
  payments,
  fees,
  interval,
  modal,
  socket,
  user,
  language,
  assetPairs,
  trade,
  wallets,
  currentPair,
  currentContract,
  assets,
  contactUS,
  temporary,
  settings,
  referralSystem,
  instanceExchange,
  vacancy,
  traderInfo,
  videos,
  cashback,
  limits,
  chat,
  vote,
  status,
  project
});

export default rootReducers;
