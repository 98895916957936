import types from '../types';

const initialState = {
  limits: [],
  feesPerInputAndOutput: [],
  loading: false,
};

const limits = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIMITS_START:
      return { ...state, loading: true };
    case types.GET_LIMITS_SUCCESS:
      return { ...state, loading: false, limits: payload };
    case types.GET_FEES_PER_INPUT_AND_OUTPUT_SUCCESS:
      return { ...state, loading: false, feesPerInputAndOutput: payload };
    case types.GET_LIMITS_FAILURE:
      return { ...state, loading: false };
    // case types.LOGOUT_SUCCESS:
    //   return initialState;
    default:
      return state;
  }
};

export default limits;
