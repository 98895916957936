import React from 'react';
import { put, takeLatest, call } from 'redux-saga/effects';
import types from '../../types';
import api from '../../../services/api';
import notification from '../../../services/notification';
import routes from '../../../routes';
import { getLS, setLS } from '../../../services/localStorage';
import { openModal } from '../../../components/Base/Modal';
import ModalErrorRegistrationCountry from '../../../components/Base/Modals/ModalErrorRegistrationCountry';

export function* Signup({ payload }) {
  try {
    const { data, status } = yield call(api.auth.signup, {
      ...payload.userData,
      // phone: `+${payload.userData.phone.replace(/\D/g, '')}`,
    });
    if (status < 200 || status >= 300) throw new Error('Something went wrong');

    if (payload?.userData?.phone && payload.userData.phone.replace('+', '')) {
      payload.history.push(routes.Auth.ConfirmMobilePhone.path, {
        phone: payload.userData.phone,
        password: payload.userData.password,
        type: 'register',
      });
      if (
        window.location.hostname.includes('orderbook.fun') ||
        window.location.hostname.includes('localhost')
      ) {
        notification({
          type: 'info',
          title: 'SMS code',
          message: data?.phone_confirm_code,
          time: 15000,
        });
      }
      return;
    }

    yield put({ type: types.SIGNUP_SUCCESS, payload: data });
    notification({
      type: 'success',
      title: 'Sign Up',
      message: 'You have registered successfully. Check your e-mail',
    });
    payload.history.push({
      pathname: routes.Auth.Login.path,
    });
  } catch (error) {
    console.dir(error);
    yield put({ type: types.SIGNUP_FAILURE });
    if (error?.response?.status === 401) return;
    if (
      error?.response?.data?.errors?.sms_code?.includes(
        'the_sms_code_field_is_required',
      )
    ) {
      notification({
        type: 'error',
        title: 'Login',
        message: 'You should fill sms code field',
      });
      return;
    }
    if (
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors?.includes('sms_code_invalid')
    ) {
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'sms code invalid',
      });
    }
    if (
      error?.response?.data?.errors?.email?.includes(
        'the_email_has_already_been_taken',
      )
    ) {
      // payload.history.push(routes.Auth.VerifyYourEmail.path, {
      //   email: payload.userData.email,
      // });
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'This e-mail is already registered',
      });
    }
    if (
      error?.response?.data?.errors?.phone?.includes(
        'the_phone_has_already_been_taken',
      )
    ) {
      notification({
        type: 'error',
        title: 'Sign Up',
        message: 'Error. Phone number has already registered.',
      });
    }
    if (
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors?.includes('forbidden_country')
    ) {
      openModal(() => <ModalErrorRegistrationCountry />);
    }
  }
}

export function* watcherSignup() {
  yield takeLatest(types.SIGNUP_START, Signup);
}
