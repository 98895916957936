// redux/project/sagas.js

import { put, takeLatest, call, fork, all } from 'redux-saga/effects';
import types from '../types';
import api from '../../services/api';
import notification from '../../services/notification';

function* submitForm(action) {
  try {

    const { data, status } = yield call(api.projects.submitFormData, action.payload);
    if (status >= 200 && status < 300) {
      yield put({ type: types.SUBMIT_FORM_SUCCESS, payload: data });
      // Optionally dispatch any success actions or handle success cases
    } else {
      throw new Error('Failed to submit form');
    }
  } catch (error) {
    yield put({ type: types.SUBMIT_FORM_FAILURE, payload: error });
    console.error('Form submission error:', error);
    notification({
      type: 'error',
      message: error?.message || 'An error occurred while submitting the form.',
    });
  }
}

export function* watchSubmitForm() {
  yield takeLatest(types.SUBMIT_FORM_REQUEST, submitForm);
}

export default watchSubmitForm;
